export const GET_CURATED_VIDEOS_REQUESTED = 'curatedVideos/GET_CURATED_VIDEOS_REQUESTED';
export const GET_CURATED_VIDEOS_SUCCESS = 'curatedVideos/GET_CURATED_VIDEOS_SUCCESS';
export const GET_CURATED_VIDEOS_FAILURE = 'curatedVideos/GET_CURATED_VIDEOS_FAILURE';

export const GET_CURATED_VIDEOS_CATEGORY_REQUESTED = 'curatedVideos/GET_CURATED_VIDEOS_CATEGORY_REQUESTED';
export const GET_CURATED_VIDEOS_CATEGORY_SUCCESS = 'curatedVideos/GET_CURATED_VIDEOS_CATEGORY_SUCCESS';
export const GET_CURATED_VIDEOS_CATEGORY_FAILURE = 'curatedVideos/GET_CURATED_VIDEOS_CATEGORY_FAILURE';

export const GET_REACTION_DATA_REQUESTED = 'curatedVideos/GET_REACTION_DATA_REQUESTED';
export const GET_REACTION_DATA_SUCCESS = 'curatedVideos/GET_REACTION_DATA_SUCCESS';
export const GET_REACTION_DATA_FAILURE = 'curatedVideos/GET_REACTION_DATA_FAILURE';

export const GET_UPLOADED_VIDEOS_REQUESTED = 'curatedVideos/GET_UPLOADED_VIDEOS_REQUESTED';
export const GET_UPLOADED_VIDEOS_SUCCESS = 'curatedVideos/GET_UPLOADED_VIDEOS_SUCCESS';
export const GET_UPLOADED_VIDEOS_FAILURE = 'curatedVideos/GET_UPLOADED_VIDEOS_FAILURE';

export const SAVE_UPLOADED_VIDEOTYPE_REQUESTED = 'curatedVideos/SAVE_UPLOADED_VIDEOTYPE_REQUESTED';
export const SAVE_UPLOADED_VIDEOTYPE_SUCCESS = 'curatedVideos/SAVE_UPLOADED_VIDEOTYPE_SUCCESS';
export const SAVE_UPLOADED_VIDEOTYPE_FAILURE = 'curatedVideos/SAVE_UPLOADED_VIDEOTYPE_FAILURE';

export const DELETE_UPLOADED_VIDEO_REQUESTED = 'curatedVideos/DELETE_UPLOADED_VIDEO_REQUESTED';
export const DELETE_UPLOADED_VIDEO_SUCCESS = 'curatedVideos/DELETE_UPLOADED_VIDEO_SUCCESS';
export const DELETE_UPLOADED_VIDEO_FAILURE = 'curatedVideos/DELETE_UPLOADED_VIDEO_FAILURE';

export const GET_VIDEO_DETAILS_REQUESTED = 'curatedVideos/GET_VIDEO_DETAILS_REQUESTED';
export const GET_VIDEO_DETAILS_SUCCESS = 'curatedVideos/GET_VIDEO_DETAILS_SUCCESS';
export const GET_VIDEO_DETAILS_FAILURE = 'curatedVideos/GET_VIDEO_DETAILS_FAILURE';



const initialState = {
  loading: false,
  loaded: false,
  error: false,
  videoDetails: [],
  videoDetailsWithCategory: [],
  reactionCount: undefined,
  uploadedVideoList:[],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CURATED_VIDEOS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_CURATED_VIDEOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        videoDetails: action.result.curatedVideosList
      };
    }
    case GET_CURATED_VIDEOS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case GET_CURATED_VIDEOS_CATEGORY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_CURATED_VIDEOS_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        videoDetailsWithCategory: action.result.categories
      };
    }
    case GET_CURATED_VIDEOS_CATEGORY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case GET_REACTION_DATA_REQUESTED: {
      return {
        ...state,
      };
    }
    case GET_REACTION_DATA_SUCCESS: {
      return {
        ...state,
        reactionCount: action.result.totalCount
      };
    }
    case GET_REACTION_DATA_FAILURE: {
      return {
        ...state,
      };
    }
    case GET_UPLOADED_VIDEOS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_UPLOADED_VIDEOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        uploadedVideoList: action.result.uploadedVideoList
      };
    }
    case GET_UPLOADED_VIDEOS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    case SAVE_UPLOADED_VIDEOTYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SAVE_UPLOADED_VIDEOTYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_UPLOADED_VIDEOTYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    case DELETE_UPLOADED_VIDEO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DELETE_UPLOADED_VIDEO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_UPLOADED_VIDEO_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    case GET_VIDEO_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_VIDEO_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        // videoDetails: action.result.curatedVideosList
      };
    }
    case GET_VIDEO_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    default:
      return state;
  }
};


export const getCuratedVideos = (userID,category,arrange,pageNo,itemsPerPage,searchTerm,videoId) => {
  const searchKey = searchTerm ? searchTerm : '';
  const order = arrange ? arrange : 'desc';
  let encodedCategory = encodeURIComponent(category);
  return {
    types: [GET_CURATED_VIDEOS_REQUESTED, GET_CURATED_VIDEOS_SUCCESS, GET_CURATED_VIDEOS_FAILURE],
    promise: (client) => client.get(`getCuratedVideos?userId=${userID}&category=${encodedCategory}&order=${order}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&search=${searchKey}&exclude=${videoId}`)
  };
};

export const getCuratedVideosWithCategory = (searchTerm, userID, orderType) => {
  const searchKey = searchTerm ? searchTerm : '';
  const order = orderType ? orderType : 'desc'
  return {
    types: [GET_CURATED_VIDEOS_CATEGORY_REQUESTED, GET_CURATED_VIDEOS_CATEGORY_SUCCESS, GET_CURATED_VIDEOS_CATEGORY_FAILURE],
    promise: (client) => client.get(`getCuratedVideosWithCategory?userId=${userID}&search=${searchKey}&order=${order}&limit=6`),
  };
};

export const getReactionData = (userSurrogateId,videoId) => {
  return {
    types: [GET_REACTION_DATA_REQUESTED, GET_REACTION_DATA_SUCCESS, GET_REACTION_DATA_FAILURE],
    promise: (client) => client.get(`getReactionData/${userSurrogateId}/${videoId}?list=false`),
  };
};

export const getUploadedVideos = (userId,pageNo,itemsPerPage,search,type,uploadType,orderType,mode) => {
  const searchTerm = search ? search : '';
  let filterType = type ? type : '';
  filterType = encodeURIComponent(filterType);
  const order = orderType ? orderType : 'desc';
  return {
    types: [GET_UPLOADED_VIDEOS_REQUESTED, GET_UPLOADED_VIDEOS_SUCCESS, GET_UPLOADED_VIDEOS_FAILURE],
    promise: (client) => client.get(`getUploadedVideos/${userId}?pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&search=${searchTerm}&type=${filterType}&uploadType=${uploadType}&order=${order}&videoUploadMode=${mode}`) //&search=${searchTerm}&type=${filterType}
  };
}

export const changeUploadedVideoType = (data, userSurrogateId) => {
  return {
    types: [SAVE_UPLOADED_VIDEOTYPE_REQUESTED, SAVE_UPLOADED_VIDEOTYPE_SUCCESS, SAVE_UPLOADED_VIDEOTYPE_FAILURE],
    promise: (client) => client.post(`changeUploadedVideoType/${userSurrogateId}`, {
      data,
    })
  };
};

export const deleteUploadedVideo = (videoId, userSurrogateId) => {
  return {
    types: [DELETE_UPLOADED_VIDEO_REQUESTED, DELETE_UPLOADED_VIDEO_SUCCESS, DELETE_UPLOADED_VIDEO_FAILURE],
    promise: (client) => client.del(`deleteUploadedVideo/${videoId}/${userSurrogateId}`)
  };
};

export const getVideoDetails = (videoId) => {
  return {
    types: [GET_VIDEO_DETAILS_REQUESTED, GET_VIDEO_DETAILS_SUCCESS, GET_VIDEO_DETAILS_FAILURE],
    promise: (client) => client.get(`getVideoDetails/${videoId}`)
  };
};
