export const GET_CHAPTERS_REQUESTED = 'chapters/GET_CHAPTERS_REQUESTED';
export const GET_CHAPTERS_SUCCESS = 'chapters/GET_CHAPTERS_SUCCESS';
export const GET_CHAPTERS_FAILURE = 'chapters/GET_CHAPTERS_FAILURE';
export const GET_SUBJECTS_REQUESTED = 'subjects/GET_SUBJECTS_REQUESTED';
export const GET_SUBJECTS_SUCCESS = 'subjects/GET_SUBJECTS_SUCCESS';
export const GET_SUBJECTS_FAILURE = 'subjects/GET_SUBJECTS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  chapters: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHAPTERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CHAPTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        chapters: action.result.chapters,
      };
    }
    case GET_CHAPTERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_SUBJECTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SUBJECTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        subjects: action.result.subjects,
      };
    }
    case GET_SUBJECTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getChaptersByTextbookId = (textbookId) => {
  return {
    types: [GET_CHAPTERS_REQUESTED, GET_CHAPTERS_SUCCESS, GET_CHAPTERS_FAILURE],
    promise: (client) => client.get(`getChapters?textbookId=${textbookId}`),
  };
};

export const getChapters = (subject, grade, board) => {
  return {
    types: [GET_CHAPTERS_REQUESTED, GET_CHAPTERS_SUCCESS, GET_CHAPTERS_FAILURE],
    promise: (client) =>
      client.get(
        `getChapters?subject=${subject}&grade=${grade}&board=${board}`
      ),
  };
};

export const getChaptersById = (ids) => {
  return {
    types: [GET_CHAPTERS_REQUESTED, GET_CHAPTERS_SUCCESS, GET_CHAPTERS_FAILURE],
    promise: (client) => client.get(`getChapters?id=${ids.join(',')}`),
  };
};

export const getSubjects = (grade, board) => {
  return {
    types: [GET_SUBJECTS_REQUESTED, GET_SUBJECTS_SUCCESS, GET_SUBJECTS_FAILURE],
    promise: (client) =>
      client.get(`getSubjects?grade=${grade}&board=${board}`),
  };
};
