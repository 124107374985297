import React, { Component } from 'react';
import { searchVideos } from '@reducers/watchparty';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import WatchPartySearch from '@components/WatchPartySearch';
import { searchFriends } from '@reducers/friendsFans';


export class WatchPartyPlaylist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearchData: false,
            isSearchDataPresent: true,
            searchText: '',
            searchResults: [],
            friendsList: false,
            friendsListArray: []
        }
    }

    searchVideos = (e) => {
        const searchTerm = e.target.value;
        this.setState({ searchText: e.target.value })
        const categoryName = ''
        if (searchTerm !== '') {
            this.setState({ isSearchData: true, isSearchDataPresent: false });
            this.props.searchVideos(undefined, encodeURIComponent(searchTerm), categoryName).then((data) => {
                this.setState({ searchResults: data.searchResults })
            });
        } else {
            this.setState({ isSearchData: false, isSearchDataPresent: true });
        }
    }

    searchFriends = (e) => {
        const searchTerm = e.target.value;
        const userSurrogateId = window.localStorage.getItem('userDetails') ? JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId : 'null'; 
        this.setState({ searchText: e.target.value })
        if (searchTerm !== '') {
            this.setState({ isSearchData: true, isSearchDataPresent: false,  friendsList: true });
            this.props.searchFriends(encodeURIComponent(searchTerm), userSurrogateId, this.props.InviteParticipantKey && true).then((data) => {
                this.setState({ friendsListArray: data.searchUserResults })
            });
        } else {
            this.setState({ isSearchData: false, isSearchDataPresent: true,friendsListArray: []  });
        }
    }

    searchField = () => {
        if (this.state.searchText === '') {
            this.props.handleSearchContainer('false')
        }
        this.setState({ searchText: '', friendsListArray: [] })
        this.props.change('selectVideo', 'searchnextvideo', '');
        this.setState({ isSearchDataPresent: true });
    }

    addFriendsEmail = (emailToAdd) => {
        this.props.friendsEmailToAdd(emailToAdd);
        this.searchField();
    }


    render() { 
        return (
            <div>
                <WatchPartySearch
                    searchData={this.state.isSearchData ? this.state.searchResults : []}
                    addNextVideo={this.props.addNextVideo}
                    searchVideos={this.searchVideos}
                    searchField={this.searchField}
                    isSearchDataPresent={this.state.isSearchDataPresent}
                    customSearchContainerClass={this.props.customSearchContainerClass}
                    customScrollContainerClass={this.props.customScrollContainerClass}
                    isSearchFriendModel={this.props.isSearchFriendModel}
                    searchFriends={this.searchFriends}
                    friendsList={this.state.friendsList}
                    friendsListArray={this.state.friendsListArray}
                    addFriendsEmail={this.addFriendsEmail}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // searchResults : state.watchparty.searchData
    };
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    searchVideos,
    searchFriends,
    change
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WatchPartyPlaylist));
