import React from 'react';
import style from './VideoViews.module.scss';
import { EN } from '@localization';
import cn from 'classnames';


const VideoViews = (props) => {
  const data = [
    {
      id: 1, title: `${EN.SESSIONS}`, value: props.views,
    },
    {
      id: 2, title: `${EN.FRIENDS}`, value: props.reactions,
    },
    {
      id: 3, title: `${EN.BERRYS}`, value: props.credits,
    },
  ];

  const renderData = (dataList) => {
    return (
      <>
        <div className={cn(style.profile_label, style.label_green)}>
          <p>
            <span className="display-block txt-center">{dataList[0].value}</span>
            <span className={cn(style.profile_label_text, 'display-block', 'txt-center')}>{dataList[0].title}</span>
          </p>
        </div>
        <br />
        <div className={cn(style.profile_label, style.label_pink)}>
          <p>
            <span className="display-block txt-center">{dataList[1].value}</span>
            <span className={cn(style.profile_label_text, 'display-block', 'txt-center')}>{dataList[1].title}</span>
          </p>
        </div>
        <br />
        <div className={cn(style.profile_label, style.label_black)}>
          <p>
            <span className="display-block txt-center">{dataList[2].value}</span>
            <span className={cn(style.profile_label_text, 'display-block', 'txt-center')}>{dataList[2].title}</span>
          </p>
        </div>
      </>
    );
  };
  return (
      renderData(data)
  );
};


export default VideoViews;


