import React from 'react';
import style from './Button.module.css';
import Loader from '@components/common/Loader';

const VidberryButton = (props) => {
    let buttonClass = `${style.btn_vidberrys}`
    if (props.className) {
        buttonClass = `${buttonClass} ${props.className}`
    }
    const onClickHandler = () => {
        if (props.onClick) {
            props.onClick()
        }
    }
    return (
        <button onClick={() => onClickHandler()} className={buttonClass} disabled={props.disabled} type={props.type}>
            {props.title && !props.icon && (
                <span className="align-self-center">{props.title}</span>
            )}
            {props.icon && !props.title && (
                <img className="align-self-center" src={props.icon} alt={props.alt} />
            )}
            {props.title && props.icon && (
                <>
                    <img className="align-self-center pr-1" src={props.icon} alt={props.alt} />
                    <span className="align-self-center">{props.title}</span>
                </>
            )}
            {props.loader && (
                <span className="align-self-center">
                    <Loader size='10' color="white" />
                </span>
            )}
        </button>
    )
}

export default VidberryButton