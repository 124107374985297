import React from 'react';
import uploadFileStyle from '@containers/FileUpload/FileUpload.module.css';
import { reduxForm } from 'redux-form';
import ReactSelect from '@formElements/ReactSelect';
import Textfield from '@formElements/TextField';
import TextArea from '@formElements/TextArea';
import Button from '@components/common/formElements/Button';
import cn from 'classnames';
import BACK_ICON from '@assets/icons/BackIcon.svg';
import { withRouter } from 'react-router';
import { EN } from '@localization';

const Feedback = (props) => {
    return (
        <div className="row m-0 mt-40">
            <div className={cn('custom-col-xl-6 custom-col-xlg-10 custom-col-xxlg-13', props.windowWidth < 1920 ? 'custom-offset-xl-5 p-0' : null)}>
                <Button
                    type="button"
                    className="btn-back"
                    icon={BACK_ICON}
                    alt="GO Back"
                    onClick={() => props.history.goBack()}
                />
            </div>
            <div className="custom-col-14 custom-col-xlg-12 custom-col-xxlg-10">
                <form onSubmit={props.handleSubmit}>
                    <div className="row m-0 mt-10 mb-40 px-4">
                        <span className="fs-20 align-self-center">FeedBack</span>
                    </div>
                    <div className="row m-0 mb-40">
                        <ReactSelect
                            name="Modules"
                            options={props.moduleOptions}
                            className="react-select-container w-100"
                            placeholder={EN.MODULE}
                            label="Feedback on"
                            displayLabel
                            background
                            value={props.module}
                            onChange={props.setModules}
                            required
                            customLabelCss={cn(uploadFileStyle.custom_label, 'mt-0')}
                        />
                    </div>
                    <div className="row m-0 mb-40">
                        <Textfield
                            name="Subject"
                            type="text"
                            label="Subject"
                            customClass="vidberrys-textfield-gray"
                            showLabel
                            customLabelCss={cn('mt-0 mb-16 fs-15')}
                            required
                            customLabelCss={cn(uploadFileStyle.custom_label, 'mt-0')}
                        />
                    </div>
                    <div className="row m-0 mb-40">
                        <TextArea
                            name="Description"
                            customClass="vidberrys-textfield-gray"
                            // placeholder="Text"
                            label="Description"
                            onChange={props.onChangeTextArea}
                            charLength={props.charCount}
                            totalChar={props.totalChar}
                            showCharCount
                            onKeyDown={props.onKeyDownDescription}
                            customLabelCss={cn(uploadFileStyle.custom_label, 'mt-0')}
                        />
                    </div>
                    <div className="row m-0">
                        <Button type="submit" className="btn-maroon px-2 w-100" title="Submit" disabled={props.disableBtn} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default withRouter(reduxForm({
    form: 'FeedbackForm',
    enableReinitialize: true
})(Feedback));
