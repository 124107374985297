export default {
  // This is ENGLISH language specific constant file
  REQUIRED: ' *',
  LOGIN: 'Sign In',
  EMAIL: 'Email',
  MOBILE_NUMBER: 'Mobile Number',
  PASSWORD: 'Password',
  PASSWORD_OR_OTP: 'Password / OTP',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  CONFIRM_PASSWORD: 'Repeat Password',
  OLD_PASSWORD: 'Old Password',
  NEW_PASSWORD: 'New Password',
  MODAL_CANCEL: 'Cancel',
  USERNAME: 'Username',
  ENTER_OTP: 'Enter Otp',
  REACTING_TO_VIDEO: 'Reacting to video',
  NEXT_VIDEO: 'Next Video',
  NOW_PLAYING_VIDEO: 'Now Playing',
  ADD_VIDEO_URL: 'Add Video URL',
  INVITE_FRIENDS: 'Invite friends',
  RECORD_ALL: 'Record all',
  RECORD_ME: 'Record me',
  SHARE: 'Share',
  REACTION_ON: 'Reaction On',
  WATCHING: '#Watching',
  HOSTED_BY: 'Hosted by',
  WATCHPARTYNAME: 'Watch Party Name',
  SOURCE: 'Source',
  SIGN_IN: 'SIGN IN',
  LOGOUT: 'Logout',
  REACTIONVIDEOS: 'Reaction videos',
  LATEST_NEWS: 'Latest News',
  VIEW_ALL: 'View All',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  RECOMMENDATIONS: 'Recommendations',
  TRENDING: 'Trending',
  NEW: 'New',
  INDIAN: 'Indian',
  AMERICAN: 'American',
  HISPANIC: 'Hispanic',
  VIEWS: 'Views',
  REACT: 'Reacts',
  BERRYS: 'Berrys',
  SESSIONS: 'Sessions',
  FRIENDS: 'Friends',
  CASH: 'Cash',
  REACTS: 'React (Stitch, Duet)',
  CREATE_WATCH_PARTY: 'Create Watch Party',
  WATCH_PARTY_LIST: 'My Watch Parties',
  CREATE_PLAYLIST: 'Create Playlist',
  WHATS_NEW: 'Whats New',
  MY_CHANNEL: 'My Channel',
  MY_FAVOURITES: 'My Favorites',
  MY_SUBSCRIPTIONS: 'My Subscriptions',
  INVITE: 'Invite',
  RECIPIENTS: 'Recipients',
  WATCH_PARTY: 'Watch Party',
  SCHEDULE_DATE_TIME: 'Scheduled Date & Time',
  NO_PARTICIPANTS: 'Number Of Participants',
  CREATED: 'Created',
  INVITED: 'Invited',
  USER_EXISTS: 'User already in watch party in different device',
  OK: 'Ok',
  VIEWING: 'Viewing',
  OTP_SENT_SUCCESSFULLY: 'OTP sent succesfully',
  UNABLE_TO_SEND_OTP: 'Unable to send OTP. Please try again',
  OTP_RESENT_SUCCESSFULLY: 'OTP re-sent succesfully',
  UNABLE_TO_RESEND_OTP: 'Unable to resend OTP. Please try again',
  UNABLE_TO_LOGIN: 'Unable to login. Please try again',
  SUCCESS_ADD_VIDEO: 'Video is added to playlist successfully.',
  FAILURE_ADD_VIDEO: 'Unable to add video to playlist. Please try again',
  SUCCESS_INVITE: 'Invite sent successfully.',
  FAILURE_SCHEDULE: 'Unable to schedule watch party. Please try again',
  UPLOAD_FILE: 'Upload File',
  FAILURE_UPLOAD: 'Unable to upload file. Please try again',
  CATEGORY: 'Category',
  SPACE: '',
  DATE_FORMAT: 'DD/MM/YYYY',
  VIDEO_ALREADY_IN_LIST: 'Video is already in the playlist!!',
  DELETE_VIDEO: 'Delete video',
  VIDEO_PLAYING_NOT_ABLE_TO_DELETE:
    'Current video playing in watch party. Not able to delete!!',
  CLOSE_CONF_ROOM_WARNING: 'You are exiting this watch party!! Are you sure ?',
  YES: 'Yes',
  MY_REACTION_TAB: 'My Reactions',
  MY_UPLOADS: 'My Uploads',
  REACTION_NAME: 'Reaction Name',
  SUBMIT: 'Submit',
  VIDEO_ACCESS: 'Video privacy',
  SAVE_MY_VIDEO: 'Save your reaction video ?',
  SAVE_GROUP_VIDEOS: 'Save group reaction video ?',
  ALLOW: 'Allow',
  DENY: 'Deny',
  RECORD_USER_REQUEST:
    'Admin is requesting to record your reaction. Do you allow to record your reaction ?',
  REACTION_VIDEO_COUNT: 'Reactions',
  MY_UPLOADS_TAB: 'My Uploads',
  SET_PASSWORD: 'Set Password',
  UPDATE_PASSWORD: 'Update Password',
  UPDATE_PROFILE: 'Update Profile',
  UPDATE_USERNAME: 'Update Username',
  VIDEO_TITLE: 'Video Title',
  HOW_TO_CREATE: 'How to Create',
  LEARN: 'Learn',
  REACTION_VIDEO: 'Reaction video',
  SCHOOL_PARTY: 'School Party',
  WATCH_PARTIES: 'Watch Parties',
  SCHOOL_PARTIES: 'School Parties',
  COMPETITION_HEADING1: 'Join Summer 2021',
  COMPETITION_HEADING2: 'Reaction Video Competition',
  CHAT: 'Chat',
  MARKERS: 'Markers',
  COMMENT: 'Comment',
  OTHER_REACTIONS: 'Other reactions',
  PLAYLIST: 'Playlist',
  SET_PASSWORD_HELPTEXT: 'Password must be min. 8 characters long',
  SET_USERNAME_HELPTEXT: 'Username can be upto 15 charecters or less',
  UPLOAD_FILE_HELPTEXT:
    'Your uploaded videos will be available in "My channel" and may be surfaced in the home page',
  NO_REACTION_VIDEOS: 'No latest reaction videos available',
  NO_VIDEOSTOREACT: 'No videos available',
  NO_SCHOOLPARTY_VIDEOS: 'No school parties available',
  NO_LIVEWATCHPARTY_VIDEOS: 'No live watch parties available',
  NO_WATCHPARTY_VIDEOS: 'No watch parties available',
  LATEST_REACTION_VIDEOS: 'Latest Reaction Videos',
  VIDEOS_TO_REACT: 'Videos to React',
  LIVE_WATCH_PARTIES: 'Live Watch Parties',
  VALID_URL_MSG: 'Enter valid url...',
  EDIT_OPTIONS: 'Edit Options',
  EDITED_VIDEOS_LIST: 'Edited Videos',
  SAVE_EDITED_VIDEOS: 'Save your edited video ?',
  EDITED_VIDEO_PRIVACY: 'Video privacy',
  MY_FRIENDS: 'Friends',
  MY_FANS: 'Fans',
  MY_FOLLOWINGS: 'Following',
  SEE_ALL: 'See All',
  PENDING_REQUESTS: 'Pending Requests',
  FRIENDS_LIST: 'Friends',
  FANS_LIST: 'Fans',
  SUCCESS_ACCEPT_OF_FRIEND_REQUEST: 'friend request accepted',
  NO_FRIENDS_INVITED: 'No friends invited',
  NO_FRIENDS_MSG: 'No friends to display',
  NO_FANS_MSG: 'No fans to display',
  NO_FOLLOWINGS_MSG: 'No following to display',
  LOADING_TEXT: 'loading...',
  RECORDING_NOTE: 'Recording reaction...play or pause video as needed',

  PHONE_OR_EMAIL_ID: 'Email Address', //'Phone or Email Address',
  OTP: 'One-Time Password',
  NAME: 'Name',
  LINK: 'Link',
  WP_MODAL_LINK: 'Paste YouTube url OR search video in Vidberrys',
  EMAIL_INVITE: 'Email Invite',
  EMAIL_USERNAME: 'Email/Username',
  INVITE_DESC:
    'Share watch party via link or send an email or share via facebook or whatsapp',
  COPY: 'Copy',
  TEXT_COPIED: 'Text copied!!',
  UPLOAD_NOTE_FILE:
    'Note: Videos are not downloaded to your account or Vidberrys. Vidberrys plays content directly from the platfrom as needed.',
  UPLOAD_NOTE_VIDEO: 'Note: Max. File size is 3GB',
  USE_NEW_DEVICE:
    'To use the new device in watch party, please configure it in settings.',
  NEW_DEVICE_DETECTED: 'New Device Detected',
  DELETE_VIDEO_CONFIRMATION: 'Delete Video Confirmation',
  DELETE_WP_CONFIRMATION: 'Delete Watch Party Confirmation',
  DELETE_REACTION_CONFIRMATION: 'Delete Reaction Confirmation',
  DELETE_VIDEO_CONFIRMATION_MSG: 'You are deleting this video!! Are you sure ?',
  DELETE_WP_CONFIRMATION_MSG:
    'You are deleting this watch party!! Are you sure ?',
  DELETE_REACTION_CONFIRMATION_MSG:
    'You are deleting this reaction!! Are you sure ?',
  COOKIE_NOTE: 'Note: Please enable third party cookies from browser settings',
  WEB_CAM_AFTER_RECORD_MODEL_GROUP_TITLE: 'Recorded group reaction',
  WEB_CAM_AFTER_RECORD_MODEL_INDIVIDUAL_TITLE: 'Recorded your reaction',
  USE_CHROME:
    'Please use Google Chrome. Other browsers are not supported at this time.',
  USE_CHROME_IN_MOB_MESSAGE:
    'Vidberrys runs only in a chrome browser on a laptop or a desktop, mobile support will be coming out in the near future',
  PLAY_EVENT_MSG: ' played the video',
  PAUSE_EVENT_MSG: ' paused the video',
  FF_EVENT_MSG: ' fast forwarded the video',
  REWIND_EVENT_MSG: ' rewinded the video',
  MEDIA_URL_CHANGE_EVENT_MSG: ' changed the video',
  MARKER_TRIGGERED: ' clicked the marker',
  DEVICE_NOT_DETECTED:
    'Please change the browser settings to access Microphone/Camera devices',
  MARKER_PLACEHOLDER: 'Enter marker Text',
  MARKER_PLACEHOLDER_SECONDARY: 'Enter marker',
  WP_PLAYLIST_PLACEHOLDER: 'YouTube url OR search',
  WP_PLAYLIST_PLACEHOLDER_SECONDARY: 'YouTube url',
  BY_CATEGORIES: 'By Categories',
  BY_SUBJECTS: 'By Subjects',
  CHAT_PLACEHOLDER: 'Enter Text',
  COMMENT_PLACEHOLDER: 'Enter Comment',
  MODULE: 'Module',
  TIMER_HOUR: 'hh',
  TIMER_MINUTE: 'mm',
  TIMER_SECOND: 'ss',
  FILE_FORMAT_ERROR: 'Enter a valid file',
  VIDEO_SIZE_ERROR: 'Maximum file size is 3GB',
  VIDEO_SIZE_MAX_5MB: 'Maximum file size is 5MB',
  VIDEO_SIZE_MAX_10MB: 'Maximum file size is 10MB',
  YOU_LOST_INTERNET: 'Lost your internet connectivity!',
  INTERNET_BACK: 'Reestablished internet connectivity!',
  WATCH_PARTY_DOESNOT_EXISTS: 'Watch Party doesnot exists!!',
  NEW_USER_MSG: 'As you are a new user, set your username and password.',
  VIDBERRYS_VERSION: 'v2.4',
  NOT_ALLOWED_TURN_OFF: 'Not allowed to turn off while recording!!',
  SOCIAL_SHARE_PLAYBACK_QUOTE:
    "My entry for the world's first reaction video competition! Help me win by liking my video in Vidberrys and share it in your network.  #ReactionVideo #ReactionVideoCompetition",
  SOCIAL_SHARE_VIDBERRYS_HASHTAG: '#Vidberrys',
  SOCIAL_SHARE_WATCHPARTY_QUOTE: 'Join me at this watch party',
  FETCH_IP_DETAILS_URL: 'https://geolocation-db.com/json/',
  // FETCH_IP_DETAILS_URL: 'https://ipapi.co/json/',

  STUDY_SESSION: 'Study Sessions',
  HOW_TO_SHARE: 'How to Share',
  WITH_YOUR_FRIENDS: 'With Your Friends',
  SHARE_WITH_FRIENDS: 'Share',
  BOARD: 'Board',
  CONTINUE_FROM_WHERE_YOU_LEFT_OFF: 'Continue Studying',
  SUBJECTS: 'Subjects',
  START_SESSION: 'Self Study',
  EXERCISE_BUTTON: 'Exercise',
  GROUP_STUDY: 'Group Study',
  NO_SESSIONS: 'No study sessions available',
  END_STUDY_SESSION_WARNING:
    'You are exiting this study session!! Are you sure ?',
};
