export const WATCH_PARTY_REQUESTED = 'watchparty/WATCH_PARTY_REQUESTED';
export const WATCH_PARTY_SUCCESS = 'watchparty/WATCH_PARTY_SUCCESS';
export const WATCH_PARTY_FAILURE = 'watchparty/WATCH_PARTY_FAILURE';
export const GET_WATCH_PARTY_LIST_REQUESTED =
  'watchparty/GET_WATCH_PARTY_LIST_REQUESTED';
export const GET_WATCH_PARTY_LIST_SUCCESS =
  'watchparty/GET_WATCH_PARTY_LIST_SUCCESS';
export const GET_SCHOOL_WATCH_PARTY_LIST_SUCCESS =
  'watchparty/GET_SCHOOL_WATCH_PARTY_LIST_SUCCESS';
export const GET_PUBLIC_WATCH_PARTY_LIST_SUCCESS =
  'watchparty/GET_PUBLIC_WATCH_PARTY_LIST_SUCCESS';
export const GET_WATCH_PARTY_LIST_FAILURE =
  'watchparty/GET_WATCH_PARTY_LIST_FAILURE';
export const GET_WATCH_PARTY_DETAILS_REQUESTED =
  'watchparty/GET_WATCH_PARTY_DETAILS_REQUESTED';
export const GET_WATCH_PARTY_DETAILS_SUCCESS =
  'watchparty/GET_WATCH_PARTY_DETAILS_SUCCESS';
export const GET_WATCH_PARTY_DETAILS_FAILURE =
  'watchparty/GET_WATCH_PARTY_DETAILS_FAILURE';
export const ADD_NEXT_VIDEO_URL_REQUESTED =
  'watchparty/ADD_NEXT_VIDEO_URL_REQUESTED';
export const ADD_NEXT_VIDEO_URL_SUCCESS =
  'watchparty/ADD_NEXT_VIDEO_URL_SUCCESS';
export const ADD_NEXT_VIDEO_URL_FAILURE =
  'watchparty/ADD_NEXT_VIDEO_URL_FAILURE';
export const INVITE_FRIENDS_REQUESTED = 'watchparty/INVITE_FRIENDS_REQUESTED';
export const INVITE_FRIENDS_SUCCESS = 'watchparty/INVITE_FRIENDS_SUCCESS';
export const INVITE_FRIENDS_FAILURE = 'watchparty/INVITE_FRIENDS_FAILURE';
export const UPDATE_USER_WATCH_PARTY_STATUS_REQUESTED =
  'watchparty/UPDATE_USER_WATCH_PARTY_STATUS_REQUESTED';
export const UPDATE_USER_WATCH_PARTY_STATUS_SUCCESS =
  'watchparty/UPDATE_USER_WATCH_PARTY_STATUS_SUCCESS';
export const UPDATE_USER_WATCH_PARTY_STATUS_FAILURE =
  'watchparty/UPDATE_USER_WATCH_PARTY_STATUS_FAILURE';
export const FILE_UPLOAD_REQUESTED = 'watchparty/FILE_UPLOAD_REQUESTED';
export const FILE_UPLOAD_SUCCESS = 'watchparty/FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'watchparty/FILE_UPLOAD_FAILURE';
export const DELETE_VIDEO_REQUESTED = 'watchparty/DELETE_VIDEO_REQUESTED';
export const DELETE_VIDEO_SUCCESS = 'watchparty/DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_FAILURE = 'watchparty/DELETE_VIDEO_FAILURE';
export const GET_CATEGORY_DATA_REQUESTED =
  'watchparty/GET_CATEGORY_DATA_REQUESTED';
export const GET_CATEGORY_DATA_SUCCESS = 'watchparty/GET_CATEGORY_DATA_SUCCESS';
export const GET_CATEGORY_DATA_FAILURE = 'watchparty/GET_CATEGORY_DATA_FAILURE';
export const GET_TEXTBOOK_DATA_REQUESTED =
  'watchparty/GET_TEXTBOOK_DATA_REQUESTED';
export const GET_TEXTBOOK_DATA_SUCCESS = 'watchparty/GET_TEXTBOOK_DATA_SUCCESS';
export const GET_TEXTBOOK_DATA_FAILURE = 'watchparty/GET_TEXTBOOK_DATA_FAILURE';
export const GET_SEARCH_VIDEOS_REQUESTED =
  'watchparty/GET_SEARCH_VIDEOS_REQUESTED';
export const GET_SEARCH_VIDEOS_SUCCESS = 'watchparty/GET_SEARCH_VIDEOS_SUCCESS';
export const GET_SEARCH_VIDEOS_FAILURE = 'watchparty/GET_SEARCH_VIDEOS_FAILURE';
export const FILE_UPLOAD_FROM_COMPUTER_REQUESTED =
  'watchparty/FILE_UPLOAD_FROM_COMPUTER_REQUESTED';
export const FILE_UPLOAD_FROM_COMPUTER_SUCCESS =
  'watchparty/FILE_UPLOAD_FROM_COMPUTER_SUCCESS';
export const FILE_UPLOAD_FROM_COMPUTER_FAILURE =
  'watchparty/FILE_UPLOAD_FROM_COMPUTER_FAILURE';
export const WATCH_PARTY_LIVE_REQ = 'watchparty/WATCH_PARTY_LIVE_REQ';
export const WATCH_PARTY_LIVE_SUCCESS = 'watchparty/WATCH_PARTY_LIVE_SUCCESS';
export const WATCH_PARTY_LIVE_FAILURE = 'watchparty/WATCH_PARTY_LIVE_FAILURE';
export const GET_PROMOTION_REQUESTED = 'watchparty/GET_PROMOTION_REQUESTED';
export const GET_PROMOTION_SUCCESS = 'watchparty/GET_PROMOTION_SUCCESS';
export const GET_PROMOTION_FAILURE = 'watchparty/GET_PROMOTION_FAILURE';
export const DELETE_WATCH_PARTY_REQUESTED =
  'watchparty/DELETE_WATCH_PARTY_REQUESTED';
export const DELETE_WATCH_PARTY_SUCCESS =
  'watchparty/DELETE_WATCH_PARTY_SUCCESS';
export const DELETE_WATCH_PARTY_FAILURE =
  'watchparty/DELETE_WATCH_PARTY_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  watchPartyData: {},
  searchData: [],
  categoryList: [],
  textbooks: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WATCH_PARTY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case WATCH_PARTY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case WATCH_PARTY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_WATCH_PARTY_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_WATCH_PARTY_LIST_SUCCESS: {
      return {
        ...state,
        watchpartyList: action.result.watchPartyList,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_SCHOOL_WATCH_PARTY_LIST_SUCCESS: {
      return {
        ...state,
        schoolWatchpartyList: action.result.watchPartyList,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_PUBLIC_WATCH_PARTY_LIST_SUCCESS: {
      return {
        ...state,
        publicWatchpartyList: action.result.watchPartyList,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_WATCH_PARTY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_WATCH_PARTY_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_WATCH_PARTY_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_WATCH_PARTY_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case ADD_NEXT_VIDEO_URL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ADD_NEXT_VIDEO_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }

    case ADD_NEXT_VIDEO_URL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case INVITE_FRIENDS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case INVITE_FRIENDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }

    case INVITE_FRIENDS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case UPDATE_USER_WATCH_PARTY_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_USER_WATCH_PARTY_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_USER_WATCH_PARTY_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case FILE_UPLOAD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case FILE_UPLOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case FILE_UPLOAD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case DELETE_VIDEO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case DELETE_VIDEO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }

    case DELETE_VIDEO_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CATEGORY_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CATEGORY_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        categoryList: action.result.categories,
      };
    }
    case GET_CATEGORY_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_TEXTBOOK_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TEXTBOOK_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        textbooks: action.result.textbooks,
      };
    }
    case GET_TEXTBOOK_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_SEARCH_VIDEOS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SEARCH_VIDEOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        searchData: action.result.searchResults,
      };
    }
    case GET_SEARCH_VIDEOS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case FILE_UPLOAD_FROM_COMPUTER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case FILE_UPLOAD_FROM_COMPUTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case FILE_UPLOAD_FROM_COMPUTER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case WATCH_PARTY_LIVE_REQ: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case WATCH_PARTY_LIVE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case WATCH_PARTY_LIVE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_PROMOTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PROMOTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_PROMOTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_WATCH_PARTY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case DELETE_WATCH_PARTY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }

    case DELETE_WATCH_PARTY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const scheduleWatchParty = (data, isaddingToContentLib) => {
  let isaddingToContentLibStr = '';
  if (isaddingToContentLib) {
    isaddingToContentLibStr = `?isaddingToContentLib=${isaddingToContentLib}`;
  }
  return {
    types: [WATCH_PARTY_REQUESTED, WATCH_PARTY_SUCCESS, WATCH_PARTY_FAILURE],
    promise: (client) =>
      client.post(`scheduleWatchParty${isaddingToContentLibStr}`, {
        data,
      }),
  };
};

export const getWatchPartyList = (
  userId,
  filterBy,
  islive,
  email,
  searchKey,
  pageNo,
  itemsPerPage,
  orderType,
  type,
  mode
) => {
  const order = orderType ? orderType : 'desc';
  const search = searchKey ? searchKey : '';
  if (type === 0) {
    return {
      types: [
        GET_WATCH_PARTY_LIST_REQUESTED,
        GET_WATCH_PARTY_LIST_SUCCESS,
        GET_WATCH_PARTY_LIST_FAILURE,
      ],
      promise: (client) =>
        client.get(
          `getWatchPartyList/${userId}/${filterBy}?isLive=${islive}&email=${email}&search=${search}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&order=${order}&watchPartyType=${type}&watchPartyMode=${mode}`
        ),
    };
  }
  if (type === 1) {
    return {
      types: [
        GET_WATCH_PARTY_LIST_REQUESTED,
        GET_SCHOOL_WATCH_PARTY_LIST_SUCCESS,
        GET_WATCH_PARTY_LIST_FAILURE,
      ],
      promise: (client) =>
        client.get(
          `getWatchPartyList/${userId}/${filterBy}?isLive=${islive}&email=${email}&search=${search}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&order=${order}&watchPartyType=${type}&watchPartyMode=${mode}`
        ),
    };
  }
  return {
    types: [
      GET_WATCH_PARTY_LIST_REQUESTED,
      GET_PUBLIC_WATCH_PARTY_LIST_SUCCESS,
      GET_WATCH_PARTY_LIST_FAILURE,
    ],
    promise: (client) =>
      client.get(
        `getWatchPartyList/${userId}/${filterBy}?isLive=${islive}&email=${email}&search=${search}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&order=${order}&watchPartyType=0&watchPartyMode=${mode}`
      ),
  };
};

export const getWatchPartyDetails = (
  watchPartyId,
  userEmail,
  userSurrogateId
) => {
  let userEmailStr = '';
  if (userEmail) {
    userEmailStr = `&userEmail=${userEmail}`;
  }
  let userSurrogateIdStr = '';
  if (userSurrogateId) {
    userSurrogateIdStr = `&userId=${userSurrogateId}`;
  }
  return {
    types: [
      GET_WATCH_PARTY_LIST_REQUESTED,
      GET_WATCH_PARTY_LIST_SUCCESS,
      GET_WATCH_PARTY_LIST_FAILURE,
    ],
    promise: (client) =>
      client.get(
        `getWatchPartyDetails?watchPartyId=${watchPartyId}${userEmailStr}${userSurrogateIdStr}`
      ),
  };
};

export const addNextVideoUrlToWatchParty = (data) => {
  return {
    types: [
      ADD_NEXT_VIDEO_URL_REQUESTED,
      ADD_NEXT_VIDEO_URL_SUCCESS,
      ADD_NEXT_VIDEO_URL_FAILURE,
    ],
    promise: (client) =>
      client.post('addNextVideoUrlToWatchParty', {
        data,
      }),
  };
};

export const inviteFrnds = (data) => {
  return {
    types: [
      INVITE_FRIENDS_REQUESTED,
      INVITE_FRIENDS_SUCCESS,
      INVITE_FRIENDS_FAILURE,
    ],
    promise: (client) =>
      client.post('inviteFrnds', {
        data,
      }),
  };
};

export const updateUserWatchPartyStatus = (data) => {
  return {
    types: [
      UPDATE_USER_WATCH_PARTY_STATUS_REQUESTED,
      UPDATE_USER_WATCH_PARTY_STATUS_SUCCESS,
      UPDATE_USER_WATCH_PARTY_STATUS_FAILURE,
    ],
    promise: (client) =>
      client.post('updateUserWatchPartyStatus', {
        data,
      }),
  };
};

export const uploadFile = (data) => {
  return {
    types: [FILE_UPLOAD_REQUESTED, FILE_UPLOAD_SUCCESS, FILE_UPLOAD_FAILURE],
    promise: (client) =>
      client.post('uploadFile', {
        data,
      }),
  };
};

export const deleteVideoFromWatchParty = (data) => {
  return {
    types: [DELETE_VIDEO_REQUESTED, DELETE_VIDEO_SUCCESS, DELETE_VIDEO_FAILURE],
    promise: (client) =>
      client.post('deleteVideoFromWatchParty', {
        data,
      }),
  };
};

export const getCategoryData = (pageNo, itemsPerPage) => {
  const pageNum = pageNo ? pageNo : '';
  const categoryPerPage = itemsPerPage ? itemsPerPage : '';
  return {
    types: [
      GET_CATEGORY_DATA_REQUESTED,
      GET_CATEGORY_DATA_SUCCESS,
      GET_CATEGORY_DATA_FAILURE,
    ],
    promise: (client) =>
      client.get(
        `getCategoryData?pageNo=${pageNum}&itemsPerPage=${categoryPerPage}`
      ),
  };
};

export const searchVideos = (userID, searchKey, category) => {
  const userName = userID ? `userId=${userID}&` : '';
  let categoryName = category ? category : '';
  categoryName = encodeURIComponent(categoryName);
  return {
    types: [
      GET_SEARCH_VIDEOS_REQUESTED,
      GET_SEARCH_VIDEOS_SUCCESS,
      GET_SEARCH_VIDEOS_FAILURE,
    ],
    promise: (client) =>
      client.get(
        `searchVideos?${userName}category=${categoryName}&search=${searchKey}`
      ),
  };
};

export const searchAutocomplete = (
  searchKey,
  board,
  grade,
  page,
  size,
  subject = null
) => {
  searchKey = encodeURIComponent(searchKey);
  let url = `searchAutocomplete?searchKey=${searchKey}&board=${board}&grade=${grade}&page=${page}&size=${size}`;
  if (subject) {
    url += `&subject=${subject}`;
  }
  return {
    types: [
      GET_SEARCH_VIDEOS_REQUESTED,
      GET_SEARCH_VIDEOS_SUCCESS,
      GET_SEARCH_VIDEOS_FAILURE,
    ],
    promise: (client) => client.get(url),
  };
};

export const uploadFileFromMyComputer = (data) => {
  return {
    types: [
      FILE_UPLOAD_FROM_COMPUTER_REQUESTED,
      FILE_UPLOAD_FROM_COMPUTER_SUCCESS,
      FILE_UPLOAD_FROM_COMPUTER_FAILURE,
    ],
    promise: (client) =>
      client.post('uploadFileFromMyComputer', {
        data,
      }),
  };
};

export const watchPartyLive = (data, id) => {
  return {
    types: [
      WATCH_PARTY_LIVE_REQ,
      WATCH_PARTY_LIVE_SUCCESS,
      WATCH_PARTY_LIVE_FAILURE,
    ],
    promise: (client) =>
      client.post(`watchpartyLive/${id}`, {
        data,
      }),
  };
};

export const getPromotion = () => {
  return {
    types: [
      GET_PROMOTION_REQUESTED,
      GET_PROMOTION_SUCCESS,
      GET_PROMOTION_FAILURE,
    ],
    promise: (client) => client.get('getPromotion'),
  };
};

export const deleteWatchParty = (wpId, userSurrogateId) => {
  return {
    types: [
      DELETE_WATCH_PARTY_REQUESTED,
      DELETE_WATCH_PARTY_SUCCESS,
      DELETE_WATCH_PARTY_FAILURE,
    ],
    promise: (client) =>
      client.del(`deleteWatchParty/${wpId}/${userSurrogateId}`),
  };
};

export const getTextbooks = (grade, board) => {
  return {
    types: [
      GET_TEXTBOOK_DATA_REQUESTED,
      GET_TEXTBOOK_DATA_SUCCESS,
      GET_TEXTBOOK_DATA_FAILURE,
    ],
    promise: (client) =>
      client.get(`getTextbooks?grade=${grade}&board=${board}`),
  };
};
