import React, { Component } from 'react'
import { connect } from 'react-redux'
import FeedbackComponent from '@components/Feedback';
import { saveFeedback } from '@reducers/register';
import { setNotificationMessage } from '@reducers/notification';
import {reset} from 'redux-form';
import { formValueSelector } from 'redux-form';


const moduleOptions = [
    {label: 'Dashboard', value: 'Dashboard'},
    {label: 'Content Library', value: 'Content Library'},
    {label: 'My Channel', value: 'My Channel'},
    {label: 'Upload Video', value: 'Upload Video'},
    {label: 'Watch Party', value: 'Watch Party'},
    {label: 'Record Reaction', value: 'Record Reaction'},
    {label: 'Reaction Playback', value: 'Reaction Playback'},
    {label: 'Other', value: 'Other'}
]
export class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            module: null,
            disableBtn: true,
            charCount: 0,
        });
        this.totalChar = 500;
    }

    handleSubmit = (value, dispatch) => {
        if (value) {
            const data = {
                module: value.Modules.value,
                subject: value.Subject,
                description: value.Description ? value.Description : '',
                userEmail: window.localStorage.getItem('email') ? window.localStorage.getItem('email') : ''
            }
            this.props.saveFeedback(data).then((data) => {this.successFeedbackRequest(data); dispatch(reset('FeedbackForm'))}).catch((data) => this.failureFeedbackRequest(data));
        }    
    }

    componentDidMount() {
        if((window.localStorage.getItem('access_token') === null || window.localStorage.getItem('access_token') === undefined) && window.innerWidth >= 1200) {
            this.props.history.push('/login');
        } 
        if(window.innerWidth < 1200) {
            this.props.history.push('/about');
        }
    }

    componentDidUpdate(prevprops, prevState) {
        if ((prevprops.modules !== this.props.modules) || (prevprops.subject !== this.props.subject) || prevState.charCount !== this.state.charCount) {
            if (this.props.modules !== undefined && this.props.subject !== undefined && this.props.modules !== '' && this.props.subject.trim() !== '' && this.props.modules !== null && this.props.subject !== null && this.state.charCount <= 500) {
                this.setState({disableBtn: false})
            } else {
                this.setState({disableBtn: true})
            }
        }
    }

    successFeedbackRequest = (data) => {
        this.props.setNotificationMessage({
          message: data.message,
          type: 'success',
          autoClose: true
        });
        this.setState({module: {}, charCount: 0})
      }
    
      failureFeedbackRequest = (data) => {
        this.props.setNotificationMessage({
          message: data.errMsg,
          type: 'danger',
          autoClose: true
        });
      }

      setModules = (e) => {
          this.setState({module: e})
      }

      onChangeTextArea = (e) => {
        this.setState((prevState) => ({
            charCount: e.target.value.length,
            // disableBtn: e.target.value.length > this.totalChar ? true : prevState.disableBtn
        }))
      }

      onKeyDownDescription = (e) => {
        if (this.state.charCount >= this.totalChar && e.keyCode !== 8) {
            e.preventDefault()
            return false
        }
      }

    render() {
        return (
            <div>
                <FeedbackComponent 
                moduleOptions={moduleOptions} 
                onSubmit={this.handleSubmit}
                setModules={this.setModules}
                module={this.state.module}
                windowWidth={this.props.windowWidth}
                disableBtn={this.state.disableBtn}
                onChangeTextArea={this.onChangeTextArea}
                charCount={this.state.charCount}
                onKeyDownDescription={this.onKeyDownDescription}
                totalChar={this.totalChar}
                />
            </div>
        )
    }
}
const selector = formValueSelector('FeedbackForm');

const mapStateToProps = (state) => {
    return {
        modules: selector(state, 'Modules'),
        subject: selector(state, 'Subject'),
        windowWidth: state.ui.windowWidth
      };
    }

const mapDispatchToProps = { 
    saveFeedback, 
    setNotificationMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
