import React from 'react';
import FooterComponent from '@components/common/Modal/Footer';
import {
  Modal, ModalHeader, ModalBody
} from 'reactstrap';
import cn from 'classnames';
import styles from './modal.module.css';
import close from '../../../assets/icons/close.svg'

const ModalComponent = (props) => {
  const {
    type,
    title,
    subTitle,
    toggle,
    isOpen,
    message,
    submitButtonName = 'Cancel',
    onCancel,
    selectedLanguage = 'EN',
    onSubmit = toggle,
    // backdrop = true,
    wrapClassName,
    modalClassName,
    backdropClassName,
    contentClassName,
    dialogClassName,
    cancel,
    hideClose,
    modalTitleClass,
    size,
    progressIndicator,
    onOpened = () => {}
  } = props;

  const clear = () => {
     cancel();
  }

  const reloadPage = () => {
    toggle();
    if (title === 'Create Watch Party') {
      cancel();
    }
  }

  const closeBtn = <button className="btn-vidberrys btn-gray" onClick={reloadPage}><img width="15px" src={close} alt="" /></button>;

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      toggle={toggle}
      className={cn('border-0', dialogClassName)}
      wrapClassName={wrapClassName}
      modalClassName={modalClassName}
      backdropClassName={backdropClassName}
      contentClassName={cn(styles.modalContent, contentClassName, progressIndicator && styles.transparentBg)}
      size={size}
      // centered
      onOpened={onOpened}
    >
      <ModalHeader toggle={reloadPage} close={closeBtn} className={cn('pb-1 p-0 ml-8', title ? (hideClose ? styles.hideCloseandborder : styles.modalHeader) : (hideClose ? styles.hideCloseandborder : styles.modalHeaderBorder))} tag="div">
        {title && (
          <h3 className={cn('m-0 modal-title-color', modalTitleClass)}>
            {title}
          </h3>
        )}
        {subTitle && (
          <h5>
            {subTitle}
          </h5>
        )}
      </ModalHeader>
      <ModalBody className="p-0">
        {type === 'confirmation' && (
          <>
            {message}
          </>
        )}
        {type === 'popup' && (
          <>
            {props.children}
          </>
        )}
        {(title === 'Create Watch Party') && (
        <FooterComponent
          onSubmit={onSubmit}
          onCancel={onCancel}
          submitButtonName={submitButtonName}
          selectedLanguage={selectedLanguage}
          title={title}
          clear={clear}
        />
      )}
      </ModalBody>
    </Modal>
  );
};

export default ModalComponent;
