export const GET_FRIENDS_LIST_REQUESTED = 'friendslist/GET_FRIENDS_LIST_REQUESTED';
export const GET_FRIENDS_LIST_SUCCESS = 'friendslist/GET_FRIENDS_LIST_SUCCESS';
export const GET_FRIENDS_LIST_FAILURE = 'friendslist/GET_FRIENDS_LIST_FAILURE';

export const GET_FOLLOWINGS_LIST_REQUESTED = 'friendslist/GET_FOLLOWINGS_LIST_REQUESTED';
export const GET_FOLLOWINGS_LIST_SUCCESS = 'friendslist/GET_FOLLOWINGS_LIST_SUCCESS';
export const GET_FOLLOWINGS_LIST_FAILURE = 'friendslist/GET_FOLLOWINGS_LIST_FAILURE';



const initialState = {
    friendsData: [],
    followingsData: []
  }

  export default (state = initialState, action) => {
    switch (action.type) {
  
      case GET_FRIENDS_LIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_FRIENDS_LIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          friendsListCount: action.result.totalCount && action.result.totalCount,
          friendsData: action.result.userFriendsList
        };
      }
      case GET_FRIENDS_LIST_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }

      case GET_FOLLOWINGS_LIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_FOLLOWINGS_LIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          followingsData: action.result.userFollowingsList
        };
      }
      case GET_FOLLOWINGS_LIST_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }

      default:
        return state;
    }
  };

  export const getFriendsList = (status, userSurrogateId, pageNo, itemsPerPage) => {
    return {
      types: [GET_FRIENDS_LIST_REQUESTED, GET_FRIENDS_LIST_SUCCESS, GET_FRIENDS_LIST_FAILURE],
      promise: (client) => client.get(`getFriendsRequest?status=${status}&userId=${userSurrogateId}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`),
    };
  };

  export const getFollowingsData = (userSurrogateId, pageNo, itemsPerPage) => {
    return {
      types: [GET_FOLLOWINGS_LIST_REQUESTED, GET_FOLLOWINGS_LIST_SUCCESS, GET_FOLLOWINGS_LIST_FAILURE],
      promise: (client) => client.get(`getFollowingsList?userId=${userSurrogateId}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`),
    };
  };