import React from 'react';
import style from './Template.module.css';
import cn from 'classnames';
import Button from '@components/common/formElements/Button';
import exploreMore from '@assets/images/MarketingTemplates/exploreMore.svg';
import { withRouter } from 'react-router';

const Template = (props) => {
    const { items } = props;

    const handleScroll = (id, length) => {
        var elmnt = id === length - 1 ? document.getElementById('footerblock') : document.getElementById(`block${id + 1}`);
        elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    const handleLinkClick = (link) => {
        props.history.push(link)
    }
    return (
        <div className={cn('row m-0', style.marketingTemplate)}>
            <div className="custom-col-36 p-0">
                {items.header && (
                    <div className={cn('row m-0 justify-content-center py-3', props.block1Background, style.row_height)}>
                        {items.header.childImg && (
                            <div className="custom-col-xlg-22 custom-col-xl-28">
                                <div className="row justify-content-center m-0 pt-40">
                                    <div className="custom-col-xlg-20 custom-col-xl-25">
                                        <div className={cn(style.description, 'w-100 text-white text-center block1_title')}>{items.header.title}</div>
                                        <div className={cn(style.heading, 'w-100 text-white text-center block1_heading')}>{items.header.heading}</div>
                                        <div className={cn(style.description, 'w-100 text-white text-center block1_description')}>{items.header.description}</div>
                                    </div>
                                </div>
                                <div className="row mt-80">
                                    <div className={cn('container', props.type === 'reaction' ? 'custom-col-xlg-36 custom-col-xl-25 p-0' : 'custom-col-xlg-25 custom-col-xl-15 p-0')}>
                                        <img src={items.header.childImg} alt="" />
                                    </div>
                                </div>
                            </div>
                        )}
                        {items.header.img && (
                            <img src={items.header.img} alt="" />
                        )}
                        <div className="row m-0 w-100 justify-content-center"><Button onClick={() => handleScroll(-1, 2)} className={style.exploreMoreButton} title="Explore More ↓" /></div>
                    </div>
                )}
                {items.contents.map((el, index) => {
                    return (
                        <div id={`block${index}`} className={cn('row m-0', style.row_height)}>
                            {!el.custom && (
                                <>
                                    <div className={cn('custom-col-18 align-self-center', el.order && el.order === 1 ? 'order-1' : 'order-2')}>
                                        <div className="row justify-content-start m-0">
                                            <div className={el.order && el.order === 1 ? 'custom-col-xlg-23 custom-col-xl-27 ml-auto' : 'custom-col-xlg-23 custom-col-xl-30 m-auto'}>
                                                <p className="m-0">
                                                    <div className={style.step}>{el.step}</div>
                                                    <div className={cn(style.heading, 'mb-40')}>{el.heading}</div>
                                                    <div className={cn(style.description, 'mb-80')}>
                                                        <p className="m-0">
                                                            {el.description.map(text => {
                                                                return (
                                                                    <span className="display-block mt-20">{text}</span>
                                                                )
                                                            })}
                                                        </p>
                                                        {el.isLink && (<span className="cursor text-info" onClick={() => handleLinkClick(el.link)}>{el.linkText}</span>)}
                                                    </div>
                                                    {(index < items.contents.length - 1 || (index === items.contents.length - 1 && items.footer)) && (
                                                        <Button onClick={() => handleScroll(index, items.contents.length)} className={style.exploreMoreButton} icon={exploreMore} />
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cn('custom-col-18 align-self-center', el.order && el.order === 1 ? 'order-2' : 'order-1')}>
                                        {el.groupedImage ? (
                                            <>
                                                <div className="row m-0 mb-3 justify-content-center">
                                                    {el.grayDiv && (
                                                        <div className="custom-col-15">
                                                            <div className={style.grayDiv} />
                                                        </div>
                                                    )}
                                                    <div className={el.grayDiv ? 'custom-col-15' : 'custom-col-25 p-0'}>
                                                        <img className={cn(el.className)} src={el.img} alt="" />
                                                    </div>
                                                </div>
                                                <div className="row m-0 justify-content-center">
                                                    {el.grayDiv && (
                                                        <div className="custom-col-15">
                                                            <div className={style.grayDiv} />
                                                        </div>
                                                    )}
                                                    <div className={el.grayDiv ? 'custom-col-15' : 'custom-col-25 p-0'}>
                                                        <img className="" src={el.img_second} alt="" />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                                <div className="row m-0">
                                                    <img className={el.order && el.order === 1 ? 'custom-offset-2 custom-col-26' : 'custom-col-26 ml-auto'} src={el.img} alt="" />
                                                </div>
                                            )}
                                    </div>
                                </>
                            )}
                            {el.custom && (
                                <div className={cn('custom-col-xl-27 custom-col-xlg-23 align-self-center container')}>
                                    <div className="row justify-content-center m-0">
                                        <p className="m-0 text-justify w-100">
                                            <div className={cn(style.heading, 'mb-40 text-center')}>{el.heading}</div>
                                            <div className={cn(style.description, 'w-100')}>
                                                <div className="row m-0">
                                                    <div className={el.doubleContent ? 'custom-col-20 p-0' : 'custom-col-36 p-0'}>
                                                        {el.description.map((text, index) => {
                                                            return (
                                                                <p className='m-0 mb-20'>
                                                                    <span className="display-block"><span className="fw-600">{text.mainText}</span>
                                                                        {text.secondaryText && (
                                                                            <span className="">{text.secondaryText}</span>
                                                                        )}
                                                                    </span>
                                                                </p>
                                                            )
                                                        })}
                                                    </div>
                                                    {el.doubleContent && (
                                                        <div className="custom-col-16">
                                                            {el.secondContent}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    {el.hasExploreMore && (
                                        <Button onClick={() => handleScroll(index, items.contents.length)} className={cn(style.exploreMoreButton, 'm-auto mt-40')} icon={exploreMore} />
                                    )}
                                </div>
                            )}
                        </div>
                    )
                })}
                {items.footer && (
                    <div id="footerblock" className={cn('row m-0 mt-100 positionRelative', props.block1Background, style.row_height)}>
                        <div className="custom-col-36 py-3 align-self-center">
                            <div className="row m-0">
                                <div className="custom-col-12 d-flex justify-content-center mt-50">
                                    <img className={style.footer_profile_img} src={items.footer.img1} alt="" />
                                </div>
                                <div className="custom-col-24 d-flex justify-content-center">
                                    <img className={style.footer_profile_img} src={items.footer.img2} alt="" />
                                </div>
                            </div>
                            <div className="row m-0 justify-content-center">
                                <div className="custom-col-xl-20 custom-col-xlg-13 container">
                                    <div className={cn(style.description, 'w-100 text-white text-center mb-20')}>{items.footer.title}</div>
                                    <div className={cn(style.heading, 'w-100 text-white text-center mb-20')}>{items.footer.heading}</div>
                                    <div className={cn(style.description, 'w-100 text-white text-center mb-20')}>{items.footer.description}</div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="custom-col-12 d-flex justify-content-end">
                                    <img className={cn(style.footer_profile_img, style.width_auto)} src={items.footer.img3} alt="" />
                                </div>
                                <div className="custom-col-12 d-flex justify-content-center h-100">
                                    <Button onClick={() => props.onClick()} className={cn('align-self-start', style.watch_party_button, items.footer.color)} title={items.footer.buttonText} />
                                </div>
                                <div className="custom-col-12 d-flex justify-content-start">
                                    <img className={cn('mt-50', style.footer_profile_img, style.width_auto)} src={items.footer.img4} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default withRouter(Template)
