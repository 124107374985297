import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import otp from './otp';
import localization from './localization';
import register from './register';
import ui from './ui';
import curatedVideos from './curatedVideos';
import notification from './notification';
import watchparty from './watchparty';
import reactionData from './reactionData';
import reactions from './reactions';
import reactionVideosList from './reactionVideosList';
import videoViews from './videoViews';
import chat from './chat';
import playbackChatData from './playbackChatData';
import editVideos from './editVideos';
import friendsFans from './friendsFans';
import friendslist from './friendslist';
import playbackComments from './playbackComments';
import chapters from './chapters';
import sessions from './sessions';

const appReducer = combineReducers({
  router: routerReducer,
  otp,
  localization,
  register,
  ui,
  curatedVideos,
  notification,
  watchparty,
  chapters,
  form: formReducer,
  reactionData,
  reactions,
  reactionVideosList,
  videoViews,
  chat,
  playbackChatData,
  editVideos,
  friendsFans,
  friendslist,
  playbackComments,
  sessions,
});

// Setup root reducer
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
