import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import EN from '../../localization/EN';
import cn from 'classnames';
import style from './SidebarMenu.module.scss';
import Home from '../../assets/icons/Home.svg';
import Forum from '../../assets/icons/community.svg';
import FriendsNFans from '../../assets/icons/FriendsNFans.svg';
import ByCategories from '../../assets/icons/ByCategories.svg';
import WatchParties from '../../assets/icons/WatchParties.svg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Button from '@components/common/formElements/Button';
import bars from '../../assets/icons/Union.svg';
import Modal from '@components/common/Modal';
import { watchPartyLive } from '@reducers/watchparty';
import VideoViews from '@components/VideoViews';
import { getVideoViewsCount } from '@reducers/videoViews';
import MenuList from './Menulist';
import { activeMenu } from '@reducers/ui';
import { setSelectedFriend } from '@reducers/friendsFans';

const SidebarMenu = (props) => {
  const [activeMenu, setActiveMenu] = useState('/');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isModelOpened, toggleModal] = useState(false);
  const [isForumMenuClicked, setForumMenuClick] = useState('');
  const [viewsCount, setViewsCount] = useState(0);
  const [reactionsCount, setReactionsCount] = useState(0);
  const [creditsCount, setCreditsCount] = useState(0);
  const [menuClicked, setMenuClicked] = useState('');
  const pathname = props.location.pathname.split('/')[1];
  const isSessionRoom = pathname === 'sessionroom';
  const userDetails = JSON.parse(window.localStorage.getItem('userDetails'));
  const isSignIn =
    window.localStorage.getItem('userdetailstemp') != null ? true : false;
  let leftbarMenu = style.leftbar_menu;
  const menulist = [
    {
      id: 1,
      name: 'Home',
      image: Home,
    },
    {
      id: 2,
      name: 'My Profile',
      image: FriendsNFans,
    },
    {
      id: 3,
      name: 'By Subjects',
      image: ByCategories,
    },
    {
      id: 4,
      name: 'Study Sessions',
      image: WatchParties,
    },
    {
      id: 5,
      name: 'Feedback',
      icon: 'fa fa-commenting fa-lg',
    },
    // {
    //   id: 6,
    //   name: 'Forum',
    //   image: Forum
    // },
    // {
    //   id: 7,
    //   name: 'Community',
    //   img: 'ForumImg'
    // }
  ];
  if ((props.windowWidth >= 1920 || props.showSidebar) && !isCollapsed) {
    leftbarMenu = leftbarMenu;
  } else {
    leftbarMenu = `${leftbarMenu} ${style.toggled_menu}`;
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevCount = usePrevious(activeMenu);

  useEffect(() => {
    if (
      pathname === 'conferenceroom' ||
      isSessionRoom ||
      pathname === window.localStorage.getItem('userName') ||
      (props.selectedFriend ? pathname === props.selectedFriend.userName : null)
    ) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
    setActiveMenu(
      pathname === ''
        ? 'Home'
        : pathname === window.localStorage.getItem('userName') ||
          (props.selectedFriend && pathname === props.selectedFriend.userName)
        ? 'My Channel'
        : pathname === 'feedback'
        ? 'Feedback'
        : props.location.pathname === '/contentLibrary/subjects'
        ? 'By Categories'
        : props.location.pathname === '/contentLibrary/completed-study-sessions'
        ? 'Watch Parties'
        : ''
    );
  }, [props.location.pathname, props.selectedFriend]);

  useEffect(() => {
    if (prevCount !== activeMenu && activeMenu === 'Community') {
      setActiveMenu(prevCount);
    }
    if (activeMenu !== 'My Channel') {
      props.getVideoViewsCount().then((data) => {
        setViewsCount(
          data.noOfViews !== viewsCount ? data.noOfViews : viewsCount
        );
        setReactionsCount(
          data.noOfReactions !== reactionsCount
            ? data.noOfReactions
            : reactionsCount
        );
        setCreditsCount(
          data.noOfCredtis !== creditsCount ? data.noOfCredtis : creditsCount
        );
      });
    }
  }, [activeMenu, window.localStorage.getItem('userDetails')]);

  const handleClick = (menu, isfromModel) => {
    setForumMenuClick(menu);
    if (
      window.localStorage.getItem('userName') === null &&
      (menu === 'My Channel' || menu === 'Feedback')
    ) {
      const clickedMenu = menu === 'My Channel' ? 'myChannel' : 'feedback';
      window.localStorage.setItem('redirectionPage', clickedMenu);
      props.history.push('/login');
      if (props.windowWidth < 1920 && props.showSidebar) {
        props.toggle();
      }
    } else if (
      (pathname === 'conferenceroom' || isSessionRoom) &&
      !isfromModel
    ) {
      setMenuClicked(menu);
      toggleModal(true);
    } else if (
      menu !== activeMenu ||
      (menu === 'My Channel' &&
        props.location.pathname !==
          `/${window.localStorage.getItem('userName')}`)
    ) {
      if (pathname === 'conferenceroom') {
        const watchPartyLiveObj = {
          userName: window.localStorage.getItem('userName'),
        };
        const id = props.location.pathname.split('/')[2];
        props.watchPartyLive(watchPartyLiveObj, id);
      }

      const setSelectedFriend = () => {
        if (props.selectedFriend !== '') {
          props.setSelectedFriend(userDetails);
        }
      };
      setActiveMenu(menu);
      if (props.windowWidth < 1920 && props.showSidebar) {
        props.toggle();
      }
      const userName = window.localStorage.getItem('userName');
      switch (menu) {
        case '/':
          props.history.push('/');
          setSelectedFriend();
          break;
        case 'My Profile':
          props.history.push(userName ? `/${userName}` : '/login');
          setSelectedFriend();
          break;
        case 'FriendsNFans':
          props.history.push('/');
          break;
        case 'By Subjects':
          props.history.push('/contentLibrary/All%20Subjects');
          setSelectedFriend();
          break;
        case 'ByChannels':
          props.history.push('/');
          break;
        case 'Study Sessions':
          props.history.push('/contentLibrary/completed-study-sessions');
          setSelectedFriend();
          break;
        case 'Feedback':
          props.history.push('/feedback');
          setSelectedFriend();
          break;
        // case 'Forum':
        //   window.open('https://forum.vidberrys.com/');
        //   break;
        // case 'Community':
        //   window.open('https://community.vidberrys.com/');
        //   break;
        default:
          break;
      }
    }
  };
  return (
    <>
      <div
        className={cn(
          pathname === 'conferenceroom' || isSessionRoom ? null : 'mt-15',
          pathname === 'login' || (pathname === 'password' && isSignIn)
            ? 'd-none'
            : props.customClass
        )}
      >
        {(pathname === 'conferenceroom' || isSessionRoom) && (
          <div className={props.windowWidth >= 1920 ? 'py-26' : 'py-16'}>
            <Button
              disabled
              className='btn-gray align-self-center'
              icon={bars}
            />
          </div>
        )}
        <div className={pathname === 'conferenceroom' ? 'mt-15' : null}>
          {menulist.map((el) => {
            return (
              <MenuList
                key={el.id}
                item={el}
                windowWidth={props.windowWidth}
                showSidebar={props.showSidebar}
                isCollapsed={isSessionRoom ? true : isCollapsed}
                handleClick={handleClick}
                leftbarMenu={leftbarMenu}
                activeMenu={activeMenu}
                isForumMenuClicked={isForumMenuClicked}
              />
            );
          })}
        </div>
      </div>
      {pathname !== 'conferenceroom' &&
        !isSessionRoom &&
        props.selectedFriend &&
        pathname !== props.selectedFriend.userName &&
        pathname !== window.localStorage.getItem('userName') &&
        props.windowWidth >= 1920 &&
        userDetails && (
          <div className={cn('custom-col-36 position bottom-50 counterWidth')}>
            <VideoViews
              views={viewsCount}
              reactions={reactionsCount}
              credits={creditsCount}
            />
          </div>
        )}
      {(props.showSidebar || props.windowWidth >= 1920) &&
        activeMenu !== 'My Channel' &&
        pathname !== 'conferenceroom' &&
        !isSessionRoom &&
        pathname !== 'login' && (
          <div
            className={cn(
              'justify-content-between',
              style.linkStyle,
              props.windowWidth < 1920
                ? 'bottom-10 px-4'
                : 'bottom-20 counterWidth'
            )}
          >
            <span className={cn(style.linkClr)}>{EN.VIDBERRYS_VERSION}</span>
            <a
              className={cn(style.linkClr, 'ml-8')}
              rel='noreferrer'
              target='_blank'
              href={'https://community.vidberrys.com/privacy-policy/'}
            >
              Privacy
            </a>
            {/* <span>
            <a className={cn('ml-5 mr-2', style.linkClr)} rel="noreferrer" target="_blank" href={'https://community.vidberrys.com/community-guide/'}>Guidelines</a>
          </span> */}
          </div>
        )}
      <br />
      <Modal
        title={
          isSessionRoom ? 'End Session Confirmation' : 'End Party Confirmation'
        }
        type='popup'
        dialogClassName='align-items-center'
        toggle={() => toggleModal(!isModelOpened)}
        isOpen={isModelOpened}
        size='lg'
        customHeaderClass='fs-24 clr-black font-bold'
        modalCloseClass='fs-20'
        modalTitleClass='w-100 text-center'
        contentClassName='border-radius-12'
      >
        <div className='pl-10'>
          {isSessionRoom
            ? EN.END_STUDY_SESSION_WARNING
            : EN.CLOSE_CONF_ROOM_WARNING}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn-vidberrys btn-maroon w-128'
              onClick={() => {
                toggleModal(false);
                handleClick(menuClicked, true);
              }}
            >
              {EN.YES}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  showSidebar: state.ui.showSidebar,
  windowWidth: state.ui.windowWidth,
  views: state.videoViews.viewsCount,
  reactions: state.videoViews.reactionCount,
  credits: state.videoViews.creditsCount,
  selectedFriend: state.friendsFans.selectedFriend,
  loading: state.videoViews.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      watchPartyLive,
      getVideoViewsCount,
      activeMenu,
      setSelectedFriend,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
);
