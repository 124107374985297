export default {
  MOBILE_NUMBER: 'Please enter valid mobile number',
  PASSWORD: 'Please enter valid password',
  EMAIL_ID: 'Please enter valid email',
  REENTER_PASSWORD: 'Please re-enter password',
  FIRST_NAME: 'Please enter your first name',
  LAST_NAME: 'Please enter your last name',
  USERNAME: 'Please enter your username',
  WATCHPARTYNAME: 'Special Characters are not allowed',
  PASSWORD_OR_OTP: 'Please enter valid password or Otp',
  NEW_CONFIRM_PWD_NOT_SAME: 'New password and confirm password does not match',
  OLD_NEW_PWD_SAME: 'New password is same as old password',

  PHONE_OR_EMAIL_ID: 'Please enter valid email Address', //'Please enter valid phone or email Address',
  OTP: 'Please enter valid one time password',
  CHAT_MESSAGE_NOT_DELETED: 'Message not deleted'
};
