import bg1 from '@assets/images/background-1.jpg';
import bg2 from '@assets/images/background-2.jpg';
import bg3 from '@assets/images/background-3.jpg';
import bg4 from '@assets/images/background-4.jpg';
import bg5 from '@assets/images/background-5.jpg';
import bg6 from '@assets/images/background-6.jpg';
import bg7 from '@assets/images/background-7.jpg';
export default {
    CHANNEL_TAB: ['Sessions', 'Watch Parties', 'Videos', 'Friends/Fans'],
    WATCHPARTY_TYPE_OPTIONS: [{ label: 'All Watch Parties', value: 'All' }, { label: 'Created', value: 'Created' }, { label: 'Invited', value: 'Invited' }],
    ORDER_OPTIONS: [{ value: 'desc', label: "Latest" }, { value: 'asc', label: "Oldest" }],
    UploadOptions: [{ value: 0, label: ' From My computer' }, { value: 1, label: 'From Youtube' }],
    UploadProfileOption: [{ value: 0, label: 'Update Username' }, { value: 1, label: 'Update Password' }],
    FRIENDSFANSOPTIONS: [{ value: 0, label: 'Friends' }, { value: 1, label: 'Fans' }, { value: 2, label: 'Following' }],
    PROFILE_TAB: ['Following', 'Fans'],
    PRIVACY_TAB: ['Privacy Policy', 'Community Guidlines'],
    HandGestures: [{id: 0, value: 'like'}, {id: 1, value: 'dislike'}, {id: 2, value: 'raisedHand'}, {id: 3, value: 'nextHand'}, {id: 4, value: 'previousHand'}, {id: 5, value: 'closed'}, {id: 6, value: 'victory'}, {id: 7, value: 'rock'}, {id: 8, value: 'firstFinger'}, {id: 20, value: 'other'}],
    bgImages: [{ label: '1', value: null }, { label: '2', value: `${bg1}` },{ label: '3', value: `${bg2}` },{ label: '4', value: `${bg3}` },{ label: '5', value: `${bg4}` },{ label: '6', value: `${bg5}` },{ label: '7', value: `${bg6}` },{ label: '8', value: `${bg7}` }]
}