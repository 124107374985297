import React from 'react'
import Modal from '@components/common/Modal';
import style from './BrowserDetectModal.module.css'
const BrowserProperty = (props) => {
    return (
        <>
        <Modal
            toggle={props.toggle}
            isOpen={props.open}
            type="popup"
            title={props.title}
            dialogClassName={style.modal_property}
            contentClassName="p-4"
            modalTitleClass="fs-15"
        />
        </>
    )
}

export default BrowserProperty
