import React, { useState } from 'react';
import Template from '@components/common/Template';
import reaction_b1 from '@assets/images/MarketingTemplates/reaction_b1.png';
import block2 from '@assets/images/MarketingTemplates/reaction_b2.png';
import reaction_submit from '@assets/images/MarketingTemplates/reaction_submit.png';
import reaction_record from '@assets/images/MarketingTemplates/reaction_record.png';
import reaction_record_progress from '@assets/images/MarketingTemplates/reaction_record_progress.png';
import profile1 from '@assets/images/MarketingTemplates/profile1.svg';
import profile2 from '@assets/images/MarketingTemplates/profile2.svg';
import profile3 from '@assets/images/MarketingTemplates/profile3.svg';
import profile4 from '@assets/images/MarketingTemplates/profile4.svg';
import WatchPartyModel from '@components/Models/WatchPartyModel/watchPartyModelContainer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { scheduleWatchParty } from '@reducers/watchparty';
import { setNotificationMessage } from '@reducers/notification';
import style from './Templates.module.css';

const Watchparty = (props) => {
    const [watchPartyModelOpened, setWatchPartyModelOpened] = useState(false);
    const items = {
        header: {
            childImg: reaction_b1,
            title: 'How to Start Create',
            heading: 'Reaction Videos',
            description: 'Create high quality, engaging reaction videos with friends directly on Vidberrys without the need for complex software.' 
        },
        contents: [
            {
                step: '01 Step',
                heading: 'Create watch party and select video',
                description: [
                    'First, create a watch party using the video you want to react to. Invite participants to the watch party.',
                    'To learn how to create a watch party'
                ],
                linkText: 'Click Here',
                isLink: true,
                link: '/howto/watchparty',
                img: block2,
                order: 1,
                custom: false
            },
            {
                step: '02 Step',
                heading: 'Record Reaction',
                description: [
                    'Click on React button, select “Record me” OR “Record group”. To start the reaction, click on Start Record button.',
                    'You can pause or fast forward your video and continue recording your reaction. Video playback will record the video events (pause, ff, rev) along with the recording'
                ],
                img: reaction_record,
                img_second: reaction_record_progress,
                order: 2,
                groupedImage: true,
                custom: false
            },
            {
                step: '03 Step',
                heading: 'Publish Reaction Video',
                description: [
                    'After recording, you can submit the recording for playback and make it public or private. You have the option of changing the settings later in “My channel”',
                    `You can playback the reaction video from the home page and it's also available in My channel page.`
                ],
                img: reaction_submit,
                order: 1,
                custom: false
            },
            {
                heading: 'Recording Guidelines',
                custom: true,
                description: [
                    {
                        mainText: '1. Headphones are highly recommended. ',
                        secondaryText: 'Use headphones and mute when not speaking. It eliminates any echo due to audio feedback picked up by the microphone'
                    },
                    {
                        mainText: '2. If you don’t have a headphone, make sure to reduce the volume of your speaker  while recording'
                    },
                    {
                        mainText: '3. Record in a well-lit room'
                    },
                    {
                        mainText: '4. Keep yourself centered in the camera view ',
                        secondaryText: 'and make sure your surrounding space is clean and not distracting'
                    },
                    {
                        mainText: '5. Review your recording and don’t be afraid to reshoot. ',
                        secondaryText: 'Get a few retakes of reaction videos & review them. You can publish the ones you like and delete the ones you don’t.  Shooting great, professional looking reaction videos gets better with practice. There are two microphone audio streams in a watch party for better reaction control. One for participant communication that can be muted and unmuted and the other for reaction recording that cannot be unmuted.'
                    },
                    {
                        mainText: '6. Have fun with your reaction videos!'
                    }
                ],
                hasExploreMore: true
            }
        ],
        footer: {
            title: 'Let’s Go!',
            heading: 'Start Your First Reaction Video',
            description: 'Create high quality and engaging reaction videos with friends.',
            img1: profile1,
            img2: profile2,
            img3: profile3,
            img4: profile4,
            color: 'color-howto-red',
            buttonText: 'Create a Reaction Video'
        }
    }

    const handleClick = () => {
        if ('userName' in window.localStorage) {
            setWatchPartyModelOpened(true);
        } else {
            props.history.push('/login')
        }
    }

    const toggleWatchPartyModal = () => {
        setWatchPartyModelOpened(!watchPartyModelOpened);
    }
    return (
        <>
            <Template
                items={items}
                onClick={handleClick}
                block1Background={style.reaction_block1Background}
                type="watchparty"
            />
            {watchPartyModelOpened && (
            <WatchPartyModel
                scheduleWatchParty={props.scheduleWatchParty}
                open={watchPartyModelOpened}
                toggle={toggleWatchPartyModal}
                setNotificationMessage={props.setNotificationMessage}
            />
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    scheduleWatchParty,
    setNotificationMessage,
  }, dispatch);

export default connect(null, mapDispatchToProps)(Watchparty)
