export const GET_COMMENTS_REQUESTED = 'reactions/GET_COMMENTS_REQUESTED';
export const GET_COMMENTS_SUCCESS = 'reactions/GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILURE = 'reactions/GET_COMMENTS_FAILURE';
export const PUT_COMMENTS_REQUESTED = 'reactions/PUT_COMMENTS_REQUESTED';
export const PUT_COMMENTS_SUCCESS = 'reactions/PUT_COMMENTS_SUCCESS';
export const PUT_COMMENTS_FAILURE = 'reactions/PUT_COMMENTS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  comments: []
};

export default (state = initialState, action) => {
    switch (action.type) {
      case GET_COMMENTS_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_COMMENTS_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          comments: action.result
        };
      }
      case GET_COMMENTS_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      case PUT_COMMENTS_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case PUT_COMMENTS_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case PUT_COMMENTS_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      default:
        return state;
    }
  };
  
  export const getComments = (reactionId, pageNo, itemsPerPage) => {
    return {
      types: [GET_COMMENTS_REQUESTED, GET_COMMENTS_SUCCESS, GET_COMMENTS_FAILURE],
      promise: (client) => client.get(`getPlaybackComments/${reactionId}?pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`)
    };
  };
  
  export const putComment = (data, reactionId) => {
    return {
      types: [GET_COMMENTS_REQUESTED, GET_COMMENTS_SUCCESS, GET_COMMENTS_FAILURE],
      promise: (client) => client.post(`putPlaybackComment/${reactionId}`, {
          data
      })
    };
  };

  export const deleteComment = (data, commentId) => {
    return {
      types: [GET_COMMENTS_REQUESTED, GET_COMMENTS_SUCCESS, GET_COMMENTS_FAILURE],
      promise: (client) => client.del(`deletePlaybackComment/${commentId}`, {
          data
      })
    };
  };
  