export const GET_CHAT_DATA_REQUESTED = 'reactions/GET_CHAT_DATA_REQUESTED';
export const GET_CHAT_DATA_SUCCESS = 'reactions/GET_CHAT_DATA_SUCCESS';
export const GET_CHAT_DATA_FAILURE = 'reactions/GET_CHAT_DATA_FAILURE';
export const STORE_CHAT_DATA = 'reactions/STORE_CHAT_DATA';
export const RESET_CHAT_DATA = 'reactions/RESET_CHAT_DATA';
export const RESET_CHAT_LIST = 'reactions/RESET_CHAT_LIST';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  chatLogs: '',
  chatList: []
};

// const checkChatList = (state, data) => {
//   let arr = ''
//     const itemIndex = state.chatList.findIndex(item => item.id === data.id)
//     if (itemIndex === -1) {
//       arr = [...state.chatList.concat(data)]
//     } 
//     else if (itemIndex !== -1) {
//       const filtItem = state.chatList[itemIndex]
//       if (filtItem.status !== data.status) {
//         const updateChatList = [...state.chatList];
//         updateChatList[itemIndex] = data;
//         arr = updateChatList
//       } else {
//         arr = [...state.chatList]
//       }
//     } 
//     else {
//       arr = [...state.chatList]
//     }
//     return arr;
// }

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHAT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_CHAT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        chatLogs: action.result
      };
    }
    case GET_CHAT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
      case STORE_CHAT_DATA: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          //chatList: checkChatList(state, action.payload)
          chatList: state.chatList.indexOf(action.payload) === -1 ? [...state.chatList.concat(action.payload)] : [...state.chatList]
        };
      }
      case RESET_CHAT_DATA: {
        return {
          ...state,
          chatLogs: '',
          chatList: []
        };
      }
      case RESET_CHAT_LIST: {
        return {
          ...state,
          chatList: []
        };
      }
    default:
      return state;
  }
};

export const getChatlogs = (reactionId) => {
  return {
    types: [GET_CHAT_DATA_REQUESTED, GET_CHAT_DATA_SUCCESS, GET_CHAT_DATA_FAILURE],
    promise: (client) => client.get(`getChatLogs/${reactionId}`)
  };
};

export const storeChatlogs = (data) => {
    return {
      type: STORE_CHAT_DATA,
      payload: data
    };
  };

  export const resetChatLogsList = () => {
    return {
      type: RESET_CHAT_DATA
    };
  };

  export const resetChatList = () => {
    return {
      type: RESET_CHAT_LIST
    };
  };

  