exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css"), "");

// Module
exports.push([module.id, ".reactSelect_selectedOptions__23W4P {\n  -webkit-user-select: none;\n          user-select: none;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n  box-sizing: border-box;\n  padding: 8px 12px; }\n\n.reactSelect_focusedOption__3Ic-o {\n  transition: background-color  0.1s ease-in; }\n\n.reactSelect_notFocusedOption__2DUb8 {\n  transition: background-color  0.1s ease-out; }\n", ""]);

// Exports
exports.locals = {
	"selectedOptions": "reactSelect_selectedOptions__23W4P",
	"focusedOption": "reactSelect_focusedOption__3Ic-o " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["bg-orange"] + " " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["clr-white"] + "",
	"notFocusedOption": "reactSelect_notFocusedOption__2DUb8 " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["bg-transparent"] + ""
};