import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import style from './LandingPage.module.css';
import cn from 'classnames';
import Indicator from './Indicators';
import Button from '@components/common/formElements/Button';
import { withRouter } from 'react-router';
import EN from '../../localization/EN';
import { isMobileDevice } from '@helpers/utils';

const SchoolPartyContainer = (props) => {
    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const { el } = props;
    const background = el.background && el.background
    const indicators = [
        {
            id: 1,
            style: style.indicator1,
            text: 'Add friends to watch party'
        },
        {
            id: 2,
            style: style.indicator2,
            text: 'Add videos to watch party from Youtube or Vidberrys'
        },
        {
            id: 3,
            style: style.indicator3,
            text: 'Watch with friends and family on camera'
        },
        {
            id: 4,
            style: style.indicator4,
            text: 'Synchronized streaming of video content'
        },
        {
            id: 5,
            style: style.indicator5,
            text: 'One remote control for all participants'
        }

    ]
    const toggleCollapse = () => {
        setIsCollapseOpen(!isCollapseOpen);
    }

    const redirectTo = () => {
        props.history.push('/');
    }

    return (
        <div className={cn('row m-0 py-5', style.row_height, background)}>
            <div className="custom-col-xlg-24 custom-col-xl-24 container p-0 align-self-center">
                <div className={cn('row m-0')}>
                    <div className="custom-col-36">
                        <div className={cn('row m-0 mb-40 justify-content-center', el.color && el.color, style.heading)}>{el.heading}</div>
                        <div className={cn('row m-0 mb-20 justify-content-center', el.color && el.color, style.description)}>
                            <div className="custom-col-xlg-32">{el.description}</div>
                        </div>
                        {props.el.id === 4 && (
                            <>
                                {isMobileDevice() && (
                                    <div className={cn('row m-0 mb-20 justify-content-center', el.color && el.color, style.description)}>
                                    <div className="custom-col-xlg-32"> {EN.USE_CHROME_IN_MOB_MESSAGE} </div>
                                </div>
                                )}
                                {!isMobileDevice() && (
                                <div className="row m-0 justify-content-center mb-20">
                                    <Button
                                        className={cn(style.getStarted_button)}
                                        onClick={() => redirectTo()}
                                        title="Get started"
                                    />
                                </div>
                                )}
                            </>
                        )}

                        {el.isMore && (
                            <>
                                {!isCollapseOpen && (
                                    <div className={cn('row m-0 mb-20 justify-content-center')}>
                                        <span onClick={() => toggleCollapse()} className={cn(style.moreless, el.background ? el.color : null)}>More ↓</span>
                                    </div>
                                )}
                                <Collapse isOpen={isCollapseOpen}>
                                    <div className={cn('row m-0 mb-20 justify-content-center', el.color && el.color, style.description)}>
                                        {el.more}
                                    </div>
                                </Collapse>
                                {isCollapseOpen && (
                                    <div className={cn('row m-0 mb-20 justify-content-center')}>
                                        <span onClick={() => toggleCollapse()} className={cn(style.moreless, el.background ? el.color : null)}>Less ↑</span>
                                    </div>
                                )}
                            </>
                        )}
                        {el.img && (
                            <div className={cn('row m-0 justify-content-center')}>
                                <div className="custom-col-xlg-28 custom-col-xl-25 positionRelative">
                                    <img className="w-100" src={el.img} alt="" />
                                    {el.id === 1 && indicators.map(el => {
                                        return (
                                            <Indicator key={el.id} el={el} />
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SchoolPartyContainer);
