exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".GlobalSearch_list__1MSpJ {\n  width: 95%;\n  top: 10 !important;\n  text-align: left;\n  position: absolute;\n  z-index: 2;\n  max-height: 280px;\n  border-radius: 0.25rem;\n  border: 0.25px solid black;\n  overflow-y: auto;\n  background-color: white;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }\n\n.GlobalSearch_no-results__3dvp2 {\n  padding: 10px;\n  font-size: 14px;\n  font-weight: 500;\n  color: grey; }\n\n.GlobalSearch_data__ZsmMc {\n  padding: 10px;\n  padding-left: 15px;\n  display: grid;\n  grid-auto-flow: column;\n  justify-content: space-between;\n  grid-column-gap: 5px;\n  column-gap: 5px;\n  font-size: 12px; }\n  .GlobalSearch_data--title__2f3UR {\n    padding: 10px;\n    font-size: 14px; }\n  .GlobalSearch_data--text__36r3j {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis; }\n  .GlobalSearch_data--info__1Y2Xm {\n    font-style: italic;\n    color: grey; }\n\n.GlobalSearch_data__ZsmMc:hover {\n  color: black;\n  background-color: #dbd9d8; }\n\n.GlobalSearch_input__1NSw8 {\n  border-radius: 5px;\n  height: 45px;\n  width: 100%;\n  outline: none;\n  padding: 5px;\n  border-style: groove; }\n\n.GlobalSearch_searchBtn__DMVPi {\n  position: absolute;\n  border-radius: 5px;\n  height: 45px;\n  outline: none;\n  border-style: groove;\n  outline: none; }\n\n.GlobalSearch_searchbarStyle__1hLna {\n  background-color: #f7f8fa;\n  border-radius: 16px;\n  border: 2px solid rgba(203, 77, 79, 0.25); }\n", ""]);

// Exports
exports.locals = {
	"list": "GlobalSearch_list__1MSpJ",
	"no-results": "GlobalSearch_no-results__3dvp2",
	"data": "GlobalSearch_data__ZsmMc",
	"data--title": "GlobalSearch_data--title__2f3UR",
	"data--text": "GlobalSearch_data--text__36r3j",
	"data--info": "GlobalSearch_data--info__1Y2Xm",
	"input": "GlobalSearch_input__1NSw8",
	"searchBtn": "GlobalSearch_searchBtn__DMVPi",
	"searchbarStyle": "GlobalSearch_searchbarStyle__1hLna"
};