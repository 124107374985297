exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".VideoViews_border__1Oadu {\n  background-color: rgba(255, 255, 255, 0.8);\n  border: 1px solid gray;\n  text-align: center;\n  font-size: 18px;\n  padding: 7px; }\n\n.VideoViews_viewBlock__3_95d {\n  background-color: grey;\n  border-radius: 4px;\n  margin-right: 5px; }\n\n.VideoViews_viewState__3E1b- {\n  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;\n  color: white; }\n\n.VideoViews_profile_label__1pkvs {\n  height: 60px;\n  border-radius: 12px;\n  font-size: 20px;\n  display: flex;\n  justify-content: center;\n  font-weight: 600;\n  line-height: 17px;\n  padding: 1.7rem; }\n\n.VideoViews_profile_label_text__3sHQG {\n  color: #908687;\n  font-size: 14px; }\n\n.VideoViews_profile_label__1pkvs p {\n  align-self: center;\n  margin: 0; }\n\n.VideoViews_profile_label__1pkvs.VideoViews_label_green__2hL23 {\n  background: rgba(174, 196, 100, 0.1);\n  color: #AEC464; }\n\n.VideoViews_profile_label__1pkvs.VideoViews_label_pink__3uTP- {\n  background: rgba(238, 144, 149, 0.1);\n  color: #EE9095; }\n\n.VideoViews_profile_label__1pkvs.VideoViews_label_black__xk0TV {\n  background: rgba(234, 236, 238, 0.3);\n  color: #23181A; }\n\n@media screen and (max-width: 1550px) {\n  .VideoViews_profile_label__1pkvs {\n    padding: 0.9rem; } }\n", ""]);

// Exports
exports.locals = {
	"border": "VideoViews_border__1Oadu",
	"viewBlock": "VideoViews_viewBlock__3_95d",
	"viewState": "VideoViews_viewState__3E1b-",
	"profile_label": "VideoViews_profile_label__1pkvs",
	"profile_label_text": "VideoViews_profile_label_text__3sHQG",
	"label_green": "VideoViews_label_green__2hL23",
	"label_pink": "VideoViews_label_pink__3uTP-",
	"label_black": "VideoViews_label_black__xk0TV"
};