import React, { useState } from 'react';
import style from './LandingPage.module.css';
import cn from 'classnames';
import indicator from '@assets/icons/indicator.svg';
import { Tooltip } from 'reactstrap';

const Indicators = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { el } = props;

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <>
        <img id={`tooltip${el.id}`} src={indicator} className={cn(style.indicator_generic, el.style)} alt="" />
        <Tooltip
            placement="right-start"
            hideArrow
            isOpen={tooltipOpen}
            target={`tooltip${el.id}`}
            toggle={toggle}
            innerClassName={cn(style.indicator_tooltip, 'shadow')}
        > 
            {el.text}
        </Tooltip>
        </>
        )
}

export default Indicators