import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import cn from 'classnames';
import FormValidationMessages from '@components/common/FormValidationMessages';
import styles from './RadioCustom.module.css';

const renderField = (props) => {
  const {
    input,
    errorLabel,
    label,
    readOnly,
    meta: {
      touched,
      error,
      warning
    },
    defaultChecked,
    disabled,
    options,
    externalLabel,
    customClass,
    customLabel,
    isDotRadio,
    appendOptonalField,
    errorLableReqired,
    errorWithLable,
    numbering,
    requiredAsterisk,
    customlabelClass,
  } = props;
  const radioContainerClass = cn(`custom-control custom-radio ${customClass} p-0 ${styles.flex_basis100}`, { 'custom-radio-button': isDotRadio });
  let requiredErrorLabel = label;
  if (customLabel) {
    requiredErrorLabel = customLabel;
  }
  return (
    <Fragment>
        { label && errorWithLable
          && (
          <div>
            <label className="text-muted radio-inline fw-600">
              {`${numbering || ''} ${label}`}
              {requiredAsterisk && (
                <span className="error">
                  {' *'}
                  {'  '}
                  {touched
                    && errorLableReqired
                    && errorWithLable
                    && ((error && (
                      <small className="m-0 p-0 error">
                        {errorLabel}
                      </small>)))
                  }
                </span>
              )}
            </label>
          </div>)
        }
        {
          options.map((option) => {
            return (
            <div className={radioContainerClass} key={`${externalLabel}_${input.name}_${option.label}`}>
            <label className={cn('text-muted custom-control-label cursor w-100', styles.vidberry_Radio, customlabelClass)} htmlFor={`${externalLabel}_${input.name}_${option.label}`}>
                <input
                {...input}
                className="custom-control-input"
                type="radio"
                value={option.value}
                readOnly={readOnly}
                id={`${externalLabel}_${input.name}_${option.label}`}
                defaultChecked={defaultChecked === option.value}
                disabled={disabled}
                checked={props.input.checked}
                />
                <span className={styles.checkmark}><span className="align-self-center">{option.label ? option.label.split('_')[0] : ''}</span></span>
            </label>
          </div>
            );
          })
        }
      {
        appendOptonalField
        && appendOptonalField
      }
      {touched
        && !errorLableReqired
        && !errorWithLable
        && ((error && (
          <small className="row m-0 p-0 error">
            {(error.trim() ? `${requiredErrorLabel} ${FormValidationMessages[props.language][error]}` : '') || errorLabel}
          </small>))
          || (warning && (
            <small className="error">
              {warning}
            </small>
          ))
        )
      }
      {touched
        && errorLableReqired
        && !errorWithLable
        && ((error && (
          <small className="row m-0 p-0 error">
            {errorLabel}
          </small>)))
      }
    </Fragment>
  );
};

function mapStateToProps({ localization }) {
  return {
    language: localization.language
  };
}

const ConnectedRenderField = connect(mapStateToProps)(renderField);

export const RadioGroup = (props) => {
  const {
    readOnly,
    validate,
    onChange,
    externalLabel,
    disabled,
    customClass,
    errorLabel,
    label,
    defaultChecked,
    options,
    customLabel,
    isDotRadio,
    numbering,
    requiredAsterisk,
    appendOptonalField,
    errorLableReqired,
    errorWithLable,
    customlabelClass,
    isVidberryRadio
  } = props;

  return (
    <div className="w-100">
      { label && !errorWithLable
        && (
        <label className="text-muted radio-inline fw-600">
          {`${numbering || ''} ${label}`}
          {requiredAsterisk && (
            <span className="error">
              {' *'}
            </span>
          )}
        </label>)}
        <div className="vidberrys-textfield-gray p-0 d-flex">
            <Field
                name={props.name}
                component={ConnectedRenderField}
                readOnly={readOnly}
                validate={validate}
                onChange={onChange}
                disabled={disabled}
                errorLabel={errorLabel}
                defaultChecked={defaultChecked}
                externalLabel={externalLabel}
                customClass={customClass}
                options={options}
                label={label}
                customLabel={customLabel}
                isDotRadio={isDotRadio}
                appendOptonalField={appendOptonalField}
                errorLableReqired={errorLableReqired}
                errorWithLable={errorWithLable}
                numbering={numbering}
                requiredAsterisk={requiredAsterisk}
                customlabelClass={customlabelClass}
                isVidberryRadio={isVidberryRadio}
            />
        </div>
    </div>);
};
export default RadioGroup;