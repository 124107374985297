import React from 'react';
import { ModalFooter } from 'reactstrap';
import * as availableLanguages from '@localization';
import cn from 'classnames';

const FooterComponent = (props) => {
  const {
    onSubmit,
    onCancel,
    submitButtonName,
    title,
    clear
  } = props;

  const CONSTANTS = availableLanguages[props.selectedLanguage];

  const cancel = () => {
    clear();
    onSubmit();
  }
  return (
    <ModalFooter className={cn(title === 'Invite Friends' ? 'ml_140' : 'nml_30', 'd-flex justify-content-center no-border mt-60')}>
      {onCancel && (
        <input
          type="button"
          className="btn btn-link btn-link-small"
          value={CONSTANTS.MODAL_CANCEL}
          onClick={onCancel}
        />
      )}
      {' '}
      <a href="#" onClick={cancel}>
      {submitButtonName}
      </a>
    </ModalFooter>
  );
};
export default FooterComponent;
