exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css"), "");

// Module
exports.push([module.id, ".TextField_inputField__1d5xF {\n  box-shadow: none !important;\n  color: #171B1F !important;\n  font-weight: 600 !important; }\n\n.TextField_customLabel__rb0lj {\n  pointer-events: none;\n  transition: all 0.2s ease;\n  margin-top: 15px; }\n", ""]);

// Exports
exports.locals = {
	"inputField": "TextField_inputField__1d5xF " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["position-relative"] + " " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["fs-15"] + " " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["br-6"] + "",
	"customLabel": "TextField_customLabel__rb0lj " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["font-regular"] + " " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["fs-16"] + " " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["text-capitalize"] + " " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["lh_1"] + " " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["color-label"] + ""
};