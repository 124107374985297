import React from 'react';
import image1 from '@assets/images/MarketingTemplates/schoolparty_b1.png';
import image3 from '@assets/images/landingpage3.svg';
import image2 from '@assets/images/landingpage2.png';

import SchoolPartyContainer from './schoolPartyContainer'

const AboutSchoolParty = () => {
    const items = [
        {
            id: 1,
            heading: 'Have fun watching school videos with your friends and do homeworks faster',
            description: 'Watch school videos together, make notes, make video reactions for later use. Get your homeworks done faster with the help of video markers, FAQs and video responses surfaced at the right places in video.',
            img: image1,
            isMore: true,
            more: 'The social experience of watching the videos with classmates makes it fun, less boring and the repeat watching experience becomes more efficient. Parents can join in and act as moderators for younger students.'
        },
        {
            id: 2,
            heading: 'Enjoy watching great videos while enjoying the company of your friends and family!',
            description: 'Watch videos as a social activity online. Invite friends and family and watch videos with a single remote control (pause, ff, rev, playlist control). Videos are streamed to each user at the highest quality using Vidberrys sync server.',
            isMore: true,
            more: 'Video conference tools (such as Zoom or Google meet) cannot do this since they create a composite video for distribution that significantly deteriorates the video/audio quality. Vidberrys provides a virtual theater experience with a live audience! You can stream videos directly from Youtube or upload your videos to Vidberrys for social viewing.'
        },
        {
            id: 3,
            heading: 'Create high quality, engaging reaction videos with friends directly on Vidberrys',
            description: 'Quickly produce a reaction video for any video in Vidberrys or Youtube and without having to fiddle with complex software like iMove or Adobe After Effects. You can share reaction videos in different layouts on Vidberrys or export to other social media (Youtube, Facebook).',
            img: image2,
            isMore: true,
            more: 'Vidberrys solves the copyright problem by streaming the reaction video directly from the original platform. All your reactions and other value added content is rendered along with the original video in real time. You do not need to download copyrighted material and use multiple complex software to produce a reaction video.'
        },
        {
            id: 4,
            heading: 'Vidberrys, the videos with friends platform',
            description: 'Build your friends and fan network and monetize your video content. Start your own channel, schedule watch parties, create reaction videos or host a school watch party and build your following. In the future, you can build video apps on the platform such as Karaoke, Sports commentary app and others using Vidberrys API.',
            img: image3,
            background: 'bg-maroon',
            color: 'text-white',
        }
    ]
    return (
        <>
            {items.map((el) => {
                return (
                    <SchoolPartyContainer key={el.id} el={el} />
                )
            })}
        </>
    )
}

export default AboutSchoolParty
