import React from 'react';
import cn from 'classnames';
import Template from '@components/common/Template';
import block2 from '@assets/images/MarketingTemplates/reaction_b2.png';
import reaction_record from '@assets/images/MarketingTemplates/reaction_record.png';
import Preview_window from '@assets/images/MarketingTemplates/Preview_Window.PNG';
import Invite_Friends from '@assets/images/MarketingTemplates/Invite_Friends.PNG';
import Like_Dislike_Share from '@assets/images/MarketingTemplates/Like_Dislike_Share.png';
import Share_Social from '@assets/images/MarketingTemplates/Share_Social.PNG';
import reaction_record_progress from '@assets/images/MarketingTemplates/reaction_record_progress.png';
import style from './Templates.module.css';

const Competition = (props) => {
    const topSecondContent = () => {
        return (
            <video className={cn('outline float-right', style.video_Tag)} controls>
                <source src="https://storage.googleapis.com/vidberrys-staging/upload-videos/1610111210132/testing_VLJGY1612258475049.mp4" type="video/mp4" />
            </video>
        )
    }
    const items = {
        contents: [
            {
                heading: 'Join Summer 2021 reaction video contest: Create a reaction video, show off your creative genius and win exciting prizes!!',
                custom: true,
                description: [
                    {
                        secondaryText: '1. Select a video, create a reaction watch party.'
                    },
                    {
                        secondaryText: '2. Create a reaction video in watch party and make it public.'
                    },
                    {
                        secondaryText: '3. Add friends and fans and ask them to like your reaction video. '
                    },
                    {
                        secondaryText: '4. Submit your reaction video to FB fan page.'
                    },
                    {
                        secondaryText: '5. Win awesome prizes and bragging rights with your friends!!'
                    },
                    {
                        secondaryText: '6. You can submit multiple reaction videos for your competition.'
                    }
                ],
                hasExploreMore: true,
                doubleContent: true,
                secondContent: topSecondContent()
            },
            {
                heading: 'Things that will help you win the game are',
                custom: true,
                description: [
                    {
                        secondaryText: '1. Number of likes and comments for your video.'
                    },
                    {
                        secondaryText: '2. Number of views for your video.'
                    },
                    {
                        secondaryText: '3. Quality of the reaction - is it engaging, interesting and entertaining.'
                    },
                    {
                        secondaryText: '4. Friends and fan following in your channel.'
                    }
                ],
                hasExploreMore: true,
                doubleContent: false
            },
            {
                step: '01 Step',
                heading: 'Select a video and create a watch party',
                description: [
                    'You can select the video to react in 2 ways ',
                    '1. If the video is in Vidberrys, hover over the video and create a new watch party.',
                    '2. If the video is not in Vidberrys but available in Youtube - Click on New Video button and copy and paste the Youtube URL you like and ‘upload video’ to Vidberrys.'
                ],
                link: '/howto/competition',
                img: block2,
                order: 1,
                custom: false
            },
            {
                step: '02 Step',
                heading: 'Create a reaction video in watch party and make it public',
                description: [
                    'Click on Record/React button, select “Record me” OR “Record group” and follow the instructions.',
                    'Each reaction video has 3 parts - Intro, Main reaction and post reaction video. Record the 3 sections in that order. You have the option to skip the intro or post reaction video.',
                    'You can pause or fast forward your video and continue recording your reaction. Video playback will record the video events (pause, ff, rev) along with the recording.'
                ],
                img: reaction_record,
                img_second: reaction_record_progress,
                order: 2,
                groupedImage: true,
                custom: false,
                className: 'mb-40'
            },
            {
                step: '03 Step',
                heading: 'Publish Reaction Video',
                description: [
                    'After recording the reaction you can submit the recording and make it public or private. You have the option of changing the settings later in “My channel”.',
                    `You can playback the reaction video from the home page and it's also available under the “My channel” page.`
                ],
                img: Preview_window,
                order: 1,
                custom: false
            },
            {
                step: '04 Step',
                heading: 'Invite friends and fans to like your video',
                description: [
                    'You have the option to invite friends under the “My channel” page.  Invite can be sent in two ways',
                    '1. Enter the email address of the friend and send an invite.',
                    '2. If the friend is in Vidberrys, use “Search By Name” option to add them to invite list.'
                ],
                img: Invite_Friends,
                order: 2,
                custom: false
            },
            {
                step: '05 Step',
                heading: 'Submit reaction video to FB page',
                description: [
                    'Like, Dislike and Share options are available for the reaction video in the playback page',
                    'Share the reaction video to FB and ask your friends to onboard onto Vidberrys and help you to win the competition.'
                ],
                img: Like_Dislike_Share,
                img_second: Share_Social,
                groupedImage: true,
                order: 1,
                custom: false,
                className: 'w-50 mb-2 ml-4'
            }
        ]
    }

    return (
        <>
            <Template
                items={items}
                block1Background={style.reaction_block1Background}
                type="watchparty"
            />
        </>
    )
}

export default Competition
