import React, { Component } from 'react';
import Modal from '@components/common/Modal';
import { reduxForm, change } from 'redux-form';
import 'react-multi-email/style.css';
import cn from 'classnames';
import {
    required,
    noSpecialCharacter
  } from '@components/common/FormValidation';
  import Radio from '@formElements/RadioCustom';
import Textfield from '@formElements/TextField';
import { ERROR_LABEL } from '@constants';
import { EN } from '@localization';
import DateTimepicker from '@formElements/ReactWidgets/DateTimepicker';
import TimePicker from '@formElements/ReactWidgets/TimePicker';
import Button from '@components/common/formElements/Button';
import WatchPartySearchContainer from '@containers/WatchPartySearchContainer';
import style from './watchPartyModel.module.css';

interface IProps {}
interface IState {
  emails: string[];
}
const ScheduleOptions = [{value:1, label:"Study Now"}, {value:2, label:"Schedule Later"}];
const TypeOptions = [{value:0, label:"Public"}, {value:1, label:"Private"}];
const CategoryOptions = [{value: 0, label: 'Watch Party'}, {value: 1, label: 'School Watch Party'}]

class WatchPartyModel extends Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            emails: [],
            time: '',
            selectedOption: this.props.isDisabled ? CategoryOptions[1] : CategoryOptions[0],
            minTime: '',
            wpDate: '',
            customValidationMsg: false,
        };
    }

    cancel = () => {
      this.props.resetWatchPartyField();
    }

    change = (e) => {
      this.setState({selectedOption : e})
    }

    onDateChange = (e) => {
      this.setState({
        wpDate: e
      });
      this.getMinTime(e);
      this.props.dateChange(e);
    }

    getMinTime = (e) => {
      let selectedDate = new Date(e);
      selectedDate.setHours(0);
      selectedDate.setMinutes(0);
      selectedDate.setSeconds(0);
      let currentDate = new Date();
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);

      if(selectedDate > currentDate) {
        this.setState({
          minTime: ''
        });
      } else {
        this.setState({
          minTime: e
        });
      }
    }

    onVideoUrlChange = (e) => {
      const youtubeLink = e.target.value;
        if (youtubeLink === '') {
          this.setState({ customValidationMsg: false })
        } else if(youtubeLink) {
          var regularExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
          if(youtubeLink.match(regularExp)){
            this.setState({ customValidationMsg: false })
          } else if(youtubeLink.startsWith("https://storage.googleapis.com/")) {
            this.setState({ customValidationMsg: false })
          } else{
            this.setState({ customValidationMsg: true })
          }
        }
    }

    componentDidUpdate() {
      if (!this.props.isSourceUrlValid) {
        this.props.dispatch(change('WatchPartyModel','source','' ));
        this.props.handleSourceField(true);
      }
      if (this.props.videoURL) {
        this.props.dispatch(change('WatchPartyModel','source',this.props.videoURL ));
        this.props.handleSourceField(true);
      }
    }

    render() {
      const isDisabled = this.props.pristine || this.props.invalid || (this.props.isSchedule && (!this.props.date || !this.props.time)) || this.props.isSubmitted || this.props.watchPartyName === '';
        return (
               <>
                  <Modal
                    type="popup"
                    title={this.props.title}
                    dialogClassName="align-items-center"
                    toggle={this.props.toggle}
                    isOpen={this.props.isOpen}
                    size="lg"
                    customHeaderClass="fs-24 clr-black font-bold"
                    modalCloseClass="fs-20"
                    modalTitleClass={this.props.modalTitleClass}
                    contentClassName=""
                    cancel={this.cancel}
                  >
              <div className="">
                <form onSubmit={this.props.handleSubmit}>
                <div className="row m-0">
                <Textfield
                    name="watchpartyname"
                    type="text"
                    maxLength="45"
                    label={EN.WATCHPARTYNAME + EN.REQUIRED}
                    placeholder={EN.NAME}
                    errorLabel={ERROR_LABEL.WATCHPARTYNAME}
                    validate={[required, noSpecialCharacter]}
                    customClass="vidberrys-textfield-gray"
                    onChange={this.props.onNameChange}
                    autocomplete={false}
                  />
                </div>
                    <div className="row m-0 mb-3 mt-4">
                      <div className="custom-col-27 p-0">
                      {/* {(this.props.videoURL) ? (
                        <>
                        <div className={cn(styles.srcUrl,'row m-0')}>
                          <span id="srcUrlId">{this.props.videoURL}</span>
                        </div>
                        </>
                      ): ( */}
                      <Textfield
                      name="source"
                      type="text"
                      label={EN.SOURCE + EN.REQUIRED}
                      validate={[required]}
                      placeholder={EN.WP_MODAL_LINK}
                      customClass="vidberrys-textfield-gray"
                      onChange={this.onVideoUrlChange}
                      />
                      {/* )} */}
                      </div>
                      <div className="custom-col-9 pr-0">
                        <Button type="button" onClick={() => this.props.handleSearchContainer('true')} className="fs-14 btn-vidberrys btn-black btn-block" title="Search Video" />
                      </div>
                      {this.state.customValidationMsg && (
                            <div className={cn('row', style.customError)}>
                              {EN.VALID_URL_MSG}
                            </div>
                      )}
                    </div>
                    {this.props.showSearchContainer && (
                      <div className="mb-3 col-10 container">
                          <WatchPartySearchContainer
                            handleSearchContainer={this.props.handleSearchContainer}
                            addNextVideo={this.props.addNextVideo}
                            customSearchContainerClass={this.props.customSearchContainerClass}
                            customScrollContainerClass={this.props.customScrollContainerClass}
                          />
                      </div>
                    )}
                    <div className="">
                      {/* <h5 className="mt-2 font">{EN.RECIPIENTS}</h5>
                        <ReactMultiEmail
                          placeholder="Enter emails..."
                          emails={emails}
                          required
                          onChange={(_emails: string[]) => {
                            this.setState({ emails: _emails });
                            this.props.watchPartyEmailsList(_emails)
                          }}
                          validateEmail={(email) => {
                            return isEmail(email); // return boolean
                          }}
                          getLabel={(
                            email: string,
                            index: number,
                            removeEmail: (index: number) => void,
                          ) => {
                            return (
                              <div data-tag key={index}>
                                {email}
                                <span data-tag-handle onClick={() => removeEmail(index)} role="button" tabIndex={0} onKeyDown={this.keyDown}>
                                  ×
                                </span>
                              </div>
                            );
                          }}
                        /> */}
                        {/* <div>
                          <ReactSelect
                            name="type"
                            options={CategoryOptions}
                            id="category"
                            className="react-select-container mb-4"
                            placeholder="Type"
                            displayLabel={false}
                            value={this.state.selectedOption}
                            isDisabled={this.props.isDisabled}
                            onChange={this.change}
                           />
                        </div> */}
                        <div className="mt-4">
                          <Radio options={TypeOptions} defaultChecked={0} name="watchPartyMode" />
                        </div>
                        <div className="mt-4">
                          <Radio options={ScheduleOptions} defaultChecked={1} name="scheduleParty" onChange={e => this.props.showSchedule(e)} />
                        </div>
                        {this.props.isSchedule && (
                          <>
                            <div className="row ml-1">
                              <h5 className="mt-4 font">Scheduled Date and Time</h5>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <DateTimepicker
                                  dropUp
                                  name="date"
                                  dateLabel="Date"
                                  showTime={false}
                                  minDate={new Date()}
                                  validate={required}
                                  className="mt-3"
                                  placeholder="DD/MM/YYYY"
                                  onChange={(e) => this.onDateChange(e)}
                                />
                              </div>
                              <div className="col-6">
                                <TimePicker
                                dropUp
                                name="time"
                                dateLabel="time"
                                showTime={true}
                                placeholder="HH:MM"
                                minDate={this.state.minTime !== '' ? new Date(this.state.minTime) : undefined}
                                onChange={(e) => this.props.timeChange(e)}
                                disabled={this.state.wpDate === '' ? true : false}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div>
                          <Button
                            type="submit"
                            className="btn-vidberrys btn-maroon btn-block mt-4"
                            title={this.props.isSchedule ? 'Schedule Study Party' : 'Create Study Party'}
                            disabled={isDisabled || this.state.customValidationMsg}
                          />
                        </div>
                    </div>
                    </form>
              </div>
        </Modal>
               </>
        );
    }
}

export default reduxForm({
    form: 'WatchPartyModel',
  })(WatchPartyModel);
