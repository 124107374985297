export default {
  // The URL we're connecting to
  // hostname: 'http://localhost:',
  hostname: 'https://vidberrys.com:',
  // hostname: 'https://staging.vidberrys.com:',
  ports: {
    profile: 9091,
    watchparty: 9090,
    logger: 9092,
    oauth: 9093,
    ffmpeg: 5200,
  },
  // Map shortnames to the actual endpoints, so that we can use them in the middleware
  endpoints: {
    //before login apis
    sendotp: 'before/profile/user/generate/otp',
    saveFbGoogleUsers: 'before/profile/user/social',
    login: 'before/profile/user/login',
    getWatchPartyList: 'before/watchparty/get/list',
    getCuratedVideosWithCategory:
      'before/watchparty/curatedvideos/get/with/categories',
    getCuratedVideos: 'before/watchparty/curatedvideos/get/list',
    getReactionData: 'before/watchparty/curatedvideos/reactions',
    getCategoryData: 'before/watchparty/curatedvideos/get/categories',
    getTextbooks: 'before/watchparty/textbooks',
    getChapters: 'before/watchparty/chapters',
    getSubjects: 'before/watchparty/subjects',
    searchVideos: 'before/watchparty/curatedvideos/search',
    getPlayBackReactions: 'before/watchparty/reactions/get',
    updateViewCountOfReaction: 'before/watchparty/reactions/views',
    getLogsForReaction: 'before/logger/get',
    getChatLogs: 'before/watchparty/chat/reaction/get/list',
    getVideoDetails: 'before/watchparty/curatedvideos/get/info',
    getSessions: 'before/watchparty/sessions',
    searchAutocomplete: 'before/watchparty/chapters/autocomplete',

    // profile end points
    verifyotp: 'profile/user/verify/otp',
    getUserDetails: 'profile/user/get',
    setPassword: 'profile/user/set/password',
    saveFeedback: 'profile/user/feedback',

    // watch party end points
    scheduleWatchParty: 'watchparty/schedule',
    getWatchPartyDetails: 'watchparty/get/details',
    addNextVideoUrlToWatchParty: 'watchparty/add/nextVideo',
    inviteFrnds: 'watchparty/invite/friends',
    updateUserWatchPartyStatus: 'watchparty/update/user/status',
    uploadFile: 'watchparty/curatedvideos/upload',
    uploadFileFromMyComputer: 'watchparty/curatedvideos/upload/video',
    deleteVideoFromWatchParty: 'watchparty/delete/nextVideo',
    getUploadedVideos: 'watchparty/curatedvideos/get/uploaded/list',
    changeUploadedVideoType: 'watchparty/curatedvideos',
    deleteUploadedVideo: 'watchparty/curatedvideos',

    //Log end points
    logEvent: 'logger/event',

    //reactions end points
    uploadReaction: 'watchparty/reactions/upload',
    getReactionUserDetails: 'watchparty/reactions/all',
    deleteReactionDetail: 'watchparty/reactions',
    updateReactionDetail: 'watchparty/reactions',
    getMoreReactionVideos: 'watchparty/reactions/group',
    publishReaction: 'watchparty/reactions/publish',
    getPromotion: 'watchparty/promote',
    deleteWatchParty: 'watchparty',
    publishVideos: 'watchparty/editvideo/publish',
    getEditedVideosList: 'watchparty/editvideo/get/list',
    deleteEditedVideo: 'watchparty/editvideo/delete',
    updateEditedVideo: 'watchparty/editvideo/update',
    downloadEditedVideo: 'watchparty/editvideo/download',
    likeDislikeAction: 'watchparty/reactions/like',
    getLikeDislikeStatus: 'watchparty/reactions/get/useraction',

    // video views end points
    addUserStats: 'watchparty/curatedvideos/stats',
    getVideoViewsCount: 'watchparty/userstats/get',
    addCreditStats: 'watchparty/userstats',
    checkUserAvialability: 'profile/user/verify/username',
    watchpartyLive: 'watchparty',
    updateUsernameInCouchbase: 'watchparty/username',

    //chats / markers
    getChatsMarkers: 'watchparty/chat/get/list',

    //stream overlay
    streamOverlay: 'streamOverlay',
    saveProfilePhoto: 'profile/user/image',

    //friends / fans
    sendInviteRequest: 'profile/friends/send/request',
    searchFriends: 'profile/friends/search',
    getFriendsRequest: 'profile/friends/get',
    actionOnPendingRequest: 'profile/friends/action',
    becomeAfan: 'profile/fans/send/request',
    getFansList: 'profile/fans/get',
    checkFanStatus: 'profile/fans/check',
    getFollowingsList: 'profile/fans/following/get',
    getPlaybackComments: 'watchparty/reactions/get/comment',
    putPlaybackComment: 'watchparty/reactions/comment',
    deletePlaybackComment: 'watchparty/reactions/comment',
    getUserDetailsForChannel: 'profile/user/get/detailsByUsername',
  },
};
