exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css"), "");

// Module
exports.push([module.id, ".RadioCustom_vidberry_Radio__i9L6I::after, .RadioCustom_vidberry_Radio__i9L6I::before {\n  position: absolute;\n  opacity: 0;\n  cursor: pointer; }\n\n.RadioCustom_checkmark__39E0R {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 48px;\n  width: 100%;\n  background: transparent;\n  border-radius: 16px;\n  font-weight: 500;\n  font-size: 15px;\n  margin-top: -2px;\n  display: flex;\n  justify-content: center; }\n\n.RadioCustom_vidberry_Radio__i9L6I input:checked ~ .RadioCustom_checkmark__39E0R {\n  border: 2px solid #E3E8F1;\n  background-color: #ffffff;\n  color: #171B1F; }\n\n.RadioCustom_flex_basis100__3GcJC {\n  flex-basis: 100%;\n  text-align: center; }\n", ""]);

// Exports
exports.locals = {
	"vidberry_Radio": "RadioCustom_vidberry_Radio__i9L6I",
	"checkmark": "RadioCustom_checkmark__39E0R " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["color-vidberry-placeholder"] + "",
	"flex_basis100": "RadioCustom_flex_basis100__3GcJC"
};