/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import cn from 'classnames';
import FormValidationMessages from '@components/common/FormValidationMessages';
import styles from './TextField.module.css';

class renderField extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {
      input,
      errorLabel,
      label,
      type,
      readOnly,
      maxLength,
      meta: { touched, error, warning },
      customClass,
      autocomplete,
      placeholder,
      labelIconClass,
      normalize,
      customLabelCss,
      hasNoStartingSpace,
      errorLabelCustomClass,
      initialValue,
      customErrorLabel,
      onKeyPress,
      adonLeft,
      adonRight,
      adonLeftClass,
      adonRightClass,
      adonLeftContent,
      adonRightContent,
      onBlur,
      onKeyDown,
      onKeyUp,
      min,
      max,
      required,
      requiredText,
      disabled,
      onPaste
    } = this.props;

    let fieldClass = `form-control form-control-danger h-48 ${styles.inputField} ${adonLeft || adonRight ? null : 'br-16'}`;
    if (customClass) {
      fieldClass = `${fieldClass} ${customClass}`;
    }
    let requiredErrorLabel = label;
    if (label) {
      requiredErrorLabel = requiredErrorLabel.replace(/\*/gi, '');
    } else {
      requiredErrorLabel = customErrorLabel;
    }
    let inputInitialValue = initialValue ? initialValue : '';
    let inputValue = hasNoStartingSpace ? input.value.trimStart() : input.value ;
    if(!inputValue) {
      inputValue = inputInitialValue;
    }
    return (
      <Fragment>
        {label && this.props.showLabel && (
          <label className={cn(styles.customLabel, customLabelCss)}>
            {labelIconClass && <i className={cn('mr-10', labelIconClass)} />}
            {label}
            {required && (
              <span className="text-danger"> *</span>
            )}
          </label>
        )}
        <small className="text-muted" htmlFor={input.name} />
        <div className="input-group focus">
          {adonLeft && (
            <div className="input-group-append">
              <span className={cn('input-group-text', adonLeftClass)}>{adonLeftContent}</span>
            </div>
          )}
        <input
          className={fieldClass}
          autoComplete={autocomplete ? autocomplete : 'off'}
          {...input}
          maxLength={maxLength}
          type={type}
          value={inputValue}
          placeholder={placeholder}
          readOnly={readOnly}
          onFocus={() => input.onFocus()}
          onBlur={() => { input.onBlur(); onBlur}}
          normalize={normalize}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          min={min}
          max={max}
          required={requiredText}
          disabled={disabled}
          onPaste={onPaste}
        />
          {adonRight && (
            <div className="input-group-append">
              <span className={cn('input-group-text', adonRightClass)}>{adonRightContent}</span>
            </div>
          )}
        </div>
        {touched
          && ((error && (
            <small className={cn('error ml-10', errorLabelCustomClass)}>
              {(error.trim() ? `${requiredErrorLabel} ${FormValidationMessages[this.props.language][error]}` : '') || errorLabel}
            </small>))
            || (warning && (
            <small className="error ml-10">
              {warning}
            </small>
            )))}
      </Fragment>
    );
  }
}

function mapStateToProps({ localization }) {
  return {
    language: localization.language
  };
}

const ConnectedRenderField = connect(mapStateToProps)(renderField);

const Textfield = (props) => {
  const {
    name,
    type,
    errorLabel,
    label,
    readOnly,
    maxLength,
    validate,
    customClass,
    autocomplete,
    onChange,
    labelIconClass,
    placeholder,
    normalize,
    customLabelCss,
    onBlur,
    hasNoStartingSpace,
    errorLabelCustomClass,
    initialValue,
    customErrorLabel,
    showLabel,
    onKeyPress,
    adonLeft,
    adonRight,
    adonLeftClass,
    adonRightClass,
    adonLeftContent,
    adonRightContent,
    onKeyDown,
    onKeyUp,
    min,
    max,
    required,
    requiredText,
    disabled,
    onPaste
  } = props;
  return (
    <Field
      name={name}
      type={type}
      readOnly={readOnly}
      maxLength={maxLength}
      component={ConnectedRenderField}
      label={label}
      errorLabel={errorLabel}
      validate={validate}
      customClass={customClass}
      autocomplete={autocomplete}
      onChange={onChange}
      placeholder={placeholder}
      labelIconClass={labelIconClass}
      normalize={normalize}
      customLabelCss={customLabelCss}
      onBlur={onBlur}
      hasNoStartingSpace={hasNoStartingSpace}
      errorLabelCustomClass={errorLabelCustomClass}
      initialValue={initialValue}
      customErrorLabel={customErrorLabel}
      showLabel={showLabel}
      onKeyPress={onKeyPress}
      adonLeft={adonLeft}
      adonRight={adonRight}
      adonLeftClass={adonLeftClass}
      adonRightClass={adonRightClass}
      adonLeftContent={adonLeftContent}
      adonRightContent={adonRightContent}
      onKeyDown={onKeyDown}
      required={required}
      onKeyUp={onKeyUp}
      min={min}
      max={max}
      requiredText={requiredText}
      disabled={disabled}
      onPaste={onPaste}
    />
  );
};

export default Textfield;


/* eslint-disable */
// import React, { Component, Fragment } from 'react';
// import { connect } from 'react-redux';
// import { Field } from 'redux-form';
// import cn from 'classnames';
// import FormValidationMessages from '@components/common/FormValidationMessages';
// import styles from './TextField.module.css';

// class renderField extends Component {
//   constructor(props) {
//     super(props);
//     this.props = props;
//   }

//   render() {
//     const {
//       input,
//       errorLabel,
//       label,
//       type,
//       readOnly,
//       maxLength,
//       meta: { touched, error, warning },
//       customClass,
//       autocomplete,
//       placeholder,
//       labelIconClass,
//       normalize,
//       customLabelCss,
//       hasNoStartingSpace,
//       errorLabelCustomClass,
//       initialValue,
//       customErrorLabel,
//       onKeyPress
//     } = this.props;

//     let fieldClass = `form-control form-control-danger br-16 h-48 ${styles.inputField}`;
//     if (customClass) {
//       fieldClass = `${fieldClass} ${customClass}`;
//     }
//     let requiredErrorLabel = label;
//     if (label) {
//       requiredErrorLabel = requiredErrorLabel.replace(/\*/gi, '');
//     } else {
//       requiredErrorLabel = customErrorLabel;
//     }
//     let inputInitialValue = initialValue ? initialValue : '';
//     let inputValue = hasNoStartingSpace ? input.value.trimStart() : input.value ;
//     if(!inputValue) {
//       inputValue = inputInitialValue;
//     }
//     return (
//       <Fragment>
//         {label && this.props.showLabel && (
//           <label className={cn(styles.customLabel, customLabelCss)}>
//             {labelIconClass && <i className={cn('mr-10', labelIconClass)} />}
//             {label}
//           </label>
//         )}
//         <small className="text-muted" htmlFor={input.name} />
//         <input
//           className={fieldClass}
//           autoComplete={autocomplete}
//           {...input}
//           maxLength={maxLength}
//           type={type}
//           value={inputValue}
//           placeholder={placeholder}
//           readOnly={readOnly}
//           onFocus={() => input.onFocus()}
//           onBlur={() => input.onBlur()}
//           normalize={normalize}
//           onKeyPress={onKeyPress}
//         />
//         {touched
//           && ((error && (
//             <small className={cn('error ml-10', errorLabelCustomClass)}>
//               {(error.trim() ? `${requiredErrorLabel} ${FormValidationMessages[this.props.language][error]}` : '') || errorLabel}
//             </small>))
//             || (warning && (
//             <small className="error ml-10">
//               {warning}
//             </small>
//             )))}
//       </Fragment>
//     );
//   }
// }

// function mapStateToProps({ localization }) {
//   return {
//     language: localization.language
//   };
// }

// const ConnectedRenderField = connect(mapStateToProps)(renderField);

// const Textfield = (props) => {
//   const {
//     name,
//     type,
//     errorLabel,
//     label,
//     readOnly,
//     maxLength,
//     validate,
//     customClass,
//     autocomplete,
//     onChange,
//     labelIconClass,
//     placeholder,
//     normalize,
//     customLabelCss,
//     onBlur,
//     hasNoStartingSpace,
//     errorLabelCustomClass,
//     initialValue,
//     customErrorLabel,
//     showLabel,
//     onKeyPress
//   } = props;
//   return (
//     <Field
//       name={name}
//       type={type}
//       readOnly={readOnly}
//       maxLength={maxLength}
//       component={ConnectedRenderField}
//       label={label}
//       errorLabel={errorLabel}
//       validate={validate}
//       customClass={customClass}
//       autocomplete={autocomplete}
//       onChange={onChange}
//       placeholder={placeholder}
//       labelIconClass={labelIconClass}
//       normalize={normalize}
//       customLabelCss={customLabelCss}
//       onBlur={onBlur}
//       hasNoStartingSpace={hasNoStartingSpace}
//       errorLabelCustomClass={errorLabelCustomClass}
//       initialValue={initialValue}
//       customErrorLabel={customErrorLabel}
//       showLabel={showLabel}
//       onKeyPress={onKeyPress}
//     />
//   );
// };

// export default Textfield;
