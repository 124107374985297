import React, { Component } from 'react';
import cn from 'classnames';
import styles from './GlobalSearch.module.css';
import search from '../../assets/icons/search.svg';
import Button from '@components/common/formElements/Button';
import { debounce } from 'lodash';
class GlobalSearch extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      searchKey: '',
      searchResults: {},
      isInputInFocus: false,
      autocompleteLoading: false,
    };
    this.handleSearch = debounce(this.handleSearch.bind(this), 250);
  }

  componentDidMount() {
    this.setSearchResults();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location !== this.props.location &&
      this.props.location !== `/contentLibrary` &&
      this.props.location !== `/contentLibrary/${this.props.categoryName}`
    ) {
      this.refs.searchValue.value = '';
      window.localStorage.removeItem('categoryName');
      window.localStorage.removeItem('searchKey');
    }
    if (
      prevProps.location !== this.props.location &&
      (this.props.location === `/contentLibrary/subjects` ||
        this.props.location === `/contentLibrary/completed-study-sessions`)
    ) {
      this.refs.searchValue.value = '';
      window.localStorage.removeItem('categoryName');
    }
    if (prevProps.searchResults !== this.props.searchResults) {
      this.setSearchResults();
    }
  }

  componentWillUnmount() {
    this.props.searchKey({ target: { value: '' } });
  }

  handleSearch(searchTerm) {
    if (searchTerm.length >= 3) {
      this.setState({ searchKey: searchTerm });
      this.props.searchKey(searchTerm);
      this.props.openDropdown();
      return;
    }
    this.setState({ searchKey: '' });
    this.props.searchKey('');
    this.props.closeDropdown();
  }

  setSearchResults() {
    if (!this.props.searchResults) {
      return;
    }
    const chapters = this.props.searchResults['chapters'];
    const sections = this.props.searchResults['sections'];
    const content = this.props.searchResults['content'];
    const searchResults = {};
    const initSubject = (subject, key) => {
      if (!searchResults[subject]) {
        searchResults[subject] = [];
      }
    };
    if (chapters?.length) {
      chapters.forEach((ch) => {
        initSubject(ch.subject);
        ch['type'] = 'chapters';
        searchResults[ch.subject].push(ch);
      });
    }
    if (sections?.length) {
      sections.forEach((sec) => {
        initSubject(sec.subject);
        sec['type'] = 'sections';
        searchResults[sec.subject].push(sec);
      });
    }
    if (content?.length) {
      content.forEach((co) => {
        initSubject(co.subject);
        co['type'] = 'content';
        searchResults[co.subject].push(co);
      });
    }
    this.setState({ searchResults: searchResults });
  }

  navToSessionRoom(
    chapterId,
    selectedSectionId,
    selectedTeachingUnitId = null
  ) {
    let url = `/sessionroom?chapterId=${chapterId}&sectionId=${selectedSectionId}`;
    if (selectedTeachingUnitId) {
      url += `&teachingUnitId=${selectedTeachingUnitId}`;
    }
    this.props.history.push(url);
  }

  render() {
    return (
      <div>
        <div className={cn('InputAddOn', styles.searchbarStyle)}>
          <Button
            className={cn('InputAddOn-item h-44')}
            style={{ cursor: 'default' }}
            icon={search}
          />
          <input
            ref='searchValue'
            className='InputAddOn-field pl-0 align-self-center'
            type='search'
            placeholder='Search'
            name='search'
            onKeyUp={(e) => {
              const searchTerm = e.target.value;
              this.handleSearch(searchTerm);
            }}
            onFocus={() => this.setState({ isInputInFocus: true })}
            onBlur={() =>
              setTimeout(() => this.setState({ isInputInFocus: false }), 100)
            }
            autoComplete='off'
          />
        </div>
        {this.state.isInputInFocus ? (
          Object.keys(this.state.searchResults).length > 0 &&
          this.state.searchKey ? (
            <div className={cn(styles.list)} ref={this.myRef}>
              {Object.keys(this.state.searchResults).map((subject) => {
                const searchResults = this.state.searchResults[subject];
                return (
                  <div key={subject}>
                    <div className={cn(styles['data--title'], 'disabled')}>
                      {subject}
                    </div>

                    {searchResults.map((item, index) => {
                      return (
                        <div
                          className={cn(styles.data, 'cursor')}
                          onMouseDown={() => {
                            this.navToSessionRoom(
                              item.chapterId,
                              item.sectionId,
                              item.teachingUnitId
                            );
                          }}
                          key={index}
                          title={item.title}
                        >
                          <div className={styles['data--text']}>
                            {item.title}
                          </div>
                          <div className={styles['data--info']}>
                            in {item.type}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={cn(styles.list)} ref={this.myRef}>
              <div className={styles['no-results']}>
                {this.props.searchAutocompleteLoading
                  ? 'Fetching...'
                  : this.state.searchKey?.length >= 3
                  ? 'No Results'
                  : 'Enter at least 3 characters'}
              </div>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default GlobalSearch;
