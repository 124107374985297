import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import HeaderContainer from '@containers/HeaderContainer';
import PageLoader from '@components/common/PageLoader';
import Notification from '@containers/common/Notification';
import OnRouteChange from '@containers/common/OnRouteChange';
import { APP_CONFIG } from '@constants';
import SidebarMenu from '@components/SidebarMenu';
import Feedback from '@containers/Feedback';
import WatchPartyTemplate from '@components/Templates/watchparty';
import ReactionTemplate from '@components/Templates/reaction';
import SchoolPartyTemplate from '@components/Templates/schoolParty';
import CompetitionTemplate from '@components/Templates/competition';
import about from '@components/LandingPage';
import aboutSchoolParty from '@components/LandingPage/schoolParty';
import cn from 'classnames';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
const trackingId = 'G-929FJBMJQ7';
const history = createBrowserHistory();
ReactGA.initialize(trackingId);

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});
// const PrivateRoute = ({ component: Component, authed, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={props => (typeof window.localStorage.getItem('email') !== 'undefined'
//         && window.localStorage.getItem('email') !== null
//         && window.localStorage.getItem('email') !== '' ? (
//           <Component {...props} />
//         ) : (
//           <Redirect to={{ pathname: '/', state: { from: props.location } }} />
//         ))
//       }
//     />
//   );
// };

const LoadingComponent = ({ isLoading, isError }) => {
  if (isLoading) {
    return <PageLoader />;
  }
  if (isError) {
    return <div>Sorry, unable to load the page</div>;
  }
  return null;
};

const notFound = Loadable({
  loader: () => import('@components/404NotFound'),
  loading: LoadingComponent,
});

const Login = Loadable({
  loader: () => import('@components/Models/LoginModel'),
  loading: LoadingComponent,
});

const dashboard = Loadable({
  loader: () => import('@components/Dashboard'),
  loading: LoadingComponent,
});

const ConferenceRoom = Loadable({
  loader: () => import('@containers/ConferenceRoom'),
  loading: LoadingComponent,
});

const SessionRoom = Loadable({
  loader: () => import('@containers/SessionRoom'),
  loading: LoadingComponent,
});

const Notes = Loadable({
  loader: () => import('@containers/Notes'),
  loading: LoadingComponent,
});

const WatchPartyList = Loadable({
  loader: () => import('@components/WatchPartyList'),
  loading: LoadingComponent,
});

const ContentLibrary = Loadable({
  loader: () => import('@components/ContentLibrary'),
  loading: LoadingComponent,
});

const videoDetails = Loadable({
  loader: () => import('@containers/VideoDetailsContainer'),
  loading: LoadingComponent,
});

const fileUpload = Loadable({
  loader: () => import('@containers/FileUpload'),
  loading: LoadingComponent,
});

const ReactionsVideo = Loadable({
  loader: () => import('@containers/ReactionsVideoContainer'),
  loading: LoadingComponent,
});

const MyReactionsTab = Loadable({
  loader: () => import('@containers/MyChannelContainer'),
  loading: LoadingComponent,
});

const ReactionsPlayback = Loadable({
  loader: () => import('@containers/reactions/ReactionSourceMediaContainer'),
  loading: LoadingComponent,
});

const SetPassword = Loadable({
  loader: () => import('@containers/SetPassword'),
  loading: LoadingComponent,
});

const Channel = Loadable({
  loader: () => import('@containers/Channel'),
  loading: LoadingComponent,
});

const Mediapipe = Loadable({
  loader: () => import('@containers/MediaPipe/bkup'),
  loading: LoadingComponent,
});

const Routes = (props) => {
  const [url, setCurrentUrl] = useState();
  const [windowWidth, setWindowWidth] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const isTemplate =
    window.location.pathname.split('/')[1] === 'privacy-policy' ||
    window.location.pathname.split('/')[1] === 'community-guide' ||
    window.location.pathname.split('/')[1] === 'howto' ||
    window.location.pathname.split('/')[1] === 'about' ||
    window.location.pathname.split('/')[1] === 'schoolparty';
  const isRoom = (url) => {
    if (!url) {
      return false;
    }
    return (
      url.split('/')[1] === 'sessionroom' ||
      url.split('/')[1] === 'conferenceroom'
    );
  };
  return (
    <Router history={history}>
      <HeaderContainer
        currentUrl={(val, user) => {
          setCurrentUrl(val);
          setSelectedUser(user);
        }}
        windowWidth={(val) => setWindowWidth(val)}
      />
      <div
        className={cn(
          !isTemplate ? 'px-32 pb-10' : 'null',
          'container-fluid',
          'container_position_top',
          isRoom(url) ? 'pt-0' : ''
        )}
      >
        <Notification />
        <div className='row m-0'>
          {!isTemplate && (
            <div
              id='sidebar'
              className={cn(
                'position-fixed p-0',
                windowWidth >= 1920 &&
                  !isRoom(url) &&
                  url?.split('/')[1] !==
                    (selectedUser || window.localStorage.getItem('userName'))
                  ? 'custom-col-xlg-4 h-90'
                  : 'custom-col-xl-2 sidebar_collapsed_border',
                isRoom(url) ? 'h-90' : 'h-100'
              )}
            >
              <SidebarMenu />
            </div>
          )}
          <div
            className={cn(
              isTemplate
                ? 'custom-col-36 p-0'
                : windowWidth >= 1920 &&
                  !isRoom(url) &&
                  url?.split('/')[1] !==
                    (selectedUser || window.localStorage.getItem('userName'))
                ? 'custom-offset-xlg-4 custom-col-xlg-32 align pr-0 pl-25'
                : 'custom-offset-2 custom-col-xl-34 align p-0'
            )}
          >
            <OnRouteChange>
              <Switch>
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/login`}
                  component={Login}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/`}
                  component={dashboard}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/mediapipe`}
                  component={Mediapipe}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/conferenceroom/:id`}
                  component={ConferenceRoom}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/sessionroom`}
                  component={SessionRoom}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/notes`}
                  component={Notes}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/watchPartylist`}
                  component={WatchPartyList}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/contentLibrary/`}
                  component={ContentLibrary}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/contentLibrary/:name`}
                  component={(props) => (
                    <ContentLibrary {...props} key={window.location.pathname} />
                  )}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/fileupload`}
                  component={fileUpload}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/reactiontsVideo/:id`}
                  component={ReactionsVideo}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/myChannel`}
                  component={MyReactionsTab}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/reactionplayback/:sourceId/:id`}
                  component={ReactionsPlayback}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/reactionplayback/:sourceId`}
                  component={ReactionsPlayback}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/reactionplayback/:sourceId/:id/edit`}
                  component={ReactionsPlayback}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/password`}
                  component={SetPassword}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/feedback`}
                  component={Feedback}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/howto/watchparty`}
                  component={WatchPartyTemplate}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/howto/reaction`}
                  component={ReactionTemplate}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/howto/schoolparty`}
                  component={SchoolPartyTemplate}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/howto/competition`}
                  component={CompetitionTemplate}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/schoolparty`}
                  component={aboutSchoolParty}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/about`}
                  component={about}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/:username/:key?`}
                  component={Channel}
                />
                <Route path='*' component={notFound} />
              </Switch>
            </OnRouteChange>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Routes;
