exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".notification_toast__3aMdt {\n  bottom: 20px;\n  right: 20px;\n  border-radius: 5px;\n  min-height: 50px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;\n  display: flex;\n  justify-content: space-between;\n  font-size: 17px;\n  font-weight: 500;\n  color: #ffffff; }\n\n.notification_successToast__1fbrE,\n.notification_failureToast__2KjXS { }\n\n.notification_successToast__1fbrE {\n  padding: 10px;\n  background: #3E635B; }\n\n.notification_failureToast__2KjXS {\n  padding: 10px;\n  background: #922A2A; }\n\n.notification_notificationContainer__3n4Vd {\n  bottom: 10px;\n  z-index: 999999;\n  width: 360px;\n  max-width: 400px;\n  right: 5px;\n  position: fixed; }\n\n.notification_notification__17ltP .notification_title__1bOno {\n  font-size: 1em;\n  line-height: 1.2em;\n  font-weight: bold;\n  margin: 0 0 5px 0;\n  color: white; }\n\n.notification_notification__17ltP:hover,\n.notification_notification__17ltP:focus {\n  opacity: 1; }\n\n.notification_closeButton__zcERq {\n  border-radius: 9px !important;\n  padding: 0px !important;\n  font-size: small;\n  width: 30px !important;\n  height: 30px !important;\n  margin-left: 10px; }\n", ""]);

// Exports
exports.locals = {
	"toast": "notification_toast__3aMdt",
	"successToast": "notification_successToast__1fbrE notification_toast__3aMdt",
	"failureToast": "notification_failureToast__2KjXS notification_toast__3aMdt",
	"notificationContainer": "notification_notificationContainer__3n4Vd",
	"notification": "notification_notification__17ltP",
	"title": "notification_title__1bOno",
	"closeButton": "notification_closeButton__zcERq"
};