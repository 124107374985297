export const SAVE_USER_REQUESTED = 'REGISTER/SAVE_USER_REQUESTED';
export const SAVE_USER_SUCCESS = 'REGISTER/SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'REGISTER/SAVE_USER_FAILURE';

export const GET_USER_REQUESTED = 'REGISTER/GET_USER_REQUESTED';
export const GET_USER_SUCCESS = 'REGISTER/GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'REGISTER/GET_USER_FAILURE';

export const GET_USER_BYUSERNAME_REQUESTED = 'REGISTER/GET_USER_BYUSERNAME_REQUESTED';
export const GET_USER_BYUSERNAME_SUCCESS = 'REGISTER/GET_USER_BYUSERNAME_SUCCESS';
export const GET_USER_BYUSERNAME_FAILURE = 'REGISTER/GET_USER_BYUSERNAME_FAILURE';

export const SET_PWD_REQ = 'REGISTER/SET_PWD_REQ';
export const SET_PWD_SUCCESS = 'REGISTER/SET_PWD_SUCCESS';
export const SET_PWD_FAILURE = 'REGISTER/SET_PWD_FAILURE';

export const CHECK_USER_REQ = 'REGISTER/CHECK_USER_REQ';
export const CHECK_USER_SUCCESS = 'REGISTER/CHECK_USER_SUCCESS';
export const CHECK_USER_FAILURE = 'REGISTER/CHECK_USER_FAILURE';

export const SET_COUCHBASE_USERNAME_REQ = 'REGISTER/SET_COUCHBASE_USERNAME_REQ';
export const SET_COUCHBASE_USERNAME_SUCCESS = 'REGISTER/SET_COUCHBASE_USERNAME_SUCCESS';
export const SET_COUCHBASE_USERNAME_FAILURE = 'REGISTER/SET_COUCHBASE_USERNAME_FAILURE';
export const RESET_ISUSERNAME_AVAILABLE = 'REGISTER/RESET_ISUSERNAME_AVAILABLE';

export const SAVE_FEEDBACK_REQUESTED = 'REGISTER/SAVE_FEEDBACK_REQUESTED';
export const SAVE_FEEDBACK_SUCCESS = 'REGISTER/SAVE_FEEDBACK_SUCCESS';
export const SAVE_FEEDBACK_FAILURE = 'REGISTER/SAVE_FEEDBACK_FAILURE';

export const SAVE_PHOTO_REQUESTED = 'REGISTER/SAVE_PHOTO_REQUESTED';
export const SAVE_PHOTO_SUCCESS = 'REGISTER/SAVE_PHOTO_SUCCESS';
export const SAVE_PHOTO_FAILURE = 'REGISTER/SAVE_PHOTO_FAILURE';
export const SET_PROFILE_PHOTO_URL = 'REGISTER/SET_PROFILE_PHOTO_URL';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  isUsernameAvailable: '',
  profilePhoto: window.localStorage.getItem('userDetails') && JSON.parse(window.localStorage.getItem('userDetails')).profilePhotoUrl
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SAVE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SAVE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_USER_BYUSERNAME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_USER_BYUSERNAME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_USER_BYUSERNAME_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SET_PWD_REQ: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SET_PWD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SET_PWD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case CHECK_USER_REQ: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case CHECK_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isUsernameAvailable: action.result.available
      };
    }
    case CHECK_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SET_COUCHBASE_USERNAME_REQ: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SET_COUCHBASE_USERNAME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SET_COUCHBASE_USERNAME_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case RESET_ISUSERNAME_AVAILABLE: {
      return {
        ...state,
        isUsernameAvailable: ''
      };
    }
    case SAVE_FEEDBACK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SAVE_FEEDBACK_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SAVE_FEEDBACK_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SAVE_PHOTO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SAVE_PHOTO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        profilePhoto: action.result.profilePhotoUrl
      };
    }
    case SAVE_PHOTO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SET_PROFILE_PHOTO_URL: {
      return {
        ...state,
        profilePhoto: window.localStorage.getItem('userDetails') && JSON.parse(window.localStorage.getItem('userDetails')).profilePhotoUrl
      };
    }
    default:
      return state;
  }
};


export const saveFbGoogleUsers = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [SAVE_USER_REQUESTED, SAVE_USER_SUCCESS, SAVE_USER_FAILURE],
    promise: (client) => client.post('saveFbGoogleUsers', {
      headers,
      data
    })
  };
};

export const getUserDetails = (email) => {
  return {
    types: [GET_USER_REQUESTED, GET_USER_SUCCESS, GET_USER_FAILURE],
    promise: (client) => client.get(`getUserDetails/details?email=${email}`)
  };
};

export const getUserDetailsByUsername = (username) => {
  return {
    types: [GET_USER_BYUSERNAME_REQUESTED, GET_USER_BYUSERNAME_SUCCESS, GET_USER_BYUSERNAME_FAILURE],
    promise: (client) => client.get(`getUserDetails/detailsByUsername?username=${username}`)
  };
};

export const setPassword = (data) => {
  return {
    types: [SET_PWD_REQ, SET_PWD_SUCCESS, SET_PWD_FAILURE],
    promise: (client) => client.post('setPassword', {
      data
    })
  };
}

export const updateUsernameInCouchbase = (data) => {
  return {
    types: [SET_COUCHBASE_USERNAME_REQ, SET_COUCHBASE_USERNAME_SUCCESS, SET_COUCHBASE_USERNAME_FAILURE],
    promise: (client) => client.post('updateUsernameInCouchbase', {
      data
    })
  };
}

export const checkUserAvailability = (data) => {
  return {
    types: [CHECK_USER_REQ, CHECK_USER_SUCCESS, CHECK_USER_FAILURE],
    promise: (client) => client.post('checkUserAvialability', {
      data
    })
  };
};

export const resetIsUsernameAvailable = () => {
  return {
    type: RESET_ISUSERNAME_AVAILABLE
  };
}

export const saveFeedback = (data) => {
  return {
    types: [SAVE_FEEDBACK_REQUESTED, SAVE_FEEDBACK_SUCCESS, SAVE_FEEDBACK_FAILURE],
    promise: (client) => client.post('saveFeedback', {
      data
    })
  };
};

export const uploadProfilePhoto = (data) => {
  return {
    types: [SAVE_PHOTO_REQUESTED, SAVE_PHOTO_SUCCESS, SAVE_PHOTO_FAILURE],
    promise: (client) => client.post('saveProfilePhoto', {
      data
    })
  };
};

export const setProfilePhotoUrl = () => {
  return {
    type: SET_PROFILE_PHOTO_URL
  };
};