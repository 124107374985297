export const GET_REACTION_DATA_REQUESTED = 'logger/GET_REACTION_DATA_REQUESTED';
export const GET_REACTION_DATA_SUCCESS = 'logger/GET_REACTION_DATA_SUCCESS';
export const GET_REACTION_DATA_FAILURE = 'logger/GET_REACTION_DATA_FAILURE';

export const DELETE_REACTION_DETAILS_REQUESTED = 'logger/DELETE_REACTION_DETAILS_REQUESTED';
export const DELETE_REACTION_DETAILS_SUCCESS = 'logger/DELETE_REACTION_DETAILS_SUCCESS';
export const DELETE_REACTION_DETAILS_FAILURE = 'logger/DELETE_REACTION_DETAILS_FAILURE';

export const UPDATE_REACTION_DETAILS_REQUESTED = 'logger/UPDATE_REACTION_DETAILS_REQUESTED';
export const UPDATE_REACTION_DETAILS_SUCCESS = 'logger/UPDATE_REACTION_DETAILS_SUCCESS';
export const UPDATE_REACTION_DETAILS_FAILURE = 'logger/UPDATE_REACTION_DETAILS_FAILURE';

export const GET_MORE_REACTION_VIDEOS_REQUESTED = 'logger/GET_MORE_REACTION_VIDEOS_REQUESTED';
export const GET_MORE_REACTION_VIDEOS_SUCCESS = 'logger/GET_MORE_REACTION_VIDEOS_SUCCESS';
export const GET_MORE_REACTION_VIDEOS_FAILURE = 'logger/GET_MORE_REACTION_VIDEOS_FAILURE';

export const GET_MORE_REACTION_VIDEOS_ONSCROLL_REQUESTED = 'logger/GET_MORE_REACTION_VIDEOS_ONSCROLL_REQUESTED';
export const GET_MORE_REACTION_VIDEOS_ONSCROLL_SUCCESS = 'logger/GET_MORE_REACTION_VIDEOS_ONSCROLL_SUCCESS';
export const GET_MORE_REACTION_VIDEOS_ONSCROLL_FAILURE = 'logger/GET_MORE_REACTION_VIDEOS_ONSCROLL_FAILURE';

export const CLEAR_REACTION_VIDEOS_ON_SCROLL_SUCCESS = 'logger/CLEAR_REACTION_VIDEOS_ON_SCROLL_SUCCESS';


const initialState = {
    loading: false,
    loaded: false,
    error: false,
    reactionCardDetails: [],
    moreReactionVideoDetails: [],
    reactionCardDetailsOnScroll: [],
    count: 0
  };

  const getReactionCardDetailsOnScroll = (action, state) => {
    let reactionArray = [];
    if (state.reactionCardDetailsOnScroll && state.reactionCardDetailsOnScroll.length > 0) {
      reactionArray = action.result.reactionVideosList ? state.reactionCardDetailsOnScroll.concat(action.result.reactionVideosList) : state.reactionCardDetailsOnScroll;
    } else {
      reactionArray = action.result.reactionVideosList;
    }
       return reactionArray;
  }
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_REACTION_DATA_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_REACTION_DATA_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          reactionCardDetails: action.result.reactionVideosList
        };
      }
      case GET_REACTION_DATA_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }

      case GET_MORE_REACTION_VIDEOS_ONSCROLL_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_MORE_REACTION_VIDEOS_ONSCROLL_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          reactionCardDetailsOnScroll: getReactionCardDetailsOnScroll(action, state)
        };
      }
      case GET_MORE_REACTION_VIDEOS_ONSCROLL_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }

      case CLEAR_REACTION_VIDEOS_ON_SCROLL_SUCCESS: {
        return {
          ...state,
          reactionCardDetailsOnScroll: []
        }
      }
      case DELETE_REACTION_DETAILS_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case DELETE_REACTION_DETAILS_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case DELETE_REACTION_DETAILS_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      case UPDATE_REACTION_DETAILS_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case UPDATE_REACTION_DETAILS_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case UPDATE_REACTION_DETAILS_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      case GET_MORE_REACTION_VIDEOS_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_MORE_REACTION_VIDEOS_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          moreReactionVideoDetails: action.result.reactionVideosList,
          count: action.result.totalCount
        };
      }
      case GET_MORE_REACTION_VIDEOS_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      default:
        return state;
    }
  };

  export const getReactionUserDetails = (userId,pageNo,itemsPerPage,search,type,mode) => {
    const searchTerm = search ? search : '';
    const filterType = type ? type : '';
    return {
      types: [GET_REACTION_DATA_REQUESTED, GET_REACTION_DATA_SUCCESS, GET_REACTION_DATA_FAILURE],
      promise: (client) => client.get(`getReactionUserDetails/${userId}?pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&search=${searchTerm}&order=${filterType}&type=${mode}`)
    };
  };

  export const getReactionUserDetailsOnScroll = (userId,pageNo,itemsPerPage,search,type) => {
    const searchTerm = search ? search : '';
    const filterType = type ? type : '';
    return {
      types: [GET_MORE_REACTION_VIDEOS_ONSCROLL_REQUESTED, GET_MORE_REACTION_VIDEOS_ONSCROLL_SUCCESS, GET_MORE_REACTION_VIDEOS_ONSCROLL_FAILURE],
      promise: (client) => client.get(`getReactionUserDetails/${userId}?pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&search=${searchTerm}&type=${filterType}`)
    };
  };

  export const deleteReactionDetail = (data) => {
    return {
      types: [DELETE_REACTION_DETAILS_REQUESTED, DELETE_REACTION_DETAILS_SUCCESS, DELETE_REACTION_DETAILS_FAILURE],
      promise: (client) => client.del('deleteReactionDetail', {
        data
      })
    };
  }

  export const updateReactionDetail = (data) => {
    return {
      types: [DELETE_REACTION_DETAILS_REQUESTED, DELETE_REACTION_DETAILS_SUCCESS, DELETE_REACTION_DETAILS_FAILURE],
      promise: (client) => client.post('updateReactionDetail', {
        data
      })
    };
  }

  export const getMoreReactionVideos = (pageNo,itemsPerPage,reactionId) => {
    return {
      types: [GET_MORE_REACTION_VIDEOS_REQUESTED, GET_MORE_REACTION_VIDEOS_SUCCESS, GET_MORE_REACTION_VIDEOS_FAILURE],
      promise: (client) => client.get(`getMoreReactionVideos?pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&reactionId=${reactionId}`)
    };
  };

  export const clearReactionVideoOnScroll = () => {
    return {
      type: CLEAR_REACTION_VIDEOS_ON_SCROLL_SUCCESS,
    };
  };

  
