import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '@containers/common/Header';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getVideoViewsCount } from '@reducers/videoViews';
import { setSelectedFriend } from '@reducers/friendsFans';

class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeader: false
    };
  }

  componentDidMount() {
    // if(window.localStorage.getItem('userDetails') && JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId !== null && JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId !== undefined) {  
    //   this.props.getVideoViewsCount();
    // }
    // this.setHeader();
  }

  // componentDidUpdate(prevProps) {
  //   const { location, selectedFriend } = this.props;
  //   // const loggedInUser = window.localStorage.getItem('userDetails') && JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId
  //   if (location !== prevProps.location && (location.pathname !== window.localStorage.getItem('userName') || location.pathname !== selectedFriend.userName)) {
  //     this.props.getVideoViewsCount();
  //     // window.scrollTo(0, 0);
  //     // if (selectedFriend.userSurrogateId === loggedInUser || !selectedFriend || location.pathname === '/' || location.pathname === '/contentLibrary/Videos to React' || location.pathname === '/contentLibrary/Watch Parties' || location.pathname === '/feedback' || location.pathname === '/fileUpload') {
  //     //   this.props.getVideoViewsCount();
  //     // } else {
  //     //   this.props.getVideoViewsCount(selectedFriend.userSurrogateId);
  //     // }
  //   }
  // }

  render() {
    return (
      <>
        {this.state.showHeader && <Header />}
        {this.props.children}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    views: state.videoViews.viewsCount,
    reactions: state.videoViews.reactionCount,
    credits: state.videoViews.creditsCount,
    selectedFriend: state.friendsFans.selectedFriend
  };
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
  getVideoViewsCount,
  setSelectedFriend

}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScrollToTop));
