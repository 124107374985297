import React, { Component } from 'react';
import WatchPartyModel from '@components/Models/WatchPartyModel/watchPartyModelContainer';
import BrowserDetectModal from '@components/Models/BrowserDetectModel';
import style from './Header.module.css';
import containerStyle from '@containers/HeaderContainer/HeaderContainer.module.css';
import logo from '../../assets/icons/Frame.svg';
import cn from 'classnames';
import { EN } from '@localization';
import GlobalSearch from '@containers/GlobalSearch';
import Button from '@components/common/formElements/Button';
import bars from '../../assets/icons/Union.svg';
import SidebarMenu from '@components/SidebarMenu';
import { isMobileDevice } from '@helpers/utils';

const GRADE_OPTIONS = [
  { value: '6', label: 'VI' },
  { value: '7', label: 'VII' },
  { value: '8', label: 'VIII' },
  { value: '9', label: 'IX' },
  { value: '10', label: 'X' },
  { value: '11', label: 'XI' },
  { value: '12', label: 'XII' },
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfStatusModelOpened: false,
      prevAction: 0,
      isModelOpened: false,
      watchPartyModelOpened: false,
      isSchedule: false,
      iconClick: false,
      watchPartyEmails: [],
      date: new Date(),
      watchPartyClick: false,
      time: '',
      watchPartyName: '',
      watchPartyParticipantsList: [],
      scheduledDateAndTime: '',
      hasError: false,
      videoURL: '',
      isChrome: true,
      browserAlert: false,
      profilePhoto: props.profilePhoto,
    };
    this.sidebarDrawyer = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      this.setState({
        isChrome: true,
      });
    } else {
      this.setState({
        isChrome: false,
      });
      if (!('browserAlert' in window.sessionStorage)) {
        window.sessionStorage.setItem('browserAlert', true);
        this.setState({
          browserAlert: true,
        });
      }
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.sidebarDrawyer &&
      this.sidebarDrawyer.current &&
      !this.sidebarDrawyer.current.contains(event.target)
    ) {
      this.handleToggle();
    }
  }

  toggleWatchPartyModal = () => {
    this.setState((previousState) => {
      return {
        watchPartyModelOpened: !previousState.watchPartyModelOpened,
      };
    });
  };

  createWatchParty = () => {
    if (!window.localStorage.getItem('userName')) {
      window.localStorage.setItem('redirectionPage', 'newWatchParty');
      this.props.history.push('/login');
      this.setState({ isModelOpened: true, iconClick: false });
    } else {
      this.setState({ watchPartyModelOpened: true, iconClick: false });
    }
  };

  goToUploadVideo = () => {
    this.props.history.push('/fileUpload');
  };

  logout = (showWarning) => {
    if (showWarning) {
      this.setState({
        prevAction: 1,
      });
      this.toggleConfStatusModal();
    } else {
      window.localStorage.clear();
      window.location.href = '/';
    }
  };

  password = (showWarning) => {
    if (showWarning) {
      this.setState({
        prevAction: 3,
      });
      this.toggleConfStatusModal();
    } else {
      window.location.href = '/password';
    }
  };

  redirectDashboard = (showWarning, key) => {
    if (this.props.windowWidth >= 1200) {
      if (showWarning) {
        this.setState({
          prevAction: 2,
        });
        this.toggleConfStatusModal();
      } else if (key && key === 'IconClick') {
        this.props.setUserDetails();
        this.props.history.push('/');
      } else {
        this.props.history.push('/');
      }
    }
  };

  closeConfRoomModal = () => {
    if (this.state.prevAction === 1) {
      this.setState({
        prevAction: 0,
      });
      this.logout(false);
    } else if (this.state.prevAction === 2) {
      this.setState({
        prevAction: 0,
      });
      this.redirectDashboard(false);
    } else if (this.state.prevAction === 3) {
      this.setState({
        prevAction: 0,
      });
      this.password(false);
    }
  };

  toggleConfStatusModal = () => {
    this.setState((previousState) => {
      return {
        isConfStatusModelOpened: !previousState.isConfStatusModelOpened,
      };
    });
  };

  handleToggle = () => {
    this.props.setSidebarMenuState();
    this.setState({ iconClick: !this.state.iconClick });
  };

  componentDidUpdate() {
    if (this.props.windowWidth >= 1920 && this.props.showSidebar) {
      this.handleToggle();
    }
    const redirectionPage = window.localStorage.getItem('redirectionPage');
    if (
      redirectionPage &&
      !this.state.watchPartyModelOpened &&
      window.localStorage.getItem('userName')
    ) {
      if (redirectionPage === 'newWatchParty') {
        this.setState({ watchPartyModelOpened: true, iconClick: false });
      }
    }
    if (this.props.profilePhoto !== this.state.profilePhoto) {
      this.setState({
        profilePhoto: this.props.profilePhoto,
      });
    }
  }

  toggleBrowserDetectModal = () => {
    this.setState({
      isChrome: true,
      browserAlert: false,
    });
    window.sessionStorage.setItem('browserAlert', false);
  };

  render() {
    const { location, showWarning } = this.props;
    const userName = window.localStorage.getItem('userName');
    const isLogIn = userName && userName !== undefined && userName !== null;
    const letter =
      userName &&
      userName
        .split(' ')
        .map((name) => name[0])
        .join('')
        .toUpperCase();
    const selectedUser = !this.props.selectedFriend
      ? window.localStorage.getItem('userName')
      : this.props.selectedFriend.userName;
    const userDetailsJson = window.localStorage.getItem('userDetails');
    const board = userDetailsJson ? JSON.parse(userDetailsJson).board : '';
    const grade = userDetailsJson ? JSON.parse(userDetailsJson).grade : '';
    return (
      <>
        {this.props.location !== '/about' &&
          this.props.location !== '/schoolparty' &&
          this.props.location !== '/privacy-policy' &&
          this.props.location !== '/community-guide' && (
            <div className='row w-100 m-0'>
              <div className='custom-col-xxlg-6 custom-col-xlg-4 custom-col-xl-6 d-flex pl-0'>
                <Button
                  disabled={
                    this.props.location === '/login' ||
                    this.props.location === `/${selectedUser}` ||
                    this.props.windowWidth >= 1920
                  }
                  className='btn-gray'
                  icon={bars}
                  onKeyDown={this.keyDown}
                  onClick={() => this.handleToggle()}
                />
                <span
                  className={cn('outline cursor align-self-center')}
                  onClick={() =>
                    this.redirectDashboard(showWarning, 'IconClick')
                  }
                  role='button'
                  tabIndex={0}
                  onKeyDown={this.keyDown}
                >
                  <div className={style['logo-container']}>
                    <img className={style.iconFont} src={logo} />
                    <div className={style['beta-tag']}>Beta</div>
                  </div>
                </span>
              </div>
              <div
                className={
                  isLogIn
                    ? 'custom-col-xxlg-23 custom-col-xlg-12 d-flex custom-col-xl-12 justify-content-center'
                    : 'custom-col-xxlg-23 custom-col-xlg-12 custom-col-xl-10 txt-center d-flex'
                }
              >
                {location && !location.includes('/conferenceroom/') && (
                  <div
                    className={cn(
                      style.searchBarContainer,
                      'col-12 pr-0 align-self-center pl-3'
                    )}
                  >
                    <GlobalSearch
                      categoryName={this.props.categoryName}
                      location={location}
                      searchKey={this.props.searchKey}
                      searchResults={this.props.searchResults}
                      videoClick={this.props.videoClick}
                      searchTerm={this.props.searchTerm}
                      isDropdownHidden={this.props.isDropdownHidden}
                      displayDropDown={this.props.displayDropDown}
                      openDropdown={this.props.openDropdown}
                      closeDropdown={this.props.closeDropdown}
                      loading={this.props.loading}
                      activeMenu={this.props.activeMenu}
                      history={this.props.history}
                      searchAutocompleteLoading={
                        this.props.searchAutocompleteLoading
                      }
                    />
                  </div>
                )}
              </div>
              <div
                className={cn(
                  'custom-col-xxlg-7 custom-col-xlg-20',
                  isLogIn ? 'custom-col-xl-18' : 'custom-col-xl-20'
                )}
              >
                <div className='row justify-content-end m-0'>
                  {isLogIn && (
                    <Button
                      onClick={this.goToUploadVideo}
                      className='btn-maroon px-3 mr-2'
                      title='Add Content'
                    />
                  )}
                  {isLogIn && (
                    <Button
                      onClick={() => this.props.history.push('/password')}
                      className='btn-gray mr-2 px-4'
                      title={`${GRADE_OPTIONS.find((g) => g.value == grade)
                        ?.label || ''} Grade, ${board}`}
                    />
                  )}
                  <Button
                    onClick={() => this.props.history.push('/about')}
                    className='btn-green px-3 mr-2'
                    title='Why Edberry?'
                  />
                  <div className='txt-center'>
                    {isLogIn ? (
                      <>
                        <div
                          className={cn('outline cursor', 'ml-8')}
                          role='button'
                          tabIndex={0}
                          data-toggle='dropdown'
                        >
                          <div className={cn(style.profileImage)}>
                            {this.state.profilePhoto && (
                              <img
                                className={style.profileImage}
                                src={this.state.profilePhoto}
                              />
                            )}
                            {!this.state.profilePhoto && (
                              <span
                                className={cn(
                                  style.profileLetter,
                                  'align-self-center'
                                )}
                              >
                                {' '}
                                {letter}{' '}
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          className={cn(
                            'dropdown-menu dropdown-menu-right shadow',
                            style.right_40
                          )}
                          role='menu'
                          aria-labelledby='menu1'
                        >
                          <a
                            className='dropdown-item cursor'
                            onClick={() => this.password(showWarning)}
                          >
                            {EN.UPDATE_PROFILE}
                          </a>
                          <a
                            className='dropdown-item cursor'
                            onClick={() => this.logout(showWarning)}
                          >
                            {EN.LOGOUT}
                          </a>
                        </div>
                      </>
                    ) : (
                      <div>
                        {location && location !== '/login' && (
                          <Button
                            onClick={() => this.props.history.push('/login')}
                            title='Sign In'
                            className='btn-gray px-3 ml-8'
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {this.state.watchPartyModelOpened && (
                <WatchPartyModel
                  scheduleWatchParty={this.props.scheduleWatchParty}
                  open={this.state.watchPartyModelOpened}
                  toggle={this.toggleWatchPartyModal}
                  setNotificationMessage={this.props.setNotificationMessage}
                />
              )}
              {this.state.iconClick && this.props.windowWidth < 1920 && (
                <div
                  ref={this.sidebarDrawyer}
                  className={style.overlay_sidebar}
                >
                  <div
                    className={cn(
                      containerStyle.navbar_customized,
                      'row m-0 d-flex mb-17 px-4'
                    )}
                  >
                    <Button
                      disabled={
                        this.props.location === '/login' ||
                        this.props.windowWidth >= 1920
                      }
                      className='btn-gray align-self-center'
                      icon={bars}
                      onKeyDown={this.keyDown}
                      onClick={() => this.handleToggle()}
                    />
                    <img className={style.iconFont} src={logo} />
                  </div>
                  <SidebarMenu
                    toggle={() => this.handleToggle()}
                    customClass='px-4'
                  />
                </div>
              )}
            </div>
          )}
        {(this.props.location === '/about' ||
          this.props.location === '/schoolparty' ||
          this.props.location === '/privacy_policy' ||
          this.props.location === '/community_guidlines') && (
          <div className='row m-0 w-100'>
            <div className='custom-col-12'>
              {!isMobileDevice() ? (
                <Button
                  className={cn('btn-green px-3')}
                  onClick={() => this.props.history.push('/')}
                  title=' ← Home'
                />
              ) : null
              // (
              //   <img className={cn('cursor mt-5')} onClick={() => this.props.history.push('/')}  src={HOME_ICON} />
              // )
              }
            </div>
            <div className='custom-col-12 d-flex justify-content-center'>
              <img
                onClick={() => this.redirectDashboard(showWarning)}
                className={cn(style.iconFont, 'p-0 cursor')}
                src={logo}
              />
            </div>
          </div>
        )}
        {this.state.browserAlert &&
          !isLogIn &&
          !this.state.isChrome &&
          !isMobileDevice() && (
            <BrowserDetectModal
              toggle={this.toggleBrowserDetectModal}
              open={!this.state.isChrome}
              title={EN.USE_CHROME}
            />
          )}
      </>
    );
  }
}

export default Header;
