import React from 'react';
import styles from '@containers/ConferenceRoom/ConferenceRoom.module.scss';
import cn from 'classnames';
import search from '../../assets/icons/search.svg';
import Button from '@components/common/formElements/Button';
import Textfield from '@formElements/TextField';
import defaultPlayerImg from '@assets/images/player.png';
import add from '../../assets/icons/add.svg';
import { reduxForm } from 'redux-form';


const WatchPartySearchContainer = (props) => {
  const searchContent = () => {
    return (
      <div className="txt-center p-1r">
        <div className="d-flex justify-content-center">
          <Button type="button" className={cn('btn-gray mr-2', styles.searchIcon)} icon={search} />
        </div>< br />
        <div className="col-6 display">
          <label className="Font15">{props.isSearchFriendModel ? 'Search for friends to send invites' : 'Search for videos to add to watch party'}</label>
        </div>
      </div>
    )
  }

  const renderSearchData = () => {
    return props.searchData && props.searchData.length > 0 && props.searchData.map((itemObj, index) => {
      return (
        <>
          <div className={cn('custom-col-36 p-14 d-flex')} key={index}>
            <div className={cn('custom-col-26 custom-col-sm-26 custom-col-md-26 custom-col-lg-26 custom-col-xl-28 custom-col-xlg-31 custom-col-xxlg-31')}>
            <div className={cn('positionRelative outline', styles.parentDivHgtWdt)}>
              {itemObj.thumbnail && (
                <img src={itemObj.thumbnail} alt={itemObj.title} className={cn(styles.myImg, 'cursor', styles.imgHgtWdt)} />
              )}
              {!itemObj.thumbnail && (
                <img src={defaultPlayerImg} alt={itemObj.title} className={cn(styles.myImg, 'cursor', styles.imgHgtWdt)} />
              )}
              </div>
            </div>
            <div className="custom-col-10 custom-col-sm-10 custom-col-md-10 custom-col-lg-10 custom-col-xl-8 custom-col-xlg-5 custom-col-xxlg-5">
              <Button className={cn('btn-gray mr-2 iconBg', styles.addBtnHt)} icon={add} onClick={() => { props.addNextVideo(itemObj); }} />
            </div>
          </div>
          <div className={cn('custom-col-26 custom-col-sm-26 custom-col-md-26 custom-col-lg-26 custom-col-xl-28 custom-col-xlg-31 custom-col-xxlg-31')}>
            <div className={cn('px-2 pb-10', styles.cardTitle)}>{itemObj.title}</div>
          </div>
        </>
      );
    })
  }

  const renderFriendsList = () => {
    return props.friendsListArray && props.friendsListArray.length > 0 && props.friendsListArray.map((friend, index) => {
      return (
        <div className={cn('row m-0 p-1r')} key={index}>
          <div className="custom-col-30 d-flex">
            <div className={cn('custom-col-9 display')}>
              {friend.profilePhotoUrl ? (
                <img className={cn(styles.profileImage)} src={friend.profilePhotoUrl} alt="" ></img>
              ) : (
                <div className={cn(styles.profileImage, styles.profileLetterStyle)}><span>{friend.name.split('')[0][0].toUpperCase()}</span></div>
              )}
            </div>
            <div className={cn('custom-col-27 display')}>
              <label className="align-self-center">{friend.name}</label>
              <div>
                <label className={cn('align-self-center', styles.greyFont)}>@{friend.userName}</label>
              </div>
            </div>
          </div>
          <div className={cn('custom-col-6 display')}>
            <Button className={cn('btn-gray iconBg')} icon={add} onClick={() => { props.addFriendsEmail(friend); }} />
          </div>
        </div>
      )
    })
  }
  return (
    <>
      <div className={cn(styles.searchContainer, '123', props.customSearchContainerClass)}>
        <div className="InputAddOn p-1r positionRelative">
          <Textfield
            name="searchnextvideo"
            type="text"
            placeholder={props.isSearchFriendModel ? "Search By Name" : "Search Video"}
            customClass="vidberrys-textfield-gray"
            onChange={props.isSearchFriendModel ? props.searchFriends : props.searchVideos}
          />
          <Button type="button" className={cn(styles.cancelBtn)} title="Cancel" onClick={props.searchField} />
        </div>
        <hr className="mt-2 mb-0" />
        <div className={cn((props.searchData.length !== 0 || props.friendsListArray.length !== 0) && styles.scroll, props.customScrollContainerClass)}>
          {props.isSearchDataPresent && searchContent()}
          {(props.searchData.length > 0 && !props.isSearchDataPresent) && (
            renderSearchData()
          )}
          {!props.isSearchDataPresent && props.friendsList && (
            renderFriendsList()
          )}
        </div>
      </div>
    </>
  )
}

export default reduxForm({
  form: 'selectVideo'
})(WatchPartySearchContainer)
