export const RENDER_ELEMENTS_PANEL = 'ui/RENDER_ELEMENTS_PANEL';
export const SIDEBAR_MENU_STATE = 'ui/SIDEBAR_MENU_STATE';
export const WINDOW_WIDTH = 'ui/WINDOW_WIDTH';
export const ACTIVE_MENU_STATUS = 'ui/ACTIVE_MENU_STATUS';
export const SET_REACT_PLAYER_HEIGHT = 'ui/SET_REACT_PLAYER_HEIGHT';


const initialState = {
  showElementPanel: false,
  showSidebar: false,
  windowWidth: window.innerWidth,
  activeMenu: false,
  reactPlayerHeight: 123
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RENDER_ELEMENTS_PANEL: {
      return {
        ...state,
        showElementPanel: action.payload
      };
    }
    case SIDEBAR_MENU_STATE: {
      return {
        ...state,
        showSidebar: action.payload
      };
    }
    case WINDOW_WIDTH: {
      return {
        ...state,
        windowWidth: action.payload
      };
    }
    case ACTIVE_MENU_STATUS: {
      return {
        ...state,
        activeMenu: action.payload
      };
    }
    case SET_REACT_PLAYER_HEIGHT: {
      return {
        ...state,
        reactPlayerHeight: action.payload
      };
    }
    default:
      return state;
  }
};

export const renderElementPanel = (payload) => {
  return {
    type: RENDER_ELEMENTS_PANEL,
    payload
  };
};

export const setSidebarMenuState = (payload) => {
  return {
    type: SIDEBAR_MENU_STATE,
    payload
  };
};

export const setWindowWidth = (payload) => {
  return {
    type: WINDOW_WIDTH,
    payload
  };
};

export const activeMenu = (payload) => {
  return {
    type: ACTIVE_MENU_STATUS,
    payload
  };
};

export const setReactPlayerHeight = (payload) => {
  return {
    type: SET_REACT_PLAYER_HEIGHT,
    payload
  };
}