export const ADD_USER_STATS_REQUESTED = 'watchparty/ADD_USER_STATS_REQUESTED';
export const ADD_USER_STATS_SUCCESS = 'watchparty/ADD_USER_STATS_SUCCESS';
export const ADD_USER_STATS_FAILURE = 'watchparty/ADD_USER_STATS_FAILURE';

export const ADD_CREDIT_STATS_REQUESTED =
  'watchparty/ADD_CREDIT_STATS_REQUESTED';
export const ADD_CREDIT_STATS_SUCCESS = 'watchparty/ADD_CREDIT_STATS_SUCCESS';
export const ADD_CREDIT_STATS_FAILURE = 'watchparty/ADD_CREDIT_STATS_FAILURE';

export const GET_VIDEO_VIEWS_COUNT_REQUESTED =
  'watchparty/GET_VIDEO_VIEWS_COUNT_REQUESTED';
export const GET_VIDEO_VIEWS_COUNT_SUCCESS =
  'watchparty/GET_VIDEO_VIEWS_COUNT_SUCCESS';
export const GET_VIDEO_VIEWS_COUNT_FAILURE =
  'watchparty/GET_VIDEO_VIEWS_COUNT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  viewsCount: 0,
  reactionCount: 0,
  creditsCount: 0,
  fansCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_STATS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_USER_STATS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case ADD_USER_STATS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_VIDEO_VIEWS_COUNT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        viewsCount: 0,
        reactionCount: 0,
        creditsCount: 0,
      };
    }
    case GET_VIDEO_VIEWS_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        viewsCount: action.result.noOfViews,
        reactionCount: action.result.noOfReactions,
        creditsCount: action.result.noOfCredtis,
        fansCount: action.result.noOfFans,
      };
    }
    case GET_VIDEO_VIEWS_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case ADD_CREDIT_STATS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_CREDIT_STATS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case ADD_CREDIT_STATS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const addUserStats = (data, videoId, ipDetails) => {
  const userSurrogateId = window.localStorage.getItem('userDetails')
    ? JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId
    : 'null';
  const headers = { ipDetails: ipDetails ? JSON.stringify(ipDetails) : null };
  return {
    types: [
      ADD_USER_STATS_REQUESTED,
      ADD_USER_STATS_SUCCESS,
      ADD_USER_STATS_FAILURE,
    ],
    promise: (client) =>
      client.post(`addUserStats/${userSurrogateId}/${videoId}`, {
        headers,
        data,
      }),
  };
};

export const getVideoViewsCount = (uid, fansCount, friendsCount) => {
  const userSurrogateId = uid
    ? uid
    : window.localStorage.getItem('userDetails')
    ? JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId
    : 'null';
  const showFansCount = fansCount ? fansCount : false;
  const showFriendsCount = friendsCount ? friendsCount : false;
  return {
    types: [
      GET_VIDEO_VIEWS_COUNT_REQUESTED,
      GET_VIDEO_VIEWS_COUNT_SUCCESS,
      GET_VIDEO_VIEWS_COUNT_FAILURE,
    ],
    promise: (client) =>
      client.get(
        `getVideoViewsCount/${userSurrogateId}?fansCount=${showFansCount}&friendsCount=${showFriendsCount}`
      ),
  };
};

export const addCreditStats = (data) => {
  const userSurrogateId = window.localStorage.getItem('userDetails')
    ? JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId
    : 'null';
  return {
    types: [
      ADD_CREDIT_STATS_REQUESTED,
      ADD_CREDIT_STATS_SUCCESS,
      ADD_CREDIT_STATS_FAILURE,
    ],
    promise: (client) =>
      client.post(`addCreditStats/${userSurrogateId}`, {
        data,
      }),
  };
};
