export const PROCESS_EDITED_VIDEOS_REQUESTED = 'curatedVideos/PROCESS_EDITED_VIDEOS_REQUESTED';
export const PROCESS_EDITED_VIDEOS_SUCCESS = 'curatedVideos/PROCESS_EDITED_VIDEOS_SUCCESS';
export const PROCESS_EDITED_VIDEOS_FAILURE = 'curatedVideos/PROCESS_EDITED_VIDEOS_FAILURE';

export const PUBLISH_VIDEOS_REQUESTED = 'curatedVideos/PUBLISH_VIDEOS_REQUESTED';
export const PUBLISH_VIDEOS_SUCCESS = 'curatedVideos/PUBLISH_VIDEOS_SUCCESS';
export const PUBLISH_VIDEOS_FAILURE = 'curatedVideos/PUBLISH_VIDEOS_FAILURE';

export const GET_EDITED_VIDEOS_LIST_REQUESTED = 'curatedVideos/GET_EDITED_VIDEOS_LIST_REQUESTED';
export const GET_EDITED_VIDEOS_LIST_SUCCESS = 'curatedVideos/GET_EDITED_VIDEOS_LIST_SUCCESS';
export const GET_EDITED_VIDEOS_LIST_FAILURE = 'curatedVideos/GET_EDITED_VIDEOS_LIST_FAILURE';

export const DELETE_EDITED_VIDEO_REQUESTED = 'curatedVideos/DELETE_EDITED_VIDEO_REQUESTED';
export const DELETE_EDITED_VIDEO_SUCCESS = 'curatedVideos/DELETE_EDITED_VIDEO_SUCCESS';
export const DELETE_EDITED_VIDEO_FAILURE = 'curatedVideos/DELETE_EDITED_VIDEO_FAILURE';

export const UPDATE_EDITED_VIDEO_REQUESTED = 'curatedVideos/UPDATE_EDITED_VIDEO_REQUESTED';
export const UPDATE_EDITED_VIDEO_SUCCESS = 'curatedVideos/UPDATE_EDITED_VIDEO_SUCCESS';
export const UPDATE_EDITED_VIDEO_FAILURE = 'curatedVideos/UPDATE_EDITED_VIDEO_FAILURE';

export const DOWNLOAD_VIDEO_REQUESTED = 'curatedVideos/DOWNLOAD_VIDEO_REQUESTED';
export const DOWNLOAD_VIDEO_SUCCESS = 'curatedVideos/DOWNLOAD_VIDEO_SUCCESS';
export const DOWNLOAD_VIDEO_FAILURE = 'curatedVideos/DOWNLOAD_VIDEO_FAILURE';

const initialState = {
  editedVideoList: [],
  file: null,
}


export default (state = initialState, action) => {
    switch (action.type) {

      case PROCESS_EDITED_VIDEOS_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case PROCESS_EDITED_VIDEOS_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case PROCESS_EDITED_VIDEOS_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      case PUBLISH_VIDEOS_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case PUBLISH_VIDEOS_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case PUBLISH_VIDEOS_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      case GET_EDITED_VIDEOS_LIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_EDITED_VIDEOS_LIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          editedVideoList: action.result.editVideoList
        };
      }
      case GET_EDITED_VIDEOS_LIST_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }

      case DELETE_EDITED_VIDEO_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case DELETE_EDITED_VIDEO_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true
        };
      }
      case DELETE_EDITED_VIDEO_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }

      case UPDATE_EDITED_VIDEO_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case UPDATE_EDITED_VIDEO_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true
        };
      }
      case UPDATE_EDITED_VIDEO_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      case DOWNLOAD_VIDEO_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DOWNLOAD_VIDEO_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        file: action.result
      };
    }
    case DOWNLOAD_VIDEO_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
  
      default:
        return state;
    }
  };

  export const editVideos = (data) => {
    return {
      types: [PROCESS_EDITED_VIDEOS_REQUESTED, PROCESS_EDITED_VIDEOS_SUCCESS, PROCESS_EDITED_VIDEOS_FAILURE],
      promise: (client) => client.post(`streamOverlay`, {
        data,
      })
    };
}

export const publishVideos = (formData) => {
  return {
    types: [PUBLISH_VIDEOS_REQUESTED, PUBLISH_VIDEOS_SUCCESS, PUBLISH_VIDEOS_FAILURE],
    promise: (client) => client.post(`publishVideos`, {
      data: formData,
    })
  };
}

export const getEditedVideosList = (userSurrogateId,reactionId, pageNo, itemsPerPage) => {
  return {
    types: [GET_EDITED_VIDEOS_LIST_REQUESTED, GET_EDITED_VIDEOS_LIST_SUCCESS, GET_EDITED_VIDEOS_LIST_FAILURE],
    promise: (client) => client.get(`getEditedVideosList/${reactionId}/${userSurrogateId}?pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`),
  };
};

export const deleteEditedVideo = (editVideoId, userSurrogateId) => {
  return {
    types: [DELETE_EDITED_VIDEO_REQUESTED, DELETE_EDITED_VIDEO_SUCCESS, DELETE_EDITED_VIDEO_FAILURE],
    promise: (client) => client.del(`deleteEditedVideo/${editVideoId}/${userSurrogateId}`)
  };
};

export const updateEditedVideo = (editVIdeoId,userSurrogateId,updateKey,updateValue) => {
  return {
    types: [PUBLISH_VIDEOS_REQUESTED, PUBLISH_VIDEOS_SUCCESS, PUBLISH_VIDEOS_FAILURE],
    promise: (client) => client.post(`updateEditedVideo/${editVIdeoId}/${userSurrogateId}?updateKey=${updateKey}&updateValue=${updateValue}`, {
      // data: formData,
    })
  };
}

export const downloadEditedVideo = (fileName) => {
  return {
    types: [DOWNLOAD_VIDEO_REQUESTED, DOWNLOAD_VIDEO_SUCCESS, DOWNLOAD_VIDEO_FAILURE],
    promise: client => client.get(`downloadEditedVideo?objectPath=${fileName}`)
  };
};


