exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".FileUpload_secondary_header__2pQHm {\n  color: #555E67;\n  font-size: 10px;\n  padding: 0px 7px; }\n\n.FileUpload_custom_label__1fU5r {\n  color: #9EA7B0;\n  font-weight: 500;\n  margin-left: 25px; }\n\n.FileUpload_orSeparator__deJaj {\n  color: #9EA7B0;\n  font-weight: 500; }\n\n.FileUpload_customError__3o1S0 {\n  font-weight: 400;\n  font-size: 13px;\n  color: red;\n  margin-left: 10px !important; }\n\n.FileUpload_custom_file_upload__2j-c6 {\n  cursor: pointer;\n  width: 100%;\n  height: 80px;\n  background: rgba(227, 232, 241, 0.15);\n  border: 1px dashed #E3E8F1;\n  box-sizing: border-box;\n  border-radius: 16px;\n  display: flex;\n  padding: 8px 24px;\n  justify-content: space-between; }\n\n.FileUpload_custom_file_upload_error__2rct4 {\n  border: 1px dashed #922A2A;\n  color: #922A2A; }\n\n.FileUpload_custom_file_upload__2j-c6 input[type=\"file\"] {\n  display: none; }\n\n.FileUpload_file_upload_inner_Button__3qBl9 {\n  max-width: 98px;\n  height: 38px;\n  background: #FFFFFF;\n  border-radius: 12px;\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  color: #555E67;\n  padding: 0px 13px; }\n\n.FileUpload_text_decoration_none__1HoRs a {\n  text-decoration: none; }\n\n.FileUpload_helpText__3UKfl {\n  font-weight: 600;\n  font-size: 15px;\n  line-height: 24px;\n  color: #555E67; }\n", ""]);

// Exports
exports.locals = {
	"secondary_header": "FileUpload_secondary_header__2pQHm",
	"custom_label": "FileUpload_custom_label__1fU5r",
	"orSeparator": "FileUpload_orSeparator__deJaj",
	"customError": "FileUpload_customError__3o1S0",
	"custom_file_upload": "FileUpload_custom_file_upload__2j-c6",
	"custom_file_upload_error": "FileUpload_custom_file_upload_error__2rct4",
	"file_upload_inner_Button": "FileUpload_file_upload_inner_Button__3qBl9",
	"text_decoration_none": "FileUpload_text_decoration_none__1HoRs",
	"helpText": "FileUpload_helpText__3UKfl"
};