import React from 'react';
import HeaderComponent from '@containers/HeaderContainer';

const Header = () => {
  return (
    <HeaderComponent />
  );
};

export default Header;
