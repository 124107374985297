export const GET_REACTION_VIDEOS_LIST_REQUESTED = 'reactionVideosList/GET_REACTION_VIDEOS_LIST_REQUESTED';
export const GET_REACTION_VIDEOS_LIST_SUCCESS = 'reactionVideosList/GET_REACTION_VIDEOS_LIST_SUCCESS';
export const GET_REACTION_VIDEOS_LIST_FAILURE = 'reactionVideosList/GET_REACTION_VIDEOS_LIST_FAILURE';

export const UPDATE_REACTION_VIEW_COUNT_REQUESTED = 'reactionVideosList/UPDATE_REACTION_VIEW_COUNT_REQUESTED';
export const UPDATE_REACTION_VIEW_COUNT_SUCCESS = 'reactionVideosList/UPDATE_REACTION_VIEW_COUNT_SUCCESS';
export const UPDATE_REACTION_VIEW_COUNT_FAILURE = 'reactionVideosList/UPDATE_REACTION_VIEW_COUNT_FAILURE';

export const GET_PLAYBACK_REACTIONS_REQUESTED = 'reactionVideosList/GET_PLAYBACK_REACTIONS_REQUESTED';
export const GET_PLAYBACK_REACTIONS_SUCCESS = 'reactionVideosList/GET_PLAYBACK_REACTIONS_SUCCESS';
export const GET_PLAYBACK_REACTIONS_FAILURE = 'reactionVideosList/GET_PLAYBACK_REACTIONS_FAILURE';

export const UPDATED_REACTION_LIST = 'reactionVideosList/GET_PLAYBACK_REACTIONS_FAILURE';

export const UPDATE_LIKEDISLIKE_ACTION_REQUESTED = 'reactionVideosList/UPDATE_LIKEDISLIKE_ACTION_REQUESTED';
export const UPDATE_LIKEDISLIKE_ACTION_SUCCESS = 'reactionVideosList/UPDATE_LIKEDISLIKE_ACTION_SUCCESS';
export const UPDATE_LIKEDISLIKE_ACTION_FAILURE = 'reactionVideosList/UPDATE_LIKEDISLIKE_ACTION_FAILURE';

export const GET_LIKEDISLIKE_STATUS_REQUESTED = 'reactionVideosList/GET_LIKEDISLIKE_STATUS_REQUESTED';
export const GET_LIKEDISLIKE_STATUS_SUCCESS = 'reactionVideosList/GET_LIKEDISLIKE_STATUS_SUCCESS';
export const GET_LIKEDISLIKE_STATUS_FAILURE = 'reactionVideosList/GET_LIKEDISLIKE_STATUS_FAILURE';

const initialState = {
    loading: false,
    loaded: false,
    error: false,
    videosList:[],
    playBackReactionsList: [],
    updatedReactionsList: [],
    checkVideoStatus: ''
  };

  export default (state = initialState, action) => {
    switch (action.type) {
        case GET_REACTION_VIDEOS_LIST_REQUESTED: {
            return {
              ...state,
              loading: true,
              loaded: false
            };
          }
        case GET_REACTION_VIDEOS_LIST_SUCCESS: {
            return {
            ...state,
            loading: false,
            error: false,
            loaded: true,
            videosList: action.result.responseList
            };
        }

        case GET_REACTION_VIDEOS_LIST_FAILURE: {
            return {
            ...state,
            loading: false,
            loaded: false,
            error: true
            };
        }
        case UPDATE_REACTION_VIEW_COUNT_REQUESTED: {
            return {
              ...state,
              loading: true,
              loaded: false
            };
          }
        case UPDATE_REACTION_VIEW_COUNT_SUCCESS: {
            return {
            ...state,
            loading: false,
            error: false,
            loaded: true,
            };
        }

        case UPDATE_REACTION_VIEW_COUNT_FAILURE: {
            return {
            ...state,
            loading: false,
            loaded: false,
            error: true
            };
        }

        case UPDATE_LIKEDISLIKE_ACTION_REQUESTED: {
          return {
            ...state,
            loading: true,
            loaded: false
          };
        }
      case UPDATE_LIKEDISLIKE_ACTION_SUCCESS: {
          return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          };
      }

      case UPDATE_LIKEDISLIKE_ACTION_FAILURE: {
          return {
          ...state,
          loading: false,
          loaded: false,
          error: true
          };
      }

        case GET_PLAYBACK_REACTIONS_REQUESTED: {
          return {
            ...state,
            loading: true,
            loaded: false
          };
        }
      case GET_PLAYBACK_REACTIONS_SUCCESS: {
          return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          videosList: action.result.responseList
          };
      }

      case GET_PLAYBACK_REACTIONS_FAILURE: {
          return {
          ...state,
          loading: false,
          loaded: false,
          error: true
          };
      }
      case UPDATED_REACTION_LIST: {
        return {
        ...state,
        updatedReactionsList: action.payload
        };
    }
    case GET_LIKEDISLIKE_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  case GET_LIKEDISLIKE_STATUS_SUCCESS: {
      return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      checkVideoStatus: action.result.action
      };
  }

  case GET_LIKEDISLIKE_STATUS_FAILURE: {
      return {
      ...state,
      loading: false,
      loaded: false,
      error: true
      };
  }
      default:
        return state;
    }
  };

  export const getReactionList = (userSurrogateId,videoId,pageNo,itemsPerPage, orderType, searchTerm, hostUserId) => {
    const searchKey = searchTerm ? searchTerm : '';
    const order = orderType ? orderType : 'desc'
    return {
      types: [GET_REACTION_VIDEOS_LIST_REQUESTED, GET_REACTION_VIDEOS_LIST_SUCCESS, GET_REACTION_VIDEOS_LIST_FAILURE],
      promise: (client) => client.get(`getReactionData/${userSurrogateId}/${videoId}?list=true&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&order=${order}&search=${searchKey}&reactionUserId=${hostUserId}`),
    };
  };

  export const updateViewCountOfReaction = (reactionVideoId, data, ipDetails) => {
    const headers = { ipDetails: ipDetails ? JSON.stringify(ipDetails) : null };
    return {
      types: [UPDATE_REACTION_VIEW_COUNT_REQUESTED, UPDATE_REACTION_VIEW_COUNT_SUCCESS, UPDATE_REACTION_VIEW_COUNT_FAILURE],
      promise: (client) => client.post(`updateViewCountOfReaction/${reactionVideoId}`, {
        headers,
        data,
      })
    };
  }

  export const getPlayBackReactions = (videoId) => {
    return {
      types: [GET_PLAYBACK_REACTIONS_REQUESTED, GET_PLAYBACK_REACTIONS_SUCCESS, GET_PLAYBACK_REACTIONS_FAILURE],
      promise: (client) => client.get(`getPlayBackReactions/${videoId}`),
    };
  };

  export const getUpdatedReactionsList = (payload) => {
    return {
      type: UPDATED_REACTION_LIST,
      payload
    };
  };

  export const likeDislikeAction = (action,reactionVideoId,type) => {
    const userSurrogateId = window.localStorage.getItem('userDetails') ? JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId : 'null';
    const userName = window.localStorage.getItem('userDetails') ? JSON.parse(window.localStorage.getItem('userDetails')).userName : 'null';
    const data = {
      userSurrogateId: userSurrogateId,
      userName: userName,
      type:type
    }
    return {
      types: [UPDATE_LIKEDISLIKE_ACTION_REQUESTED, UPDATE_LIKEDISLIKE_ACTION_SUCCESS, UPDATE_LIKEDISLIKE_ACTION_FAILURE],
      promise: (client) => client.post(`likeDislikeAction/${action}/${reactionVideoId}`, {
        data,
      })
    };
  }

  export const getLikeDislikeStatus = (userSurrogateId,videoId) => {
    return {
      types: [GET_LIKEDISLIKE_STATUS_REQUESTED, GET_LIKEDISLIKE_STATUS_SUCCESS, GET_LIKEDISLIKE_STATUS_FAILURE],
      promise: (client) => client.get(`getLikeDislikeStatus/${userSurrogateId}/${videoId}`),
    };
  };
