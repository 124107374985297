export const SEND_INVITE_REQUESTED = 'friendsFans/SEND_INVITE_REQUESTED';
export const SEND_INVITE_SUCCESS = 'friendsFans/SEND_INVITE_SUCCESS';
export const SEND_INVITE_FAILURE = 'friendsFans/SEND_INVITE_FAILURE';

export const BECOME_FRIEND_REQUESTED = 'friendsFans/BECOME_FRIEND_REQUESTED';
export const BECOME_FRIEND_SUCCESS = 'friendsFans/BECOME_FRIEND_SUCCESS';
export const BECOME_FRIEND_FAILURE = 'friendsFans/BECOME_FRIEND_FAILURE';

export const ACCEPT_REJECT_REQUEST_REQUESTED = 'friendsFans/ACCEPT_REJECT_REQUEST_REQUESTED';
export const ACCEPT_REJECT_REQUEST_SUCCESS = 'friendsFans/ACCEPT_REJECT_REQUEST_SUCCESS';
export const ACCEPT_REJECT_REQUEST_FAILURE = 'friendsFans/ACCEPT_REJECT_REQUEST_FAILURE';

export const SEARCH_FRIENDS_REQUESTED = 'friendsFans/SEARCH_FRIENDS_REQUESTED';
export const SEARCH_FRIENDS_SUCCESS = 'friendsFans/SEARCH_FRIENDS_SUCCESS';
export const SEARCH_FRIENDS_FAILURE = 'friendsFans/SEARCH_FRIENDS_FAILURE';

export const GET_FRIENDS_LIST_REQUESTED = 'friendsFans/GET_FRIENDS_LIST_REQUESTED';
export const GET_FRIENDS_LIST_SUCCESS = 'friendsFans/GET_FRIENDS_LIST_SUCCESS';
export const GET_FRIENDS_LIST_FAILURE = 'friendsFans/GET_FRIENDS_LIST_FAILURE';

export const GET_FANS_LIST_REQUESTED = 'friendsFans/GET_FANS_LIST_REQUESTED';
export const GET_FANS_LIST_SUCCESS = 'friendsFans/GET_FANS_LIST_SUCCESS';
export const GET_FANS_LIST_FAILURE = 'friendsFans/GET_FANS_LIST_FAILURE';

export const GET_FOLLOWINGS_LIST_REQUESTED = 'friendsFans/GET_FOLLOWINGS_LIST_REQUESTED';
export const GET_FOLLOWINGS_LIST_SUCCESS = 'friendsFans/GET_FOLLOWINGS_LIST_SUCCESS';
export const GET_FOLLOWINGS_LIST_FAILURE = 'friendsFans/GET_FOLLOWINGS_LIST_FAILURE';

export const GET_PENDING_LIST_REQUESTED = 'friendsFans/GET_PENDING_LIST_REQUESTED';
export const GET_PENDING_LIST_SUCCESS = 'friendsFans/GET_PENDING_LIST_SUCCESS';
export const GET_PENDING_LIST_FAILURE = 'friendsFans/GET_PENDING_LIST_FAILURE';

export const FAN_STATUS_REQUESTED = 'friendsFans/FAN_STATUS_REQUESTED';
export const FAN_STATUS_SUCCESS = 'friendsFans/FAN_STATUS_SUCCESS';
export const FAN_STATUS_FAILURE = 'friendsFans/FAN_STATUS_FAILURE';
export const SET_SELECTED_FRIEND = 'friendsFans/SET_SELECTED_FRIEND';

export const GET_USER_DETAILS_REQUESTED = 'friendsFans/GET_USER_DETAILS_REQUESTED';
export const GET_USER_DETAILS_SUCCESS = 'friendsFans/GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'friendsFans/GET_USER_DETAILS_FAILURE';

const initialState = {
  friendsListCount: 0,
  pendingCount: 0,
  list: [],
  totalFansCount: 0,
  selectedFriend: '',
  totalFollowingsCount: 0,
  userDetailsOnLoad: {}
}

export default (state = initialState, action) => {
  switch (action.type) {

    case SEND_INVITE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SEND_INVITE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SEND_INVITE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case SEARCH_FRIENDS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SEARCH_FRIENDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SEARCH_FRIENDS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    case GET_FRIENDS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_FRIENDS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        friendsListCount: action.result.totalCount && action.result.totalCount,
        list: action.result.userFriendsList && action.result.userFriendsList
      };
    }
    case GET_FRIENDS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    case GET_FANS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_FANS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        totalFansCount:action.result.fansCount
      };
    }
    case GET_FANS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    case GET_FOLLOWINGS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_FOLLOWINGS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        totalFollowingsCount:action.result.followingsCount
      };
    }
    case GET_FOLLOWINGS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    case GET_PENDING_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_PENDING_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        pendingCount: action.result.pendingCount,
      };
    }
    case GET_PENDING_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case ACCEPT_REJECT_REQUEST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ACCEPT_REJECT_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case ACCEPT_REJECT_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    case BECOME_FRIEND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case BECOME_FRIEND_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case BECOME_FRIEND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    case FAN_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case FAN_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case FAN_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case SET_SELECTED_FRIEND: {
      return {
        ...state,
        selectedFriend: action.payload
      };
    }

    case GET_USER_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        userDetailsOnLoad: action.result.userDetails
      };
    }
    case GET_USER_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    default:
      return state;
  }
};

export const sendInviteRequest = (formData) => {
  return {
    types: [SEND_INVITE_REQUESTED, SEND_INVITE_SUCCESS, SEND_INVITE_FAILURE],
    promise: (client) => client.post(`sendInviteRequest`, {
      data: formData,
    })
  };
}

export const searchFriends = (searchTerm, userSurrogateId, inviteAll) => {
  const inviteKey = inviteAll ? true : false;
  return {
    types: [SEARCH_FRIENDS_REQUESTED, SEARCH_FRIENDS_SUCCESS, SEARCH_FRIENDS_FAILURE],
    promise: (client) => client.get(`searchFriends/?search=${searchTerm}&userId=${userSurrogateId}&inviteAll=${inviteKey}`),
  };
};

export const getPendingRequest = (status, userSurrogateId, pageNo, itemsPerPage) => {
  return {
    types: [GET_PENDING_LIST_REQUESTED, GET_PENDING_LIST_SUCCESS, GET_FRIENDS_LIST_FAILURE],
    promise: (client) => client.get(`getFriendsRequest?status=${status}&userId=${userSurrogateId}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`),
  };
};

export const getFriendsRequest = (status, userSurrogateId, pageNo, itemsPerPage) => {
  return {
    types: [GET_FRIENDS_LIST_REQUESTED, GET_FRIENDS_LIST_SUCCESS, GET_FRIENDS_LIST_FAILURE],
    promise: (client) => client.get(`getFriendsRequest?status=${status}&userId=${userSurrogateId}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`),
  };
};

export const actionOnPendingRequest = (formData) => {
  return {
    types: [ACCEPT_REJECT_REQUEST_REQUESTED, ACCEPT_REJECT_REQUEST_SUCCESS, ACCEPT_REJECT_REQUEST_FAILURE],
    promise: (client) => client.post(`actionOnPendingRequest`, {
      data: formData,
    })
  };
}

export const becomeAfan = (userID, userFanId) => {
  return {
    types: [BECOME_FRIEND_REQUESTED, BECOME_FRIEND_SUCCESS, BECOME_FRIEND_FAILURE],
    promise: (client) => client.post(`becomeAfan?userId=${userID}&userFanId=${userFanId}`)
  };
}

export const getFansList = (userSurrogateId, pageNo, itemsPerPage) => {
  return {
    types: [GET_FANS_LIST_REQUESTED, GET_FANS_LIST_SUCCESS, GET_FANS_LIST_FAILURE],
    promise: (client) => client.get(`getFansList?userId=${userSurrogateId}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`),
  };
};

export const getFollowingsList = (userSurrogateId, pageNo, itemsPerPage) => {
  return {
    types: [GET_FOLLOWINGS_LIST_REQUESTED, GET_FOLLOWINGS_LIST_SUCCESS, GET_FOLLOWINGS_LIST_FAILURE],
    promise: (client) => client.get(`getFollowingsList?userId=${userSurrogateId}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`),
  };
};

export const checkFanStatus = (userID,userFanId) => {
  return {
    types: [FAN_STATUS_REQUESTED, FAN_STATUS_SUCCESS, FAN_STATUS_FAILURE],
    promise: (client) => client.get(`checkFanStatus?userId=${userID}&hostUserId=${userFanId}`),
  };
};

export const setSelectedFriend = (friend) => {
  return {
    type: SET_SELECTED_FRIEND,
    payload: friend,
  };
};

export const getUserDetailsForChannel = (userName) => {
  return {
    types: [GET_USER_DETAILS_REQUESTED, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_FAILURE],
    promise: (client) => client.get(`getUserDetailsForChannel?username=${userName}`),
  };
};