import React, { useState } from 'react'
import block1 from '@assets/images/MarketingTemplates/watchPartyBlock1.png';
import signin from '@assets/images/MarketingTemplates/sign_in.svg';
import block4_c2 from '@assets/images/MarketingTemplates/block4_c2.png';
import block5_c1 from '@assets/images/MarketingTemplates/block5_c1.png';
import profile1 from '@assets/images/MarketingTemplates/profile1.svg';
import profile2 from '@assets/images/MarketingTemplates/profile2.svg';
import profile3 from '@assets/images/MarketingTemplates/profile3.svg';
import profile4 from '@assets/images/MarketingTemplates/profile4.svg';
import add_video_to_playlist from '@assets/images/MarketingTemplates/add_video_to_playlist.png';
import create_watch_party from '@assets/images/MarketingTemplates/create_watch_party.png';
import create_watch_party_alt from '@assets/images/MarketingTemplates/create_watch_party_alt.png';
import Template from '@components/common/Template';
import WatchPartyModel from '@components/Models/WatchPartyModel/watchPartyModelContainer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { scheduleWatchParty } from '@reducers/watchparty';
import { setNotificationMessage } from '@reducers/notification';
import { withRouter } from 'react-router';
import style from './Templates.module.css';

const Watchparty = (props) => {
    const [watchPartyModelOpened, setWatchPartyModelOpened] = useState(false);
    const items = {
        header: {
            img: block1,
        },
        contents: [
            {
                step: '01 Step',
                heading: 'Sign In to Vidberrys',
                description: ['Create a vidberrys account or use your google or facebook account .'],
                img: signin,
                order: 1
            },
            {
                step: '02 Step',
                heading: 'Create a watch party in 1 of 2 ways',
                description: [
                    '1. Click on New Watch Party in the header OR',
                    '2. Hover over the video you want and click on New Watch Party (you can add more videos later)'
                ],
                img: create_watch_party_alt,
                img_second: create_watch_party,
                groupedImage: true,
                // grayDiv: true,
                order: 2
            },
            {
                step: '03 Step',
                heading: 'Select the video',
                description: [
                    'You can select the video to watch in 2 ways (you can add more videos to playlist later)',
                    '1. Copy and paste the Youtube URL you like',
                    '2. Click on Select Video button to select a video in Vidberrys'
                ],
                img: block4_c2,
                order: 1
            },
            {
                step: '04 Step',
                heading: 'Watch party has started. Invite friends and enjoy!',
                description: ['Now the watch party has started, you can invite friends and add more videos to the playlist!'],
                img: block5_c1,
                order: 2
            },
            {
                step: '05 Step',
                heading: 'Add videos to playlist as needed',
                description: [
                    'Add more videos to the playlist in 1 of 2 ways',
                    '1. Copy and paste Youtube URL and click on ‘+’',
                    '2. Click on search icon and select the video from Vidberrys'
                ],
                img: add_video_to_playlist,
                order: 1
            }
        ],
        footer: {
            title: 'Let’s Go!',
            heading: 'Start Your First Watch Party',
            description: 'Watch videos with friends on camera. Enjoy watching great videos while enjoying the company of your friends and family!',
            img1: profile1,
            img2: profile2,
            img3: profile3,
            img4: profile4,
            color: 'color-howto-yellow',
            buttonText: 'Host a Watch Party'
        }
    }

    const handleClick = () => {
        if ('userName' in window.localStorage) {
            setWatchPartyModelOpened(true);
        } else {
            props.history.push('/login')
        }
    }

    const toggleWatchPartyModal = () => {
        setWatchPartyModelOpened(!watchPartyModelOpened);
    }
    return (
        <>
            <Template
                items={items}
                onClick={handleClick}
                block1Background={style.watchparty_block1Background}
                type="watchparty"
            />
            {watchPartyModelOpened && (
            <WatchPartyModel
                scheduleWatchParty={props.scheduleWatchParty}
                open={watchPartyModelOpened}
                toggle={toggleWatchPartyModal}
                setNotificationMessage={props.setNotificationMessage}
            />
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    scheduleWatchParty,
    setNotificationMessage,
  }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Watchparty))
