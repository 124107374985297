import React, { Component } from 'react';
import Header from '@components/Header';
import { withRouter } from 'react-router';
import { searchVideos, searchAutocomplete } from '@reducers/watchparty';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { APP_CONFIG } from '@constants';
import { scheduleWatchParty } from '@reducers/watchparty';
import { setNotificationMessage } from '@reducers/notification';
import { setSidebarMenuState, setWindowWidth, activeMenu } from '@reducers/ui';
import style from './HeaderContainer.module.css';
import cn from 'classnames';
import { setSelectedFriend } from '@reducers/friendsFans';

class HeaderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      search: '',
      currentPage: this.props.location.pathname.split('/')[1],
      categoryName: this.props.location.pathname.split('/')[2],
      isDropdownHidden: true,
      windowWidth: '',
      userDetails: {},
      searchAutocompleteLoading: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.setUserDetails();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    this.setState({ search: '', searchResults: {} });
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
    this.props.setWindowWidth(window.innerWidth);
    // if (window.innerWidth > 1920 || this.props.location === '/login') {
    //   this.toggleSidebarMenu(true);
    // }
    // if (window.innerWidth < 1920) {
    //   this.toggleSidebarMenu(false);
    // }
    if (window.innerWidth < 500) {
      this.props.history.push('/about');
    }
  }

  setUserDetails = () => {
    if ('userDetails' in window.localStorage) {
      const userDetails = JSON.parse(
        window.localStorage.getItem('userDetails')
      );
      this.setState({ userDetails: userDetails });
      this.props.setSelectedFriend(userDetails);
    } else {
      this.setState({ userDetails: { board: 'CBSE', grade: '10' } });
    }
  };

  componentDidUpdate() {
    this.props.currentUrl(
      this.props.location.pathname,
      this.props.selectedFriend && this.props.selectedFriend.userName
    );
    this.props.windowWidth(this.state.windowWidth);
  }

  videoClick = (item) => {
    let id = item.videoId;
    const url = `${APP_CONFIG.BASE_URL}/videoDetails/${id}`;
    const content = {
      id,
      title: item.title,
      link: item.videoUrl,
      url,
      categoryName: item.categoryName,
      userName: item.username,
    };
    window.location.href = `/reactionplayback/${id}`;
    window.localStorage.setItem('videoDetails', JSON.stringify(content));
  };

  searchKey = async (searchTerm, page = 0, size = 5) => {
    if (searchTerm.length < 3) {
      return;
    }
    let categoryName = this.props.location.pathname
      ? this.props.location.pathname.split('/')[2]
      : '';
    let currentPage =
      this.props.location.pathname &&
      this.props.location.pathname.split('/')[1];
    if (currentPage === 'videoDetails') {
      categoryName = '';
    }
    this.setState({ search: searchTerm, searchResults: {} });
    if (searchTerm !== '') {
      this.setState({ searchAutocompleteLoading: true });
      try {
        const response = await this.props.searchAutocomplete(
          searchTerm,
          this.state.userDetails.board,
          this.state.userDetails.grade,
          page,
          size
        );
        this.setState({ searchResults: response });
      } finally {
        this.setState({ searchAutocompleteLoading: false });
      }
      this.setState({ isDropdownHidden: true });
    } else {
      this.setState({ searchResults: [] });
      if (currentPage === `contentLibrary`) {
        window.localStorage.removeItem('searchKey');
        window.localStorage.removeItem('categoryName');
        this.props.history.push({
          pathname: `/contentLibrary/`,
          state: { keySearch: '' },
        });
      }
    }
    /* if (e.key === 'Enter') {
      this.setState({ isDropdownHidden: false });
    } */
  };

  displayDropDown = () => {
    this.setState({ isDropdownHidden: false });
  };

  toggleSidebarMenu = (flag) => {
    if (flag) {
      this.props.setSidebarMenuState(flag);
    } else {
      this.props.setSidebarMenuState(!this.props.showSidebar);
    }
  };

  render() {
    const location =
      this.props.location && this.props.location.pathname.split('/');
    const showWarning =
      this.props.location.pathname.includes('/conferenceroom/') ||
      this.props.location.pathname.includes('/sessionroom/')
        ? true
        : false;

    if (location[1] === 'sessionroom') {
      return <></>;
    }

    return (
      <>
        {((location[1] !== 'conferenceroom' &&
          this.state.windowWidth >= 1200) ||
          this.state.windowWidth < 1200) && (
          <nav
            className={cn(
              style.navbar_customized,
              'navbar navbar-light bg-primary position-fixed w-100 px-2'
            )}
          >
            <Header
              profileDisable={false}
              location={this.props.location.pathname}
              categoryName={this.props.location.pathname.split('/')[2]}
              searchKey={this.searchKey}
              searchResults={this.state.searchResults}
              videoClick={this.videoClick}
              searchTerm={this.state.search}
              isDropdownHidden={this.state.isDropdownHidden}
              displayDropDown={this.displayDropDown}
              closeDropdown={() => this.setState({ isDropdownHidden: true })}
              openDropdown={() => this.setState({ isDropdownHidden: false })}
              history={this.props.history}
              scheduleWatchParty={this.props.scheduleWatchParty}
              setNotificationMessage={this.props.setNotificationMessage}
              setSidebarMenuState={this.toggleSidebarMenu}
              showSidebar={this.props.showSidebar}
              showWarning={showWarning}
              windowWidth={this.state.windowWidth}
              loading={this.props.loading}
              activeMenu={this.props.activeSidebarMenu}
              setUserDetails={this.setUserDetails}
              profilePhoto={this.props.profilePhoto}
              selectedFriend={this.props.selectedFriend}
              searchAutocompleteLoading={this.state.searchAutocompleteLoading}
            />
          </nav>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.watchparty.loading,
    // searchResults: state.watchparty.searchData,
    showSidebar: state.ui.showSidebar,
    activeSidebarMenu: state.ui.activeMenu,
    selectedFriend: state.friendsFans.selectedFriend,
    profilePhoto: state.register.profilePhoto,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchVideos,
      searchAutocomplete,
      scheduleWatchParty,
      setNotificationMessage,
      setSidebarMenuState,
      setWindowWidth,
      setSelectedFriend,
      activeMenu,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
);
