import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import { ConnectedRouter } from 'react-router-redux';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';

import store from './store';
import Routes from './routes';

import './include/bootstrap';
import {
  GOOGLE_OAUTH_CLIENT_ID,
  POSTHOG_HOST,
  POSTHOG_KEY,
} from './constants/appConfig';

const target = document.querySelector('#root');

posthog.init(POSTHOG_KEY, {
  api_host: POSTHOG_HOST,
});

render(
  <PostHogProvider client={posthog}>
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </GoogleOAuthProvider>
  </PostHogProvider>,
  target
);
