export const GET_ACTIVE_SESSIONS_REQUESTED =
  'sessions/GET_ACTIVE_SESSIONS_REQUESTED';
export const GET_ACTIVE_SESSIONS_SUCCESS =
  'sessions/GET_ACTIVE_SESSIONS_SUCCESS';
export const GET_ACTIVE_SESSIONS_FAILURE =
  'sessions/GET_ACTIVE_SESSIONS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  sessions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_SESSIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ACTIVE_SESSIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        sessions: action.result.sessions,
      };
    }
    case GET_ACTIVE_SESSIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getActiveSessions = (userId, grade, board) => {
  let url = `getSessions?status=IN_PROGESS&userId=${userId}`;
  if (grade && board) {
    url = url + `&grade=${grade}&board=${board}`;
  }
  return {
    types: [
      GET_ACTIVE_SESSIONS_REQUESTED,
      GET_ACTIVE_SESSIONS_SUCCESS,
      GET_ACTIVE_SESSIONS_FAILURE,
    ],
    promise: (client) => client.get(url),
  };
};
