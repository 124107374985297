exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Templates_reaction_block1Background__1LDyq {\n  background: #CB4D4F; }\n\n.Templates_schoolparty_block1Background__3sYkf {\n  background: #3E635B; }\n\n.Templates_watchparty_block1Background__AImko {\n  background: #B88555; }\n\n.Templates_video_Tag__127D7 {\n  height: 90%;\n  min-width: 380px;\n  width: 80%; }\n", ""]);

// Exports
exports.locals = {
	"reaction_block1Background": "Templates_reaction_block1Background__1LDyq",
	"schoolparty_block1Background": "Templates_schoolparty_block1Background__3sYkf",
	"watchparty_block1Background": "Templates_watchparty_block1Background__AImko",
	"video_Tag": "Templates_video_Tag__127D7"
};