export const UPLOAD_REACTION_REQUESTED = 'reactions/UPLOAD_REACTION_REQUESTED';
export const UPLOAD_REACTION_SUCCESS = 'reactions/UPLOAD_REACTION_SUCCESS';
export const UPLOAD_REACTION_FAILURE = 'reactions/UPLOAD_REACTION_FAILURE';
export const PUBLISH_REACTION_REQUESTED = 'reactions/PUBLISH_REACTION_REQUESTED';
export const PUBLISH_REACTION_SUCCESS = 'reactions/PUBLISH_REACTION_SUCCESS';
export const PUBLISH_REACTION_FAILURE = 'reactions/PUBLISH_REACTION_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_REACTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPLOAD_REACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPLOAD_REACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case PUBLISH_REACTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PUBLISH_REACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case PUBLISH_REACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    default:
      return state;
  }
};

export const uploadReaction = (data) => {
  return {
    types: [UPLOAD_REACTION_REQUESTED, UPLOAD_REACTION_SUCCESS, UPLOAD_REACTION_FAILURE],
    promise: (client) => client.post('uploadReaction', {
      data,
    })
  };
};

export const publishReaction = (data) => {
  return {
    types: [PUBLISH_REACTION_REQUESTED, PUBLISH_REACTION_SUCCESS, PUBLISH_REACTION_FAILURE],
    promise: (client) => client.post('publishReaction', {
      data,
    })
  };
}
