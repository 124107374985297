
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import Select from 'react-select';
import cn from 'classnames';
import styles from '@formElements/TextField/TextField.module.css';
import FormValidationMessages from '@components/common/FormValidationMessages';
import selectStyles from './reactSelect.module.css';

const Option = (props) => {
  return (
    <div className={cn(selectStyles.selectedOptions, (props.isFocused ? `${selectStyles.focusedOption} full-width d-block` : selectStyles.notFocusedOption))} {...props.innerProps}>
      {props.data.label}
      { props.isFocused && props.data.optionInfo && (
        <div className="fs-10">
          { props.data.optionInfo }
        </div>
      )}
    </div>
  );
};
const RenderSelectInput = (props) => {
  const customStyles = {
    input: (provided) => ({
      ...provided,
      wordBreak: 'break-all',
    }),
    control: (provided) => ({
      ...provided,
      //  minHeight: '48px',
      marginLeft: '10px',
      height: '48px',
      border: props.background ? '2px solid #E3E8F1' : '1px solid #F7F8FA',
      boxShadow: (props.background && props.shadow) ? '0px 8px 16px #F7F8FA' : '',
      borderRadius: 16,
      backgroundColor: props.background ? props.background : '#F7F8FA',
      borderColor: '#dbd9d8',
      padding: 8,
      color: '#222',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      marginTop: '0',
      marginBottom: '0',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '4px',
    }),
  };

  const {
    input,
    name,
    id,
    options,
    placeholder,
    isMulti,
    isDisabled,
    isSearchable,
    className,
    label,
    displayLabel,
    errorMsgPrefix,
    meta: { touched, error, warning },
    errorLabel,
    errorLabelCustomClass,
    labelIconClass,
    customLabelCss,
    customErrorLabel,
    defaultValue,
    required,
    displayImg,
  } = props;
  
  let requiredErrorLabel = label;
  if (label) {
    requiredErrorLabel = requiredErrorLabel.replace(/\*/gi, '');
  } else {
    requiredErrorLabel = customErrorLabel;
  }
 if (errorMsgPrefix) {
   requiredErrorLabel = `${errorMsgPrefix} ${requiredErrorLabel}`;
 }
  return (
    <>
      {displayLabel !== false && (
        <label className={cn(styles.customLabel, customLabelCss)}>
          {labelIconClass && <i className={cn('mr-10', labelIconClass)} />}
          {label}
          {required && (
              <span className="text-danger"> *</span>
          )}
        </label>
      )}
      <Select
        {...input}
        name={name}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        displayImg={displayImg}
        placeholder={placeholder}
        className={className}
        classNamePrefix="react-select"
        isMulti={isMulti}
        options={options}
        id={id}
        value={defaultValue}
        styles={customStyles}
        onBlur={() => input.onBlur(input.value)}
        onChange={(value) => {
          window.setTimeout(() => {
            input.onChange(value);
          }, 10);
        }}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => <span className="fa fa-angle-down mr-10" />,
          Option
        }}
      />
      {touched
        && ((error && (
          <small className={cn('error', errorLabelCustomClass)}>
            {(error.trim() ? `${requiredErrorLabel} ${FormValidationMessages[props.language][error]}` : '') || errorLabel}
          </small>)
        )
          || (warning && (
          <small className="error">
            {warning}
          </small>
          )))}
    </>
  );
};

function mapStateToProps({ localization }) {
  return {
    language: localization.language
  };
}

const ConnectedRenderField = connect(mapStateToProps)(RenderSelectInput);

const ReactSelect = (props) => {
  const {
    name,
    id,
    options,
    onChange,
    placeholder,
    isMulti,
    isDisabled,
    isSearchable,
    className,
    validate,
    label,
    errorLabel,
    displayLabel,
    customLabelCss,
    errorMsgPrefix,
    customErrorLabel,
    background,
    value,
    required,
    displayImg,
  } = props;
  
  return (
      <Field
        name={name}
        component={ConnectedRenderField}
        id={id}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        className={className}
        validate={validate}
        label={label}
        errorLabel={errorLabel}
        displayLabel={displayLabel}
        customLabelCss={customLabelCss}
        errorMsgPrefix={errorMsgPrefix}
        customErrorLabel={customErrorLabel}
        background={background}
        defaultValue={value}
        required={required}
        displayImg={displayImg}
      />
  );
};
export default ReactSelect;
