export const GET_MESSAGES_REQUESTED = 'chat/GET_MESSAGES_REQUESTED';
export const GET_MESSAGES_SUCCESS = 'chat/GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAILURE = 'chat/GET_MESSAGES_FAILURE';
export const STORE_MARKER_DATA = 'chat/STORE_MARKER_DATA';
export const RESET_MARKER_DATA = 'chat/RESET_MARKER_DATA';

const initialState = {
  chatMessage: [],
  loading: '',
  loaded: false,
  markerList: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGES_REQUESTED: {
      return {
          ...state,
          loading: true,
          loaded: false
      }
    }
    case GET_MESSAGES_SUCCESS: {
        return {
            ...state,
            loading: false,
            loaded: true,
        }
    }
    case GET_MESSAGES_FAILURE: {
        return {
            ...state,
            loading: false,
            loaded: false
        }
    }
    case STORE_MARKER_DATA: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        markerList: action.payload
      };
    }
    case RESET_MARKER_DATA: {
      return {
        ...state,
        markerList: ''
      };
    }
    default:
      return state;
  }
};

export const getMessage = (confId, type, sourceUrl) => {
    let source = '';
    if(sourceUrl!==undefined) {
      let encodedUrl = encodeURIComponent(sourceUrl);
      source = `?sourceUrl=${encodedUrl}`
    }
    return {
        types: [GET_MESSAGES_REQUESTED, GET_MESSAGES_SUCCESS, GET_MESSAGES_FAILURE],
        promise: (client) => client.get(`getChatsMarkers/${confId}/${type}${source}`)
    }
}

export const storeMarkers = (data) => {
  return {
    type: STORE_MARKER_DATA,
    payload: data
  };
};

export const resetMarkers = (data) => {
  return {
    type: RESET_MARKER_DATA
  };
};