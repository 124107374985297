exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css"), "");

// Module
exports.push([module.id, ".Button_btn_vidberrys__10TMP {\n  box-sizing: border-box;\n  border-radius: 16px;\n  text-align: center;\n  font-weight: 600;\n  font-size: 16px;\n  font-style: normal;\n  min-width: 48px;\n  position: relative;\n  justify-content: center;\n  font-family: 'Inter', sans-serif;\n  display: flex;\n  outline: none !important; }\n", ""]);

// Exports
exports.locals = {
	"btn_vidberrys": "Button_btn_vidberrys__10TMP " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../scss/global.css").locals["vidberry-button-height"] + ""
};