exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".modal_modalContent__2kOHV {\n  border-radius: 12px !important;\n  padding: 39px 40px;\n  border: 0px !important;\n  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05); }\n\n.modal_modalHeader__296qZ {\n  border: 0px !important;\n  justify-content: center;\n  text-align: center;\n  display: flex; }\n  .modal_modalHeader__296qZ button {\n    background: transparent; }\n  .modal_modalHeader__296qZ div {\n    align-self: center; }\n\n.modal_modalHeaderBorder__3C8am {\n  border-bottom: transparent !important; }\n\n.modal_hideCloseandborder__3T6-Y {\n  border-bottom: transparent !important; }\n\n.modal_hideCloseandborder__3T6-Y > button {\n  display: none; }\n\n.modal_transparentBg__23rBK {\n  background: transparent !important; }\n", ""]);

// Exports
exports.locals = {
	"modalContent": "modal_modalContent__2kOHV",
	"modalHeader": "modal_modalHeader__296qZ",
	"modalHeaderBorder": "modal_modalHeaderBorder__3C8am",
	"hideCloseandborder": "modal_hideCloseandborder__3T6-Y",
	"transparentBg": "modal_transparentBg__23rBK"
};