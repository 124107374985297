import React, { Component } from 'react';
import WatchPartyModel from '@components/Models/WatchPartyModel';
import { EN } from '@localization';
import { APP_CONFIG } from '@constants';
import { withRouter } from 'react-router';
import style from './watchPartyModel.module.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setSelectedFriend } from '@reducers/friendsFans';
//

class WatchPartyModelContainer extends Component {

  constructor(props) {
    super(props);
    this.state = ({
      watchPartyModelOpened: this.props.open,
      isSchedule: false,
      iconClick: false,
      watchPartyEmails: [],
      date: new Date(),
      time: '',
      watchPartyName: '',
      watchPartyParticipantsList: [],
      scheduledDateAndTime: '',
      hasError: false,
      videoURL: (props.videoURL && props.videoURL !== undefined) ? props.videoURL : '',
      showSearchContainer: false,
      isSubmitted: false,
      isSourceUrlValid: true,
    });
  }

  handleSearchContainer = (key) => {
    if (key === 'true') {
      this.setState({showSearchContainer: true})
    }
    if (key === 'false') {
      this.setState({showSearchContainer: false})
    }
  }

  addNextVideo = (link) => {
    const content = {
      videoID: link.videoId, videoTitle: link.title, category: link.categoryName, uploadType: link.videoUploadType, imgSrc: link.thumbnail
    };
    window.localStorage.setItem('videoDetail', JSON.stringify(content));
    this.setState({
      videoURL: link.videoUrl,
      showSearchContainer: false,
    })
  }

  showSchedule = (e) => {
    if(e.target.value === "1") {
      this.setState({
        isSchedule: false
      });
    } else {
      this.setState({
        isSchedule: true
      });
    }
  }

  handleSubmit = (values) => {
    this.setState({isSubmitted: true})
    this.watch(values, this.state.watchPartyEmails);
  }

  watch = (values, emails) => {
    const videoDetails = window.localStorage.getItem('videoDetail') ? JSON.parse(window.localStorage.getItem('videoDetail')) : '';
    let url = values.source ? values.source : this.state.videoURL ;
    if(url.indexOf('&') > 0) {
      const urlArray = url.split('&');
      url = urlArray[0];
    }
    const watchPartyName = values.watchpartyname;
    if(url) {
      const userName = window.localStorage.getItem('userName');
      const userSurrogateId = JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId;
      const userEmail = JSON.parse(window.localStorage.getItem('userDetails')).email;
      let id = 0;let imgSrc= '';let videoTitle='';
      if(url) {
        if(url.indexOf('watch?v=') > 0) {
          const urlArray= url.split('watch?v=');
          if(urlArray.length === 2) {
              id = urlArray[1];
            }
        } else if(url.indexOf('/embed/') > 0) {
          let urlArray= url.split('/embed/');
          if(urlArray.length === 2) {
            id = urlArray[1];
          }
          // url = `${urlArray[0]}/watch?v=${id}`;
        } else if(url.indexOf('/youtu.be/') > 0) {
          let urlArray= url.split('/youtu.be/');
          if(urlArray.length === 2) {
            urlArray[0] = 'https://www.youtube.com';
            id = urlArray[1];
          }
          url = `${urlArray[0]}/watch?v=${id}`;  
        } 
      }

      fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${id}&key=${APP_CONFIG.YOUTUBE_KEY}`)
      .then((res) => res.json())
      .then((data) => {
            const item = data.items[0];
            if((videoDetails && videoDetails.uploadType === "vidberrys") || item) {
              videoTitle = videoDetails && videoDetails.uploadType === 'vidberrys' ? videoDetails.videoTitle : item.snippet.title;
              imgSrc = videoDetails && videoDetails.uploadType === 'vidberrys' ? videoDetails.imgSrc : item.snippet.thumbnails.default.url;

  	if(item) {
              if (item.snippet.thumbnails.maxres) {
                imgSrc = item.snippet.thumbnails.maxres.url;
              } else if(item.snippet.thumbnails.standard){
                imgSrc = item.snippet.thumbnails.standard.url;
              }  else if(item.snippet.thumbnails.high){
                imgSrc = item.snippet.thumbnails.high.url;
              }  else if(item.snippet.thumbnails.medium){
                imgSrc = item.snippet.thumbnails.medium.url;
              }  else if(item.snippet.thumbnails.default){
                imgSrc = item.snippet.thumbnails.default.url;
              }
  	}
              const nextVideoList = [
                {
                  "key": 0,
                  "videoUrl": url,
                  "addedBy" : userName,
                  "addedById": userSurrogateId,
                  "videoTitle":videoTitle,
                  "imgSrc":imgSrc
                }
              ];
              let res = [];
              res.push({
                id: '',
                participantEmail: userEmail,
                participantName: userName,
                createdTime: '',
                watchPartyId: 0
              });
              emails.map(email => {
                const name = email.toString().split('@'); 
                res.push({
                  id: '',
                  participantEmail: email,
                  participantName: name[0],
                  createdTime: '',
                  watchPartyId: 0
                })
              });
              const jsonData = {
                id: '',
                watchPartyName: watchPartyName,
                videoUrl: url,
                nextVideoList: nextVideoList,
                createdTime: '',
                userSurrogateId: userSurrogateId,
                userName: userName,
                userEmail,
                scheduledStartTime: this.state.isSchedule ? this.state.scheduledDateAndTime : this.state.date,
                scheduledEndTime: '',
                status: values.scheduleParty ? values.scheduleParty === '1' ? 1 : 0 : 1,
                noOfParticipants: res.length,
                watchPartyParticipantsList : res,
                watchPartyMode: values.watchPartyMode ? Number(values.watchPartyMode) : 0,
                watchPartyType: this.props.isDisabled ? 1 : values.type ? values.type.value : 0,
                watchPartyTypeName: this.props.isDisabled ? 'School' : values.type ? values.type.value === 0 ? 'General' : 'School' : 'General'
              };
              this.setState({ watchPartyParticipantsList: res });
              this.props.scheduleWatchParty(jsonData,true).then((data) => this.successRequestSent(data)).catch(this.failureRequest);
            } else {
              this.props.setNotificationMessage({
                message: 'Please add valid youtube url or select video from vidberrys',
                type: 'danger',
                autoClose: true
              });
              this.handleSourceField(false);
              this.setState({isSubmitted: false});
            }

      });
    }
  }

  handleSourceField = (value) => {
    this.setState({isSourceUrlValid: value, videoURL: ''});
  }

  successRequestSent = (data) => {
    if (this.state.isSchedule) {
      this.props.toggle();
      //this.props.history.push(`/${window.localStorage.userName}`);
    } else {
      const id = data.watchPartyId;
      this.props.history.push(`/conferenceroom/${id}`);
    }
    this.props.setNotificationMessage({
      message: data.successMsg,
      type: 'success',
      autoClose: true
    });
    this.setState({
        isSchedule: false
    })
  }

  failureRequest = (data) => {
    this.props.setNotificationMessage({
      message: EN.FAILURE_SCHEDULE,
      type: 'danger',
      autoClose: true
    });
  }

  timeChange = time => {
    let currentTime = this.state.scheduledDateAndTime;
    const incomingTime = new Date(time);

    if (currentTime && currentTime !== '') {
      currentTime.setHours(incomingTime.getHours());
      currentTime.setMinutes(incomingTime.getMinutes());
    } else {
      currentTime = incomingTime;
    }
       this.setState({ scheduledDateAndTime: currentTime, time: currentTime });
  }

  dateChange = date => {
    let currentDate = this.state.scheduledDateAndTime;
    const incomingDate = new Date(date);

    if (currentDate && currentDate !== '') {
      currentDate.setDate(incomingDate.getDate());
      currentDate.setMonth(incomingDate.getMonth());
      currentDate.setYear(incomingDate.getFullYear());
    } else {
      currentDate = incomingDate;
    }
   this.setState({ scheduledDateAndTime: currentDate, date: currentDate });
  }

  resetWatchPartyField = () => {
    this.setState({
      watchPartyName: '',
      videoURL: '',
      isSchedule: false });
  }

  watchPartyEmailsList = (email) => {
    this.setState({watchPartyEmails: [...email]})
  }

  onNameChange = (e) => {
    this.setState({ watchPartyName: e.target.value.trim() })
  }

  render() {
    return (
        <WatchPartyModel
          type="popup"
          title="New Study Party"
          dialogClassName="align-items-center"
          toggle={this.props.toggle}
          isOpen={this.state.watchPartyModelOpened}
          size="lg"
          customHeaderClass="fs-24 clr-black font-bold"
          modalCloseClass="fs-20"
          modalTitleClass="fw-600 fs-24"
          contentClassName="border-radius-12"
          showSchedule={this.showSchedule}
          onSubmit={this.handleSubmit}
          isSchedule={this.state.isSchedule}
          hasError={this.state.hasError}
          timeChange={this.timeChange}
          dateChange={this.dateChange}
          resetWatchPartyField={this.resetWatchPartyField}
          watchPartyEmailsList={this.watchPartyEmailsList}
          isDisabled={this.props.isDisabled}
          videoURL={this.state.videoURL}
          date={this.state.date}
          time={this.state.time}
          handleSearchContainer={this.handleSearchContainer}
          addNextVideo={this.addNextVideo}
          showSearchContainer={this.state.showSearchContainer}
          customSearchContainerClass={style.customSearchContainerClass}
          customScrollContainerClass={style.customScrollContainerClass}
          isSubmitted={this.state.isSubmitted}
          onNameChange={this.onNameChange}
          watchPartyName={this.state.watchPartyName}
          handleSourceField={this.handleSourceField}
          isSourceUrlValid={this.state.isSourceUrlValid}
        />
    )
  }
}

const mapStateToProps = (state) => {
  return {
      selectedUser: state.friendsFans.selectedFriend
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setSelectedFriend
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WatchPartyModelContainer));
