import APIConfig from './api';
export default {
  BASE_URL: process.env.PUBLIC_URL,
  // SERVER_URL: 'meet.sakhaglobal.com', //quick installed
  // SERVER_URL: 'conference.sakhaglobal.com', // manually installed
  // SERVER_URL: 'localhost:8080',
  SERVER_URL: 'meet.vidberrys.com', // manually installed in vidberrys server
  YOUTUBE_KEY: 'AIzaSyBoohXYyEKXEwUESygdxlmyp4UaNsSp5N4',
  OTP_LOGIN_TYPE: 1,
  FB_LOGIN_TYPE: 2,
  GOOGLE_LOGIN_TYPE: 3,
  ITEMS_PER_PAGE: 5,
  // PORT: 'https://storage.googleapis.com/vidberrys-staging/',
  PORT: 'https://storage.googleapis.com/vidberry-reactions/',
  MIC_ICON: 'https://img.icons8.com/cute-clipart/40/000000/microphone.png',
  MIC_OFF_ICON:
    'https://img.icons8.com/cute-clipart/40/000000/block-microphone.png',
  VIDEO_CALL_ICON:
    'https://img.icons8.com/cute-clipart/40/000000/video-call.png',
  VIDEO_CALL_OFF_ICON:
    'https://img.icons8.com/cute-clipart/40/000000/no-video.png',
  HUNG_UP_ICON: 'https://img.icons8.com/ios-filled/40/000000/hang-up.png',
  profile_photo_base_url: `${APIConfig.hostname}${APIConfig.ports.profile}/profile/user/profilepic`,
};

export const GOOGLE_KEY = 'AIzaSyBnBPtzNwN6K8_-oNHmKdxATeRISKq13HM';
export const GOOGLE_OAUTH_CLIENT_ID =
  '441007562783-lkcqqlunuosk92tn4kf8c2v42fq6th09.apps.googleusercontent.com';
export const POSTHOG_HOST = 'https://us.i.posthog.com';
export const POSTHOG_KEY = 'phc_G4cmJ9pACDsh0K3VIMwDyPLkOt3s3hJBAAkL19uzlMP';

// eslint-disable-next-line new-cap
export const socket = new window.io.connect('https://vidberrys.com:4000', {
  transports: ['websocket'],
  upgrade: false,
});
// export const socket = new window.io.connect('https://staging.vidberrys.com:4000',{transports: ['websocket'], upgrade: false});
// export const socket = new window.io.connect('http://localhost:4000');
export const uploadSocket = new window.io.connect(
  'https://vidberrys.com:4000',
  { transports: ['websocket'], upgrade: false }
);
// export const uploadSocket = new window.io.connect('https://staging.vidberrys.com:4000',{transports: ['websocket'], upgrade: false});
// export const uploadSocket = new window.io.connect('http://localhost:4000');
