/* eslint-disable */

import superagent from 'superagent';
import IsJsonString from '@helpers/TypeCheckers';
import { APIConfig } from '../constants';
const fetch = require("node-fetch");

const methods = ['get', 'post', 'put', 'patch', 'del'];
let HOSTNAME = '';
const PORTS = APIConfig.ports;
const ENDPOINTS = APIConfig.endpoints;

function formatUrl(path) {
  HOSTNAME = APIConfig.hostname;
  let apiPathArray = [];
  if(path.indexOf('?') !== -1) {
    apiPathArray = path.split('?');
    path = apiPathArray[0];
    apiPathArray.shift();
  }
  let mappedEndpoint = ENDPOINTS[path];
  if (path.indexOf('/') !== -1) {
    mappedEndpoint = '';
    const splitPathArray = path.split('/');
    mappedEndpoint += ENDPOINTS[splitPathArray[0]]+'/';
    splitPathArray.shift();
    mappedEndpoint += splitPathArray.join('/')
  }

  if(mappedEndpoint.startsWith('before/')) {
    mappedEndpoint = mappedEndpoint.split('before/')[1];
  }

  
  if (mappedEndpoint.startsWith('profile')) {
    HOSTNAME = HOSTNAME + PORTS['profile'];
  } else if (mappedEndpoint.startsWith('watchparty')) {
    HOSTNAME = HOSTNAME + PORTS['watchparty'];
  } else if (mappedEndpoint.startsWith('logger')) {
    HOSTNAME = HOSTNAME + PORTS['logger'];
  } else if (mappedEndpoint.startsWith('streamOverlay')) {
    HOSTNAME = HOSTNAME + PORTS['ffmpeg'];
  }
  
  let adjustedPath = mappedEndpoint[0] !== '/' ? HOSTNAME + '/' + mappedEndpoint : HOSTNAME + mappedEndpoint;
  
  if(apiPathArray)
    adjustedPath = adjustedPath + '?' + apiPathArray;
  return adjustedPath;
}

async function fetchBasicAuthToken(email, base64pwd) {
  const BASE_HOSTNAME = APIConfig.hostname;
  const AUTH_URL = "9093/VidberrysAuth/oauth/token";
  const authUrl = BASE_HOSTNAME + AUTH_URL;
  const urlParams = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Basic aXZVNXRSdlMxOHgzdHp4UjpTQjdmZHhpRHlNSE9rQmRX"
    },
    body: `client_id=ivU5tRvS18x3tzxR&username=${email}&grant_type=password&password=${base64pwd}`
  };
  try {
    console.log('authUrl', authUrl)
    console.log('urlParams', urlParams)
    const response = await fetch(authUrl, urlParams);
    return new Promise((resolve, reject) => {
      response.json().then((data) => {
        resolve(data.access_token);
      });
    });
  } catch (err) {
    console.log('err',err);
    return err;
  }
}

export default class ApiClient {
  constructor(req) {
    methods.forEach(method => {
      this[method] = (path, { params, data, headers = {}, files, fields } = {}) => new Promise((resolve, reject) => {
        let request = superagent[method](formatUrl(path));

        if(path.indexOf('fakeapi') !== -1) {
          let fakePath = path;
          let splitPathArray = fakePath.split('/');
          splitPathArray.shift();
          let constructedURL = splitPathArray.join('/');
          request = superagent[method](`http://localhost:3004/${constructedURL}`).withCredentials();
        }
        if (path.indexOf('uploadFile') == -1 && path.indexOf('uploadReaction') == -1 && path.indexOf('publishVideos') && path.indexOf('saveProfilePhoto')) {
          request.set({ 'Content-Type': 'application/json;charset=UTF-8' });
        } 
        
        if (params) {
          request.query(params);
        }
        let access_token = '';
        if (request.url && window.localStorage.getItem('access_token') && window.localStorage.getItem('access_token') !== null && (request.url.indexOf('/before') == -1 )){
           access_token = window.localStorage.getItem('access_token');
        }
        if(access_token === '' && request.url && (request.url.indexOf('/profile/user/verify/username') > -1 || request.url.indexOf('/profile/user/set/password') > -1 || request.url.indexOf('profile/user/image') > -1)) {
          access_token = window.localStorage.getItem('userdetailstemp')!= null ? JSON.parse(window.localStorage.getItem('userdetailstemp')).access_token : '';
        }
        if(access_token !== '') {
          headers['Authorization'] = `Bearer ${access_token}`;
        }
        if (headers) {
          request.set(headers);
        }

        if (this.token) {
           request.set('Authorization', `Bearer ${this.token}`);
         }

        if (files) {
          files.forEach(file => request.attach(file.key, file.value));
        }

        if (fields) {
          fields.forEach(item => request.field(item.key, item.value));
        }

        const requrl= request.url.split('?');
          if (requrl[0].indexOf('download') != -1) {
            request.responseType('arraybuffer');
          }

        if (data) {
           request.set(headers);
           request.send(data);
         }

        request.end(async(err, response = {}) => {
          if(err) {
            if (response && response.text != '') {
              if(request.url.indexOf('/streamOverlay') > -1 || request.url.indexOf('/profile/user/set/password') > -1) {
                if (IsJsonString(decodeURIComponent(response.text.replace(/\+/g, '%20')))) {
                  reject(JSON.parse(decodeURIComponent(response.text.replace(/\+/g, '%20'))));
                } else {
                  reject(decodeURIComponent(response.text.replace(/\+/g, '%20')));
                }
              } else {
                reject(err);
              }
            } else {
              reject(err);
            }
          } else if (request.url.indexOf('download') != -1) {
                resolve(response);
            } else {
              if(request.url.indexOf('/profile/user/social') > -1 || request.url.indexOf('/profile/user/login') > -1) {
                let isOtp = true; let base64password = '';
                if(request.url.indexOf("profile/user/login") > -1) {
                  isOtp = request.url.split("?isOtp=")[1];
                } else if (request.url.indexOf('/profile/user/social') > -1){
                  isOtp = false;
                }
                if(!isOtp || isOtp === false || isOtp === 'false') {
                  base64password = request._data.otp ? Buffer.from(request._data.otp).toString('base64') : '';
                }
                let access_token = await fetchBasicAuthToken(request._data.email, base64password);
                if(IsJsonString(response.text)) {
                  let toBeModified = {};
                  toBeModified.userData = JSON.parse(response.text);
                  toBeModified.access_token =  access_token;
                  toBeModified.status = 200;
                  resolve(toBeModified);
                } else {
                  console.log('login', response.text, access_token);
                }

              } else {
                if(IsJsonString(response.text)) {
                  resolve(JSON.parse(response.text));
                } else {
                  resolve(response.text);
                }
              }
          }
        });
      });
    });
  }

  empty() {}
}
