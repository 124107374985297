import React from 'react';
import moment from 'moment';

// to put ellipsis in a long text and on hover it will show the full text (pass filename and length of charecter how much you want to show, as param)
export const ellipsis = (fileName, length) => {
    if (fileName && fileName.includes('_')) {
      const filteredFileName = fileName.replace(/_/g, ' ')
    return filteredFileName && filteredFileName.length > length ? <span title={filteredFileName}>{filteredFileName.slice(0, length).concat('...')}</span> : <span>{filteredFileName}</span>
    } else {
    return fileName && fileName.length > length ? <span title={fileName}>{fileName.slice(0, length).concat('...')}</span> : <span>{fileName}</span>
    }
}

// use this method to convert any time duration that is in second, to hour:minute:second
export const secondsToHms = (d) => {
    if (!isNaN(d)) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        var formattedSecond = s > 9 ? s : `0${s}`
        var formattedMinute = m > 9 ? m : `0${m}`
        var formattedHour = h > 9 ? h : `0${h}`
        return h !== 0 ? `${formattedHour}:${formattedMinute}:${formattedSecond}` : m !== 0 ? `${formattedMinute}:${formattedSecond}` : `00:${formattedSecond}`;
    } else {
        return d;
    }
}

export const hmsToSeconds = (d) => {
  const arr = d.split(':');
  let token = 0;
  const splitTime = arr.map(Number)
  if (splitTime.length === 3) {
    splitTime.forEach((el, index) => {
      if (index === 0) {
        token = token + el * 60 * 60;
      } else if (index === 1) {
        token = token + el * 60;
      } else {
        token = token + el;
      }
    })
  }
  if (splitTime.length === 2) {
    splitTime.forEach((el, index) => {
      if (index === 0) {
        token = token + el * 60;
      } else {
        token = token + el;
      }
    })
  }
  return token
}


// use this method to show number of views along with K,M etc. (eg: 2K, 1M)
export const countViews = (views) => {
    if (views >= 1000 && views < 1000000) {
      let count = (views/1000).toFixed(1);
      let number = (count+"").split(".")[0];
      let decPart = (count+"").split(".")[1];
      if (decPart > 0) {
        return `${count}K`;
      } else {
        return `${number}K`;
      }
    }
    if (views >= 1000000) {
      let count = (views/1000000).toFixed(1);
      let number = (count+"").split(".")[0];
      let decPart = (count+"").split(".")[1];
      if (decPart > 0) {
        return `${count}M`;
      } else {
        return `${number}M`;
      }
    }
    return views;
  }

  export const countUploadTime = (datetime) => {
    const startTime = moment( new Date(Date.parse(datetime)), 'YYYY-MM-DD HH:mm:ss' );
    const endTime = moment( new Date(), 'YYYY-MM-DD HH:mm:ss' );
    const minutes = endTime.diff( startTime, 'minutes');
    const hours = endTime.diff( startTime, 'hours');
    const days = endTime.diff( startTime, 'days');
    const weeks = endTime.diff( startTime, 'weeks');
    const months = endTime.diff( startTime, 'months');
    const years = endTime.diff( startTime, 'years');
    let displayTime = '';
    if (minutes < 60) {
      displayTime = `${minutes} minutes ago`;
    }
    if (minutes >= 60) {
      displayTime = `${hours} hours ago`;
    }
    if (hours >= 24) {
      displayTime = `${days} days ago`;
    }
    if (days >= 7) {
      displayTime = `${weeks} weeks ago`;
    }
    if (weeks > 4) {
      displayTime = `${months} months ago`;
    }
    if (months > 12) {
      displayTime = `${years} years ago`;
    }
    return displayTime;
  }

  export const isMobileDevice = () => {
    if (typeof window.orientation !== 'undefined') { 
      return true;
    } else {
      return false;
    }
  }

  export const initDownload = (res, filename) => {
    const blob = new Blob([res.body], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('id', 'downloadRJ');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  export const toDataUrl = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export const base64ToMultipartFile = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++)
    {
        ia[i] = byteString.charCodeAt(i);
    }
    var bb = new Blob([ab], { "type": mimeString });
    return bb;
}

export const isCorrectFile = (file, type) => {
  let correctFile = false;
  const parts = file && file.name.split('.');
  const extension = parts && parts[parts.length - 1];
  if (type === 'video') {
    switch (extension.toLowerCase()) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'mkv':
        correctFile = true;
        break;
      default:
        break;
      }
  }
  if (type === 'image') {
    switch (extension.toLowerCase()) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        correctFile = true;
        break;
      default:
          break;
      }
  }
  return correctFile
}

export const isLoggedInUser = (sid) => {
  if (window.localStorage.getItem('userDetails')!==null && JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId === sid)
  return true;
  else 
  return false
} 

export const kFormatter = (num) => {
  return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

export const blobToData = (blob: Blob): Promise<string> =>
    new Promise(resolve => {
        const reader = new FileReader();

        reader.onloadend = () => resolve(reader.result.toString());
        reader.readAsDataURL(blob);
    });

export const toDataURL = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const resData = await blobToData(blob);

  return resData;
};