exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".watchPartyModel_customSearchContainerClass__Kqd_h {\n  height: 450px !important; }\n\n.watchPartyModel_customScrollContainerClass__39FCW {\n  height: 350px !important; }\n\n.watchPartyModel_srcUrl__1LtVv {\n  background-color: #F7F8FA;\n  padding: 8px 24px;\n  border-radius: 16px;\n  word-break: break-all;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  font-size: 0.9375rem !important;\n  line-height: 32px !important; }\n\n.watchPartyModel_customError__rtROJ {\n  font-weight: 400;\n  font-size: 80%;\n  color: red;\n  margin-left: 20px !important; }\n", ""]);

// Exports
exports.locals = {
	"customSearchContainerClass": "watchPartyModel_customSearchContainerClass__Kqd_h",
	"customScrollContainerClass": "watchPartyModel_customScrollContainerClass__39FCW",
	"srcUrl": "watchPartyModel_srcUrl__1LtVv",
	"customError": "watchPartyModel_customError__rtROJ"
};